export class AppConstants {
	public static SESSION_KEY_PAYLOAD = "payload";
	public static SESSION_KEY_IMPERSONATION = "impersonation";

	public static ROLE_PLATFORMADMIN = "PlatformAdmin";
	public static ROLE_CLIENTADMIN = "ClientAdmin";
	public static ROLE_RADIOLOGIST = "Radiologist";
	public static ROLE_SCANNINGCENTRE = "ScanningCentre";
	public static ROLE_SHOPADMIN = "ShopAdmin";
	public static ROLE_SALESUSER = "SalesUser";

	public static TRAD_ROLES = [AppConstants.ROLE_PLATFORMADMIN, AppConstants.ROLE_CLIENTADMIN, AppConstants.ROLE_RADIOLOGIST, AppConstants.ROLE_SCANNINGCENTRE, AppConstants.ROLE_SALESUSER];
	public static INV_ROLES = [AppConstants.ROLE_PLATFORMADMIN, AppConstants.ROLE_CLIENTADMIN, AppConstants.ROLE_SHOPADMIN, AppConstants.ROLE_SALESUSER];
	public static ALL_ROLES = [...AppConstants.TRAD_ROLES, ...AppConstants.INV_ROLES];

	public static STATUS_LOADING = "Loading";
	public static STATUS_NEW = "New";
	public static STATUS_REVIEW = "UnderReview";
	public static STATUS_COMPLETED = "Completed";

	public static APP_INVOICE = "Invoice Management";
	public static APP_TRAD = "Tele Radiology";

	public static MASTER_TYPE_SCAN = "Scan Type";
}
