import { Component } from "react";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import InvService from "../../Services/Inv/Inv.service";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaEdit } from "react-icons/fa";
import { FaRegFloppyDisk, FaXmark, FaPlus } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import { Col, Row, Table } from "react-bootstrap";
import "../../Styles/invTable.custom.css";

type Props = {};
type State = {
	redirect: string | null;
	categories: Array<any>;
	selectedCategory: any;
	ready: boolean;
	tableKey: number;
};

type categoryType = {
	Name: string;
	IsEdit: boolean;
	Action: any;
	ItemCount: number;
	NewRandomId: any;
};

export default class InvCategories extends Component<Props, State> {
	categoryTableHeaders: TableColumnType<categoryType>[] = [
		{
			prop: "Name",
			title: "Category Name",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div>
							<div>Category Name</div>
							<div>
								<input
									value={row.Name}
									className="form-control"
									onChange={(e) => {
										row.Name = e.target.value;
										this.setState({
											categories: this.state.categories,
										});
									}}
								/>
							</div>
						</div>
					)}
					{!row.IsEdit && <span>{row.Name}</span>}
				</div>
			),
		},
		{
			prop: "ItemCount",
			title: "Items",
			isSortable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Items</div>}
					<div>{row.ItemCount}</div>
				</>
			),
		},
		{
			prop: "Action",
			title: "Action",
			isFilterable: false,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Action</div>}
					<div className="d-flex">
						{!row.IsEdit && (
							<button
								className="btn btn-primary d-flex"
								onClick={() => {
									row.IsEdit = !row.IsEdit;
									this.setState({
										categories: this.state.categories,
									});
								}}
							>
								<span className="text-white">
									<FaEdit />
								</span>
								<span className="ms-3 mt-1">Edit</span>
							</button>
						)}
						{row.IsEdit && (
							<>
								<button
									className="btn btn-success d-flex"
									onClick={() => {
										this.saveValue(row);
									}}
								>
									<span className="text-white">
										<FaRegFloppyDisk />
									</span>
									<span className="ms-3 mt-1">Save</span>
								</button>
								<button
									className="btn btn-warning d-flex ms-4 "
									onClick={() => {
										row.IsEdit = !row.IsEdit;
										this.setState({
											categories: this.state.categories.filter((x) => x.NewRandomId === undefined || x.NewRandomId !== row.NewRandomId),
										});
									}}
								>
									<span className="text-white">
										<FaXmark />
									</span>
									<span className="ms-3 mt-1 text-white">Cancel</span>
								</button>
							</>
						)}
					</div>
				</>
			),
		},
	];
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			categories: [],
			selectedCategory: null,
			ready: false,
			tableKey: Math.random(),
		};
	}

	async componentDidMount() {
		this.loadValues();
	}

	async loadValues() {
		const response = await InvService.getCategories();
		this.setState({
			categories: response.data,
			ready: true,
		});
	}

	async saveValue(data: any) {
		console.log(data);
		if (data.Name === undefined || data.Name === "" || data.Name === null || data.Name.trim() === "") {
			toast.error("Please enter name");
			return;
		}

		const res = await InvService.createOrUpdateCategory({
			Id: data.Id,
			Name: data.Name,
		});
		if (res.status === 200 && res.data.success) {
			toast.success(res.data.message);
			this.loadValues();
		} else {
			toast.error(res.data.message);
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<>
					<div className="mt-50 px-10">
						<div className="row">
							<div className="col-12">
								<h4 className="h3 mb-0 text-gray-800">Manage Categories</h4>
								<p>A place to manage who all categories</p>
							</div>
						</div>
					</div>
					{this.state.ready && (
						<DatatableWrapper
							body={this.state.categories}
							headers={this.categoryTableHeaders}
							paginationOptionsProps={{
								initialState: {
									rowsPerPage: 10,
									options: [5, 10, 15, 20],
								},
							}}
							key={this.state.tableKey}
						>
							<Row className="mb-10">
								<Col className="col-12 col-md d-flex flex-col justify-content-end align-items-end mb-3 mb-md-0">
									<Filter />
								</Col>
								<Col className="col-12 col-md d-flex justify-content-end align-items-end">
									<button
										className="btn btn-primary d-flex ms-10"
										onClick={() => {
											const categories = this.state.categories;
											categories.unshift({
												Id: undefined,
												Name: "",
												IsEdit: true,
												NewRandomId: Math.random(),
											});
											this.setState({
												categories,
												tableKey: Math.random(),
											});
										}}
									>
										<span className="text-white">
											<FaPlus />
										</span>
										<span className="ms-3 mt-1">Create New</span>
									</button>
								</Col>
							</Row>

							<Table className="dataTablesssss">
								<TableHeader />
								<TableBody />
							</Table>
							<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
								<PaginationOptions />
								<div className="ms-5" />
								<Pagination />
							</Col>
						</DatatableWrapper>
					)}
					<ToastContainer />
				</>
			</InvLayout>
		);
	}
}
