import { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../../Services/Auth/Auth.service";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

type Props = {
	children: React.ReactNode;
};
type State = {
	redirect: string | null;
	ready: boolean;
};

export default class AppLayout extends Component<Props, State> {
	currentUser = null;
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
		};
	}

	componentDidMount() {
		this.currentUser = AuthService.getCurrentUser();
		if (!this.currentUser) this.setState({ redirect: "/login" });
		this.setState({ ready: true });
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		return (
			<>
				{this.state.ready && (
					<>
						<AppHeader currentUser={this.currentUser}></AppHeader>
						<div className="overflow-auto container-fluid bg-light vh-100">
							{this.props.children}
							<div className="mb-50"></div>
						</div>
						<AppFooter />
					</>
				)}
			</>
		);
	}
}
