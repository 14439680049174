import { Component } from "react";
import ModalPopup from "../Shared/ModalPopup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React from "react";
import Select from "react-select";

type Props = {
  show: boolean;
  handleClose: () => void;
  handleSave: (data: any) => void;
  selectedRecord: any | null;
  clients: Array<any>;
};

export default class ApplicationDialog extends Component<Props> {
  appSubmitButtonRef: React.Ref<HTMLButtonElement> = null;
  logoFileRef: React.Ref<HTMLInputElement> = null;
  initialValues = {
    id: "",
    name: "",
    route: "",
    selectedClients: [],
  };

  constructor(props: Props) {
    super(props);

    this.readyToSave = this.readyToSave.bind(this);
    this.appSubmitButtonRef = React.createRef<HTMLButtonElement>();
    this.logoFileRef = React.createRef<HTMLInputElement>();
    this.initialValues = {
      id: this.props.selectedRecord?.Id,
      name: this.props.selectedRecord?.Name,
      route: this.props.selectedRecord?.Route,
      selectedClients: this.props.selectedRecord?.Clients,
    };
    console.log(this.initialValues);
  }

  validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    route: Yup.string().required("Route is required"),
  });

  handleSave = () => {
    if (this.appSubmitButtonRef !== null) {
      (this.appSubmitButtonRef as any).current.click();
    }
  };

  async readyToSave(data: any) {
    console.log(data);
    this.props.handleSave(data);
  }

  render() {
    return (
      <ModalPopup
        show={this.props.show}
        handleClose={this.props.handleClose}
        handleSave={this.handleSave}
        title={
          this.props.selectedRecord !== null
            ? "Edit Application"
            : "Create Application"
        }
        content={
          <Formik
            enableReinitialize
            initialValues={this.initialValues}
            validationSchema={this.validationSchema}
            onSubmit={this.readyToSave}
          >
            {({ setFieldValue }) => {
              return (
                <Form className="p-10">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <Field
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="name">Route</label>
                    <Field
                      type="text"
                      id="route"
                      name="route"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="route"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group pt-5">
                    <label htmlFor="logo">Clients</label>
                    <Select
                      name="clients"
                      id="clients"
                      isMulti={true}
                      options={this.props.clients}
                      onChange={(e) => {
                        if (this.props.selectedRecord) {
                          this.props.selectedRecord.Clients = e;
                          setFieldValue("selectedClients", e);
                        }
                      }}
                      value={this.props.selectedRecord?.Clients}
                    ></Select>
                  </div>

                  <button
                    type="submit"
                    ref={this.appSubmitButtonRef}
                    className="d-none"
                  ></button>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}
