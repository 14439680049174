import api from "../Middleware/ApiClient";

export default class GenericUserService {
	static getUsersByType(roleKey: string, shopId?: string) {
		return api.post("/genericuser/getUsersByType", { roleKey, shopId });
	}
	static create(data: any) {
		return api.post("/genericuser/create", data);
	}
	static update(data: any) {
		return api.post("/genericuser/update", data);
	}
}
