import { Component } from "react";
import ModalPopup from "../Shared/ModalPopup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React from "react";
import moment from "moment";
import InvService from "../../Services/Inv/Inv.service";
import AuthService from "../../Services/Auth/Auth.service";

type Props = {
	show: boolean;
	handleClose: () => void;
	handleSave: (data: any) => void;
	itemName: string;
	itemId: any;
	isQuantity: boolean;
	isRate: boolean;
	isVat: boolean;
	shopId: any;
	isVatUser: any;
};

type State = {
	purchases: Array<any>;
	currentUser: any;
};

export default class InvShopStockAddDialog extends Component<Props, State> {
	submitButtonRef: React.Ref<HTMLButtonElement> = null;
	initialValues = {
		purchase: "",
		rate: 0,
		quantity: "",
		vat: "",
		tax2: "",
	};

	constructor(props: Props) {
		super(props);

		this.readyToSave = this.readyToSave.bind(this);
		this.submitButtonRef = React.createRef<HTMLButtonElement>();

		this.state = {
			purchases: [],
			currentUser: AuthService.getCurrentUser(),
		};
	}

	async componentDidMount() {
		const purchases = await InvService.getPurchaseInvoicesforShop({ ItemId: this.props.itemId, ShopId: this.props.shopId });
		this.setState({
			purchases: purchases.data,
		});
	}

	validationSchema = Yup.object().shape({
		purchase: this.props.isQuantity ? Yup.string().required("Purchase Invoice is required") : Yup.string().notRequired(),
		rate: this.props.isRate ? Yup.number().required("Rate is required").positive("Must be greater than 0") : Yup.string().notRequired(),
		quantity: this.props.isQuantity ? Yup.number().required("Quantity is required").positive("Must be greater than 0") : Yup.string().notRequired(),
		vat: this.props.isVat ? Yup.number().required(`VAT is required`).min(0, "Must be greater than or equal to 0") : Yup.number().notRequired(),
		tax2: this.props.isVat && !this.props.isVatUser ? Yup.number().required("VAT is required").min(0, "Must be greater than or equal to 0") : Yup.number().notRequired(),
	});

	handleSave = () => {
		if (this.submitButtonRef !== null) {
			(this.submitButtonRef as any).current.click();
		}
	};

	readyToSave(data: any) {
		this.props.handleSave(data);
	}

	getTitle() {
		if (this.props.isQuantity) {
			return `Add quantity for ${this.props.itemName}`;
		} else if (this.props.isRate) {
			return `Update rate for ${this.props.itemName}`;
		} else if (this.props.isVat) {
			return `Update taxes for ${this.props.itemName}`;
		}

		return this.props.itemName;
	}

	render() {
		const { currentUser } = this.state;
		return (
			<ModalPopup
				show={this.props.show}
				handleClose={this.props.handleClose}
				handleSave={this.handleSave}
				title={this.getTitle()}
				content={
					<Formik enableReinitialize initialValues={this.initialValues} validationSchema={this.validationSchema} onSubmit={this.readyToSave}>
						{({ setFieldValue }) => {
							return (
								<Form className="p-10">
									{this.props.isQuantity && (
										<div className="form-group">
											<label htmlFor="purchase">Purchase Invoice (Invoice#-Supplier-Date)</label>
											<Field disabled={!this.props.isQuantity} as="select" id="purchase" name="purchase" className="form-select">
												<option value="">-- Select --</option>
												{this.state.purchases?.map((p: any) => {
													return (
														<option key={p.Id} value={p.Id}>
															{p.InvoiceNumber + "-" + p.SupplierName + "-" + moment(p.InvoiceDate).format("DD-MM-YYYY")}
														</option>
													);
												})}
											</Field>
											<ErrorMessage name="purchase" component="div" className="text-danger" />
											{this.state.purchases?.length === 0 && <div className="fw-semibold text-danger">No purchases found for this item. Please contact store admin</div>}
										</div>
									)}
									{this.props.isRate && (
										<div className="form-group pt-5">
											<label htmlFor="rate">Rate</label>
											<Field disabled={!this.props.isRate} type="text" id="rate" name="rate" className="form-control" />
											<ErrorMessage name="rate" component="div" className="text-danger" />
										</div>
									)}
									{this.props.isQuantity && (
										<div className="form-group pt-5">
											<label htmlFor="quantity">Quantity</label>
											<Field disabled={!this.props.isQuantity} type="text" id="quantity" name="quantity" className="form-control" />
											<ErrorMessage name="quantity" component="div" className="text-danger" />
										</div>
									)}
									{this.props.isVat && currentUser.headers.TAX1 && (
										<div className="form-group pt-5">
											<label htmlFor="vat">{currentUser.headers.TAX1}</label>
											<Field disabled={!this.props.isVat} type="number" id="vat" name="vat" className="form-control" />
											<ErrorMessage name="vat" component="div" className="text-danger" />
										</div>
									)}
									{this.props.isVat && !this.props.isVatUser && currentUser.headers.TAX2 && (
										<div className="form-group pt-5">
											<label htmlFor="tax2">{currentUser.headers.TAX2}</label>
											<Field disabled={!this.props.isVat} type="number" id="tax2" name="tax2" className="form-control" />
											<ErrorMessage name="tax2" component="div" className="text-danger" />
										</div>
									)}
									<button type="submit" ref={this.submitButtonRef} className="d-none"></button>
								</Form>
							);
						}}
					</Formik>
				}
			/>
		);
	}
}
