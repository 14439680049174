import api from "../../Middleware/ApiClient";

export default class InvService {
	static getShops() {
		return api.post("/inv/getShops", {});
	}

	static getCategories() {
		return api.post("/inv/getCategories", {});
	}

	static createOrUpdateCategory(data: any) {
		return api.post("/inv/createOrUpdateCategory", data);
	}

	static getItems(data: any) {
		return api.post("/inv/getItems", data);
	}

	static createOrUpdateItem(data: any) {
		return api.post("/inv/createOrUpdateItem", data);
	}

	static getPurchases(data: any) {
		return api.post("/inv/getPurchases", data);
	}

	static createOrUpdatePurchase(data: any) {
		return api.post("/inv/createOrUpdatePurchase", data);
	}

	static getPurchaseById(data: any) {
		return api.post("/inv/getPurchasebyid", data);
	}

	static getPurchaseItems(data: any) {
		return api.post("/inv/getPurchaseItems", data);
	}

	static searchItem(data: any) {
		return api.post("/inv/searchItem", data);
	}

	static createOrUpdatePurchaseItem(data: any) {
		return api.post("/inv/createOrUpdatePurchaseItem", data);
	}

	static deletePurchaseItem(data: any) {
		return api.post("/inv/deletePurchaseItem", data);
	}

	static getShopStock(data: any) {
		return api.post("/inv/getShopStock", data);
	}

	static getPurchaseInvoicesforShop(data: any) {
		return api.post("/inv/getPurchaseInvoicesforShop", data);
	}

	static addShopStockQuantity(data: any) {
		return api.post("/inv/addShopStockQuantity", data);
	}

	static addShopStockRate(data: any) {
		return api.post("/inv/addShopStockRate", data);
	}

	static addShopStockTax(data: any) {
		return api.post("/inv/addShopStockTax", data);
	}

	static shopStockAudits(data: any) {
		return api.post("/inv/shopStockAudits", data);
	}

	static getSaleData(data: any) {
		return api.post("/inv/getSaleData", data);
	}

	static searchSaleItem(data: any) {
		return api.post("/inv/searchSaleItem", data);
	}

	static saveSaleData(data: any) {
		return api.post("/inv/saveSaleData", data);
	}

	static addSalePayment(data: any) {
		return api.post("/inv/addSalePayment", data);
	}

	static deleteSalePayment(data: any) {
		return api.post("/inv/deleteSalePayment", data);
	}

	static deleteSaleItem(data: any) {
		return api.post("/inv/deleteSaleItem", data);
	}

	static shopSalesReport(data: any) {
		return api.post("/inv/shopSalesReport", data);
	}

	static getDashboardData(data: any) {
		return api.post("/inv/getDashboardData", data);
	}

	static getTaxReport(data: any) {
		return api.post("/inv/getTaxReport", data);
	}

	static createOrUpdateExpense(data: any) {
		return api.post("/inv/createOrUpdateExpense", data);
	}

	static getExpenses(data: any) {
		return api.post("/inv/getExpenses", data);
	}

	static deleteExpense(data: any) {
		return api.post("/inv/deleteExpense", data);
	}

	static generateInvoice(data: any) {
		return api.post("/inv/generateInvoice", data);
	}

	static getTranslatedHeaders(key: any) {
		return api.post("/inv/getTranslatedHeaders", key);
	}

	static editSubmittedSale(data: any) {
		return api.post("/inv/editSubmittedSale", data);
	}
}
