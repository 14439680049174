import { Component } from "react";
import { Navigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import logo from "../../Assets/Logos/logo-no-background.png";
import AuthService from "../../Services/Auth/Auth.service";
import moment from "moment";
import { AppConstants } from "../../Utils/AppConstants";

type Props = {};

type State = {
  redirect: string | null;
  username: string;
  password: string;
  loading: boolean;
  message: string;
};

export default class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);

    this.state = {
      redirect: null,
      username: "",
      password: "",
      loading: false,
      message: "",
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (currentUser) {
      // this.setState({ redirect: "/profile" });
    }
  }

  validationSchema() {
    return Yup.object().shape({
      username: Yup.string().required("Username is required!"),
      password: Yup.string().required("Password is required!"),
    });
  }

  async handleLogin(formValue: { username: string; password: string }) {
    const { username, password } = formValue;
    this.setState({
      message: "",
      loading: true,
    });

    var res = await AuthService.login(username, password);
    if (res.response.message) {
      this.setState({
        loading: false,
        message: res.response.message,
      });
    } else {
      console.log(res.response);
      const result = JSON.stringify(res.response);
      sessionStorage.setItem(AppConstants.SESSION_KEY_PAYLOAD, result);
      this.setState({
        redirect: "/home",
      });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    const { loading, message } = this.state;

    const initialValues = {
      username: "",
      password: "",
    };

    return (
      <div className="bg-light vh-100 d-flex align-items-center justify-content-center">
        <div className="login border rounded-2 shadow-lg">
          <div className="d-flex align-items-center justify-content-center bg-black p-20 rounded-top-2">
            <img
              src={logo}
              alt="logos"
              className="mx-auto img-fluid"
              width={"400px"}
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div className="ps-20 pe-20">
            <h6 className="card-title fw-light mb-10 fs-4 fw-semibold mt-10">
              Sign In
            </h6>
            <Formik
              initialValues={initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.handleLogin}
            >
              <Form>
                <div className="form-group">
                  <Field
                    name="username"
                    type="text"
                    className="form-control"
                    placeholder="Username"
                  />
                  <ErrorMessage
                    name="username"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group mt-10">
                  <Field
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="d-flex mt-10">
                  <div className="col-12 col-md-6 ">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Login</span>
                    </button>
                  </div>
                </div>

                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-end mt-5 mb-5">
                  <a href="/forgotpassword" className="btn btn-link fs-6">
                    Forgot Password ?
                  </a>
                </div>
              </Form>
            </Formik>
          </div>
          <div className="d-flex align-items-baseline justify-content-center border-top">
            <p className="fs-6 mt-5">
              NewDawnIT &copy; {moment().format("YYYY")}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
