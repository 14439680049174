import { Navigate, useLocation } from "react-router-dom";
import UnAuthorized from "../Components/Auth/UnAuthorized.component";
import AuthService from "../Services/Auth/Auth.service";

const ProtectedRoute = ({ children, roles }: { children: JSX.Element; roles: Array<String> }) => {
  let location = useLocation();
  const isAuthenticated = AuthService.isAuthenticated();
  const u = AuthService.getCurrentUser();
  if (u !== null) {
    if (isAuthenticated) {
      const userRoles = u.user.Roles.split(",");

      const userHasRequiredRole = userRoles.some((requiredRole: any) => roles.includes(requiredRole));

      if (!userHasRequiredRole) {
        return <UnAuthorized />;
      }
    }
  }

  if (!isAuthenticated || u === null) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
