/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import JSZip from "jszip";
import TRadViewScanLeft from "./TRadViewScanLeft.component";
import TRadLayout from "../../Layouts/TRad/TRadLayout";
import TRadViewScanZoom from "./TRadViewScanZoom.component";
import TRadReportDialog from "./TRadReportDialog.component";
import TRadService from "../../Services/TRad/TRad.service";
import { Navigate } from "react-router-dom";
import ModalPopup from "../Shared/ModalPopup";
import "../../Styles/tradviewscan.custom.css";
import { FaArrowRotateLeft, FaComment, FaMagnifyingGlassMinus, FaMagnifyingGlassPlus, FaPlus } from "react-icons/fa6";

type State = {
	selectedFile: any | null;
	imageFiles: Blob[];
	selectedImage: any;
	showImage: boolean;
	isShowDialog: boolean;
	savedReport: any;
	redirect: string | null;
	showConfirmation: boolean;
	submitData: any;
	className: string;
	close: string;
};

export default class TRadViewScan extends React.Component<any, State> {
	mainClassName = "social hide";
	closeClassName = "close-button";
	zoomRef: any;
	constructor(props: any) {
		super(props);

		this.state = {
			selectedFile: [],
			imageFiles: [],
			selectedImage: null,
			showImage: false,
			isShowDialog: false,
			savedReport: "",
			redirect: null,
			showConfirmation: false,
			submitData: [],
			className: this.mainClassName,
			close: this.closeClassName,
		};

		this.toggle = this.toggle.bind(this);
		this.zoomRef = React.createRef();
	}

	blobToFile = (blob: any, fileName: any) => {
		const file = new File([blob], fileName, { type: blob.type });

		return file;
	};

	getScanIdFromURL() {
		const currentURL = window.location.href;
		const url = new URL(currentURL);
		const id = url.searchParams.get("id");
		return id;
	}

	handleImageClick = (index: number) => {
		const { imageFiles } = this.state;
		this.setState({ selectedImage: imageFiles[index] });
	};

	async saveReport(data: any, isSubmitted: boolean) {
		const scanId = this.getScanIdFromURL();
		const object = {
			scanId: scanId,
			isSubmitted: isSubmitted,
			report: data,
		};

		const res = await TRadService.saveScanReport(object);

		const respons: any = await TRadService.downloadFileById(scanId);
		this.setState({
			savedReport: respons.data.data.report,
		});

		if ((res.success === true, isSubmitted === true)) {
			this.setState({
				redirect: `/tradunderreview`,
			});
		}
	}

	async componentDidMount(): Promise<void> {
		const scanId = this.getScanIdFromURL();
		if (scanId != null) {
			try {
				const respons: any = await TRadService.downloadFileById(scanId);
				this.setState({
					savedReport: respons.data.data.report,
				});
				if (respons != null) {
					const uint8Array = new Uint8Array(respons.data.data.image.data);
					const blob = new Blob([new Blob([uint8Array])], {
						type: "application/zip",
					});
					const zip = new JSZip();
					zip.loadAsync(blob)
						.then(async (zip) => {
							const imagePromises: any = [];
							// Extract and store each image in the imagePromises array
							zip.forEach((relativePath, file) => {
								if (file.dir) return; // Skip directories
								imagePromises.push(file.async("blob").then((blob) => ({ name: relativePath, blob })));
							});

							const imagesData = await Promise.all(imagePromises);

							const fileName = "test"; // You can customize the filename if needed
							const file = this.blobToFile(new Blob([imagesData[0].blob], { type: "application/zip" }), fileName);
							const contents = await zip.loadAsync(file);
							const images1 = Object.values(contents.files).filter((file) => file.name.match(/\.(jpg|jpeg|png|gif)$/));
							const imageBlobs = await Promise.all(images1.map((image) => image.async("blob")));
							this.setState({
								imageFiles: imageBlobs.filter((x) => {
									return x.size > 10000;
								}),
								showImage: true,
							});
						})
						.catch((error) => {
							console.error("Error extracting ZIP:", error);
						});
				}
			} catch (error) {
				console.error("Error handling download:", error);
			}
		}
	}

	toggle() {
		if (this.state.className === "social") {
			this.setState({
				className: "social hide",
				close: "close-button",
			});
		} else {
			this.setState({
				className: "social",
				close: "close-button close-button-rotate",
			});
		}
	}

	render(): React.ReactNode {
		const { imageFiles, selectedImage } = this.state;
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		return (
			<>
				<TRadLayout showMargin={false}>
					<div className="d-flex mt-49">
						{imageFiles.length ? (
							<TRadViewScanLeft
								selectedImage={(selectedImage) => {
									this.setState(
										{
											selectedImage,
										},
										() => {
											this.setState({
												showImage: true,
											});
										}
									);
								}}
								imageFiles={imageFiles}
							/>
						) : (
							<></>
						)}
						{this.state.showImage && <TRadViewScanZoom ref={this.zoomRef} selectedImage={selectedImage ? selectedImage : imageFiles[0]} />}
					</div>
					<div className="floatingwrapper">
						<div
							className={this.state.className}
							title="Reset"
							onClick={() => {
								this.zoomRef.current.reset();
							}}
						>
							<FaArrowRotateLeft className="iconstyle" />
						</div>
						<div
							className={this.state.className}
							title="Zoom in"
							onClick={() => {
								this.zoomRef.current.zoomIn();
							}}
						>
							<FaMagnifyingGlassPlus className="iconstyle" />
						</div>
						<div
							className={this.state.className}
							title="Zoom out"
							onClick={() => {
								this.zoomRef.current.zoomOut();
							}}
						>
							<FaMagnifyingGlassMinus className="iconstyle" />
						</div>
						<div
							title="Enter Observation"
							className={this.state.className}
							onClick={() => {
								this.setState(
									{
										isShowDialog: true,
									},
									() => this.toggle()
								);
							}}
						>
							<FaComment className="iconstyle" />
						</div>
						<div className={this.state.close} onClick={this.toggle} title="Show/Hide">
							<FaPlus className="iconstyle" />
						</div>
					</div>
				</TRadLayout>
				{this.state.isShowDialog && (
					<TRadReportDialog
						show={this.state.isShowDialog}
						isEdit={true}
						recordId={""}
						savedReport={this.state.savedReport}
						handleSave={(data) => {
							this.saveReport(data, false);
						}}
						handleSubmit={(data) => {
							this.setState({
								isShowDialog: false,
								showConfirmation: true,
								submitData: data,
							});
						}}
						handleClose={() => {
							this.setState({
								isShowDialog: false,
							});
						}}
					></TRadReportDialog>
				)}
				<ModalPopup
					show={this.state.showConfirmation}
					title={"Submit Confirmation"}
					content={<div className="form-floating form-control form-control-lg">Are you sure to submit your report ? Clicking submit will save the observation and mark the scan as completed.</div>}
					handleClose={() => {
						this.setState({
							showConfirmation: false,
							isShowDialog: true,
						});
					}}
					handleSave={() => {
						this.saveReport(this.state.submitData, true);
					}}
				/>
			</>
		);
	}
}
