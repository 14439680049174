import { Component } from "react";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import InvService from "../../Services/Inv/Inv.service";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { Badge, Col, Row, Table } from "react-bootstrap";
import moment from "moment";
import AuthService from "../../Services/Auth/Auth.service";
import ExportToExcel from "../Shared/ExportToExcel";
import "../../Styles/invTable.custom.css";

type Props = {};

type State = {
	redirect: string | null;
	ready: boolean;
	selectedFilter: string;
	currentUser: any;
	items: any;
	totalRow: any;
};

type itemType = {
	Invoice: string;
	PaidAmount: number;
	Tax: number;
};

export default class InvTaxes extends Component<Props, State> {
	itemTableHeaders: TableColumnType<itemType>[] = [
		{
			prop: "Invoice",
			title: "Invoice",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "PaidAmount",
			title: "Amount",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "Tax",
			title: "Tax",
			isSortable: true,
			isFilterable: true,
		},
	];

	filters = ["This Quarter", "Previous Quarter", "This Year", "Last Year"];

	constructor(props: Props) {
		super(props);
		this.state = {
			redirect: null,
			ready: true,
			selectedFilter: this.filters[0],
			currentUser: AuthService.getCurrentUser(),
			items: [],
			totalRow: [],
		};
	}

	async componentDidMount() {
		console.log(this.state.currentUser);
		await this.loadValues();
	}

	async loadValues() {
		const taxResponse = await InvService.getTaxReport({ filter: this.state.selectedFilter });
		console.log(taxResponse.data);
		if (taxResponse.data.length > 0) {
			this.setState({
				items: taxResponse.data.filter((x: any) => x.Invoice !== null),
				totalRow: taxResponse.data.filter((x: any) => x.Invoice === null),
			});
		} else {
			this.setState({
				items: [],
				totalRow: [],
			});
		}
	}

	getFrom(filter: any) {
		var start = moment().startOf("quarter").format("YYYY-MM-DD");
		if (filter === "Previous Quarter") {
			start = moment().subtract(1, "quarter").startOf("quarter").format("YYYY-MM-DD");
		} else if (filter === "This Year") {
			start = moment().startOf("year").startOf("quarter").format("YYYY-MM-DD");
		} else if (filter === "Last Year") {
			start = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
		}

		return start;
	}

	getTo(filter: any) {
		const currentDate = moment();
		var end = currentDate.endOf("quarter").format("YYYY-MM-DD");
		if (filter === "This Previous Quarter") {
			end = currentDate.subtract(1, "quarter").endOf("quarter").format("YYYY-MM-DD");
		} else if (filter === "This Year") {
			end = moment().endOf("year").format("YYYY-MM-DD");
		} else if (filter === "Last Year") {
			end = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
		}

		return end;
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<>
					<div className="mt-55 px-10"></div>
					{this.state.ready && (
						<>
							<DatatableWrapper
								body={this.state.items}
								headers={this.itemTableHeaders}
								paginationOptionsProps={{
									initialState: {
										rowsPerPage: 10,
										options: [5, 10, 15, 20],
									},
								}}
								sortProps={{
									initialState: {
										order: "desc",
										prop: "Invoice",
									},
								}}
								key={Math.random()}
							>
								<Row className="mb-10">
									<Col xs={12} md={6} lg={4} className="col-4 d-flex flex-col justify-content-end align-items-end gap-5 mb-3 mb-md-0">
										<Filter placeholder="Search..." />
										<ExportToExcel fileName={`SalesReport_${moment().format("DD-MM-YYYY-HH-mm")}`} data={this.state.items} sheetName="Tax Report" fields={null} />
									</Col>
									<Col className="d-flex justify-content-end align-items-end gap-5 ">
										{this.filters.map((x) => (
											<Badge
												key={Math.random()}
												className={x === this.state.selectedFilter ? "bg-blue-400 cursor-pointer" : "bg-blue-700 cursor-pointer"}
												onClick={() =>
													this.setState(
														{
															selectedFilter: x,
														},
														() => {
															this.loadValues();
														}
													)
												}
											>
												{x}
											</Badge>
										))}
									</Col>
								</Row>
								{this.state.totalRow.length > 0 && (
									<Row className="bg-white mx-1 border-bottom">
										<Col>From {this.getFrom(this.state.selectedFilter)}</Col>
										<Col>To {this.getTo(this.state.selectedFilter)}</Col>
										<Col className="fs-5 justify-content-end align-content-end d-flex bg-gray-200">Total Tax {this.state.totalRow[0].Tax}</Col>
									</Row>
								)}
								<Table className="dataTable">
									<TableHeader />
									<TableBody />
								</Table>
								<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
									<PaginationOptions />
									<div className="ms-5" />
									<Pagination />
								</Col>
							</DatatableWrapper>
						</>
					)}
				</>
			</InvLayout>
		);
	}
}
