import moment from "moment";
import { Component } from "react";
import { Navbar, NavbarBrand } from "react-bootstrap";
import { Navigate } from "react-router-dom";

type Props = {};
type State = {
	redirect: string | null;
};

export default class AppFooter extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
		};
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		return (
			<div className="fixed-bottom">
				<Navbar bg="dark">
					<NavbarBrand className="text-white ms-auto -me-2">Copyright NewDawnIT {moment().format("YYYY")}</NavbarBrand>
				</Navbar>
			</div>
		);
	}
}
