import React, { Component } from "react";
import { Modal, Button, Col, Row } from "react-bootstrap";

type Props = {
	show: boolean;
	confirmModal: (selection: boolean, id?: string) => void;
	title?: string;
	message: string;
	id?: string;
};

type State = {
	show: boolean;
};

export default class ConfirmDialog extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// Initialize the state using the value of the 'show' prop
		this.state = {
			show: props.show,
		};
	}

	render() {
		return (
			<Modal show={this.props.show} onHide={() => this.props.confirmModal(false)} backdrop="static">
				<Modal.Header className="bg-primary text-white p-10" closeButton closeVariant="white">
					<Modal.Title>{this.props.title || "Delete Confirmation"}</Modal.Title>
				</Modal.Header>
				<Modal.Body className="py-20 px-10 bg-red-100">
					<Row>
						<Col className="fs-5">{this.props.message}</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="p-10">
					<Button
						className="bg-secondary text-white"
						variant="default"
						onClick={() => {
							this.props.confirmModal(false);
						}}
					>
						Cancel
					</Button>
					<Button variant="danger" onClick={() => this.props.confirmModal(true, this.props.id)}>
						Ok
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
