import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { FaFloppyDisk } from "react-icons/fa6";

type Props = {
	show: boolean;
	title: string;
	content: React.ReactNode; // Use React.ReactNode for content
	showSubmit?: boolean;
	hideSave?: boolean;
	handleClose: () => void;
	handleSave: () => void;
	handleSubmit?: () => void;
	dialogClassName?: string;
	size?: any;
	showOptionAddScan?: boolean;
};

type State = {
	show: boolean;
	showOptionAddScan: boolean;
};

export default class ModalPopup extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		// Initialize the state using the value of the 'show' prop
		this.state = {
			show: props.show,
			showOptionAddScan: props.showOptionAddScan ? props.showOptionAddScan : false,
		};
	}

	handleClose = () => {
		this.props.handleClose();
	};

	OnSave = () => {
		this.props.handleSave();
	};

	OnSubmit = () => {
		if (this.props.handleSubmit) this.props.handleSubmit();
	};

	componentDidUpdate(prevProps: Props) {
		if (prevProps.show !== this.props.show) {
			this.setState({ show: this.props.show });
		}
	}
	render() {
		return (
			<div>
				<Modal size={this.props.size ? this.props.size : "lg"} show={this.state.show} onHide={this.handleClose} centered backdrop="static" dialogClassName={this.props.dialogClassName}>
					<Modal.Header className="bg-primary text-white p-10" closeButton closeVariant="white">
						<Modal.Title>{this.props.title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{this.props.content}</Modal.Body>
					{!this.props.showOptionAddScan && (
						<Modal.Footer className="p-10">
							<Button variant="secondary" onClick={this.handleClose}>
								<FaTimes /> Close
							</Button>
							{!this.props.hideSave && (
								<Button variant="primary" onClick={this.OnSave}>
									<FaFloppyDisk /> Save
								</Button>
							)}
							{this.props.showSubmit && (
								<Button variant="primary" onClick={this.OnSubmit}>
									<FaFloppyDisk /> Submit
								</Button>
							)}
						</Modal.Footer>
					)}
				</Modal>
			</div>
		);
	}
}
