import { Component } from "react";
import { Navigate } from "react-router-dom";
import TRadLayout from "../../Layouts/TRad/TRadLayout";
import { AppConstants } from "../../Utils/AppConstants";
import GenericUsers from "../Shared/GenericUsers.component";
type Props = {};
type State = {
	redirect: string | null;
};

export default class TRadManageRadiologist extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
		};
	}

	componentDidMount(): void {}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<TRadLayout showMargin={true}>
				<>
					<div className="mt-50 px-10">
						<div className="row">
							<div className="col-12">
								<h4 className="h3 mb-0 text-gray-800">Manage Radiologists</h4>
								<p>A place to manage all radiologists you work with</p>
							</div>
						</div>
					</div>
					<GenericUsers role={AppConstants.ROLE_RADIOLOGIST} />
				</>
			</TRadLayout>
		);
	}
}
