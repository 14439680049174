/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import React from "react";
import { DatatableWrapper, Filter, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaEdit } from "react-icons/fa";
import { FaPlus, FaRegFloppyDisk, FaTrash, FaXmark } from "react-icons/fa6";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import { toast, ToastContainer } from "react-toastify";
import { Col, Form, InputGroup, Pagination, Row, Table } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import InvService from "../../Services/Inv/Inv.service";
import ExportToExcel from "../Shared/ExportToExcel";
import ConfirmDialog from "../Shared/ConfirmDialog";
import deepCopy from "../../Utils/DeepCopy";
import "../../Styles/invTable.custom.css";

type Props = { isShop: boolean };

type State = {
	redirect: string | null;
	expenses: Array<any>;
	expenseCopy: Array<any>;
	ready: boolean;
	tableKey: number;
	shops: Array<any>;
	selectedShop: any;
	selectedMonth: any;
	totalExpense: any;
	showConfirmDialog: boolean;
	deleteId: any;
};

type expenses = {
	Id?: string;
	ExpenseDate: string;
	ExpenseDetails: string;
	ExpenseAmount: number;
	Action: any;
	IsEdit: boolean;
	IsNew: boolean;
	ShopId?: number;
	isShop?: boolean;
};

export default class InvExpenses extends React.Component<Props, State> {
	expenseTableHeaders: TableColumnType<expenses>[] = [
		{
			prop: "ExpenseDate",
			title: "Expense Date",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<>
							<div>Expense Date</div>
							<div>
								<input
									type="date"
									value={row.ExpenseDate}
									className="form-control"
									onChange={(e) => {
										row.ExpenseDate = e.target.value;
										this.setState({
											expenses: this.state.expenses,
										});
									}}
									min={this.getFirstDayOfMonth()}
									max={this.getLastDayOfMonth()}
								/>
							</div>
						</>
					)}
					{!row.IsEdit && <span>{moment(row.ExpenseDate).format("DD-MM-YYYY")}</span>}
				</div>
			),
		},
		{
			prop: "ExpenseDetails",
			title: "Expense Details",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<>
							<div>Expense Details</div>
							<div>
								<input
									value={row.ExpenseDetails}
									className="form-control"
									onChange={(e) => {
										row.ExpenseDetails = e.target.value;
										this.setState({
											expenses: this.state.expenses,
										});
									}}
								/>
							</div>
						</>
					)}
					{!row.IsEdit && <span>{row.ExpenseDetails}</span>}
				</div>
			),
		},
		{
			prop: "ExpenseAmount",
			title: "Expense Amount",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<>
					<div>
						{row.IsEdit && (
							<>
								<div>Expense Amount</div>
								<div>
									<input
										type="number"
										value={row.ExpenseAmount}
										className="form-control"
										onChange={(e) => {
											row.ExpenseAmount = Number(e.target.value);
											this.setState({
												expenses: this.state.expenses,
											});
										}}
									/>
								</div>
							</>
						)}
						{!row.IsEdit && <span>{row.ExpenseAmount}</span>}
					</div>
				</>
			),
		},
		{
			prop: "Action",
			title: "Action",
			cell: (row) => {
				const { expenses } = this.state;
				return (
					<div className="">
						{row.IsEdit && <div>Action</div>}
						<div className="d-flex gap-2 align-items-center">
							{!row.IsEdit && (
								<>
									<button
										className="btn btn-primary d-flex"
										onClick={() => {
											row.IsEdit = !row.IsEdit;
											this.setState({
												expenses,
											});
										}}
									>
										<span className="text-white">
											<FaEdit />
										</span>
										<span className="ms-3 mt-1">Edit</span>
									</button>
								</>
							)}
							{row.IsEdit && (
								<>
									<button
										className="btn btn-success d-flex"
										onClick={() => {
											this.saveValue(row);
										}}
									>
										<span className="text-white">
											<FaRegFloppyDisk />
										</span>
										<span className="ms-3 mt-1">Save</span>
									</button>
									<button
										className="btn btn-warning d-flex ms-4 "
										onClick={() => {
											row.IsEdit = !row.IsEdit;
											if (row.IsNew) {
												this.setState({
													expenses: expenses.filter((x) => {
														return x.Id !== row.Id;
													}),
												});
											} else {
												this.setState({
													expenses: deepCopy(this.state.expenseCopy),
												});
											}
										}}
									>
										<span className="text-white">
											<FaXmark />
										</span>
										<span className="ms-3 mt-1 text-white">Cancel</span>
									</button>
								</>
							)}
							{!row.IsNew && (
								<button
									onClick={() =>
										this.setState({
											deleteId: row.Id,
											showConfirmDialog: true,
										})
									}
									title="Delete"
									className="btn bg-red-500 d-flex"
								>
									<span className="text-white">
										<FaTrash />
									</span>
								</button>
							)}
						</div>
					</div>
				);
			},
		},
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			expenses: [],
			expenseCopy: [],
			ready: true,
			tableKey: Math.random(),
			shops: [],
			selectedShop: null,
			selectedMonth: null,
			totalExpense: 0,
			showConfirmDialog: false,
			deleteId: null,
		};

		this.onShopChange = this.onShopChange.bind(this);
	}

	async componentDidMount() {
		if (!this.props.isShop) {
			const response = await InvService.getShops();
			const shops = new Array<any>();
			response.data.map((s: any) =>
				shops.push({
					label: s.Name,
					value: s.Id,
				})
			);
			this.setState({
				shops,
			});
		}
	}

	deepCopy = (obj: any) => {
		if (obj === null || typeof obj !== "object") {
			return obj;
		}

		if (Array.isArray(obj)) {
			const arrCopy: any = [];
			for (let i = 0; i < obj.length; i++) {
				arrCopy[i] = this.deepCopy(obj[i]);
			}
			return arrCopy;
		}

		const objCopy: any = {};
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				objCopy[key] = this.deepCopy(obj[key]);
			}
		}

		return objCopy;
	};

	deleteExpense = async (confirm: any) => {
		if (confirm) {
			const res = await InvService.deleteExpense({ expenseId: this.state.deleteId });
			if (res.data.success) {
				toast.success(res.data.message);
			} else {
				toast.error(res.data.message);
			}
			this.loadValues();
			this.setState({
				showConfirmDialog: false,
			});
		} else {
			this.setState({
				showConfirmDialog: false,
			});
		}
	};

	onShopChange(e: any) {
		this.setState(
			{
				selectedShop: e,
			},
			() => {
				if (this.state.selectedMonth) {
					this.loadValues();
				} else {
					this.setState({
						expenses: [],
					});
				}
			}
		);
	}

	getFirstDayOfMonth = () => {
		if (this.state.selectedMonth) {
			const [year, month] = this.state.selectedMonth.split("-");
			return `${year}-${month}-01`;
		}
		return "";
	};

	getLastDayOfMonth = () => {
		console.log(this.state.selectedMonth);
		if (this.state.selectedMonth) {
			const [year, month] = this.state.selectedMonth.split("-");
			const lastDay = new Date(year, month, 0).getDate();
			return `${year}-${month}-${lastDay}`;
		}
		return "";
	};

	loadValues = async () => {
		this.setState({
			expenses: [],
			totalExpense: 0,
		});
		const response = await InvService.getExpenses({ shopId: this.state.selectedShop?.value, isShop: this.props.isShop, startDate: this.getFirstDayOfMonth(), endDate: this.getLastDayOfMonth() });
		if (response.data) {
			this.setState({
				expenses: response.data,
				expenseCopy: deepCopy(response.data),
				ready: true,
				totalExpense: response.data.reduce((total: any, expense: any) => total + expense.ExpenseAmount, 0),
			});
		}
	};

	saveValue = async (row: expenses) => {
		if (!row.ExpenseDate) {
			return toast.error("Please Select Expense Date");
		}
		if (!row.ExpenseAmount) {
			return toast.error("Please Add Expense Amount");
		}
		if (row.IsNew) {
			delete row.Id;
		}
		if (!this.props.isShop) {
			row.ShopId = this.state.selectedShop.value;
		}
		row.isShop = this.props.isShop;
		const res = await InvService.createOrUpdateExpense(row);
		if (res.data) {
			if (res.data.success) {
				toast.success(res.data.message);
			} else {
				toast.error(res.data.message);
			}
			this.loadValues();
		}
		row.IsEdit = false;
		this.setState({
			expenses: this.state.expenses,
		});
	};

	handleMonthChange = (event: any) => {
		this.setState(
			{
				selectedMonth: event.target.value,
			},
			() => {
				if (event.target.value) {
					this.setState({
						expenses: [],
					});
				}
				if (!this.props.isShop && this.state.selectedShop) {
					this.loadValues();
				} else if (this.props.isShop) {
					this.loadValues();
				}
			}
		);
	};

	getMonthInWords = () => {
		const [year, month] = this.state.selectedMonth.split("-");
		const monthIndex = parseInt(month, 10) - 1;
		const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		return `${monthNames[monthIndex]} ${year}`;
	};

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<ConfirmDialog confirmModal={(confirm: any) => this.deleteExpense(confirm)} message="Do you want to delete this Expense?" show={this.state.showConfirmDialog} title="Delete Expense" id={this.state.deleteId} />;
				<>
					<div className="mt-50 mb-10">
						<div className="row">
							<div className="col-12">
								<h4 className="h3 mb-0 text-gray-800">Expenses</h4>
							</div>
						</div>
					</div>
					{this.state.ready && (
						<DatatableWrapper
							body={this.state.expenses}
							headers={this.expenseTableHeaders}
							paginationOptionsProps={{
								initialState: {
									rowsPerPage: 10,
									options: [5, 10, 15, 20],
								},
							}}
							key={this.state.tableKey}
							sortProps={{
								initialState: {
									order: "desc",
									prop: "ExpenseDate",
								},
							}}
						>
							<Row className="mb-10">
								{this.props.isShop === false && (
									<Col>
										<Select name="shops" value={this.state.selectedShop} onChange={this.onShopChange} options={this.state.shops}></Select>
									</Col>
								)}
								{(this.props.isShop || this.state.selectedShop) && (
									<Col className=" col-3">
										<InputGroup>
											<Form.Control type="month" id="monthPicker" value={this.state.selectedMonth} onChange={this.handleMonthChange} />
										</InputGroup>
									</Col>
								)}
								{((this.props.isShop === false && this.state.selectedShop !== null && this.state.selectedMonth) || (this.props.isShop === true && this.state.selectedMonth)) && (
									<Col className="col-4 d-flex flex-col justify-content-end align-items-end gap-5">
										<Filter placeholder="Search..." />
										<ExportToExcel fileName={`ExpenseReport_${moment().format("DD-MM-YYYY-HH-mm")}`} data={this.state.expenses} sheetName="Expense Report" fields={null} />
									</Col>
								)}
								<Col className="col-12 mt-5 col-md mt-md-0 d-flex justify-content-end align-items-end">
									{((this.props.isShop === false && this.state.selectedShop !== null && this.state.selectedMonth) || (this.props.isShop === true && this.state.selectedMonth)) && (
										<button
											className="btn btn-primary d-flex ms-10"
											onClick={() => {
												const { expenses } = this.state;
												expenses.push({
													Id: Math.random(),
													ExpenseDate: "",
													ExpenseDetails: "",
													ExpenseAmount: "",
													IsEdit: true,
													IsNew: true,
												});
												this.setState({
													expenses,
													tableKey: Math.random(),
												});
											}}
										>
											<span className="text-white">
												<FaPlus />
											</span>
											<span className="ms-3 mt-1">Create New</span>
										</button>
									)}
								</Col>
							</Row>
							<Row>{((this.props.isShop === false && this.state.selectedShop !== null && this.state.selectedMonth) || (this.props.isShop === true && this.state.selectedMonth)) && <Col className="fw-bold py-5">Total: {this.state.totalExpense}</Col>}</Row>
							<Table className="dataTable">
								<TableHeader />
								<TableBody />
							</Table>
							<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
								<PaginationOptions />
								<div className="ms-5" />
								<Pagination />
							</Col>
						</DatatableWrapper>
					)}
					<ToastContainer />
				</>
			</InvLayout>
		);
	}
}
