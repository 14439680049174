import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../Utils/ProtectedRoute";
import { AppConstants } from "../Utils/AppConstants";
import InvHome from "../Components/Inv/InvHome.component";
import InvUser from "../Components/Inv/InvUser.component";
import InvCategories from "../Components/Inv/InvCategories.component";
import InvItems from "../Components/Inv/InvItems.component";
import InvPurchases from "../Components/Inv/InvPurchases.component";
import InvPurchaseItems from "../Components/Inv/InvPurchaseItems.component";
import InvShopStocks from "../Components/Inv/InvShopStocks.component";
import InvShopSale from "../Components/Inv/InvShopSale.component";
import InvShopSaleReport from "../Components/Inv/InvShopSaleReport";
import InvTaxes from "../Components/Inv/InvTaxes";
import InvExpenses from "../Components/Inv/InvExpenses.component";

export class InvRoutes extends React.Component {
	render(): React.ReactNode {
		return (
			<>
				<Routes>
					<Route
						path="/invhome"
						element={
							<ProtectedRoute roles={AppConstants.INV_ROLES}>
								<InvHome />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manage/invusers"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<InvUser />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manage/invcats"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<InvCategories />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manage/invitems"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<InvItems />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manage/invstorepurchases"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<InvPurchases isShop={false} />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manage/invshoppurchases"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN, AppConstants.ROLE_SHOPADMIN]}>
								<InvPurchases isShop={true} />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manage/invstorepurchaseitem/:id"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<InvPurchaseItems isShop={false} />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manage/invshoppurchaseitem/:id"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<InvPurchaseItems isShop={true} />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/invshopstocks"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_SHOPADMIN]}>
								<InvShopStocks isShop={true} />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manage/invadminshopstocks"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<InvShopStocks isShop={false} />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manage/invadminexpenses"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<InvExpenses isShop={false} />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/manageinvexpenses"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN, AppConstants.ROLE_SHOPADMIN]}>
								<InvExpenses isShop={true} />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/invshopsales/:salesId?"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_SHOPADMIN]}>
								<InvShopSale isShop={true} />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/invadminsales/:salesId?"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<InvShopSale isShop={false} />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/invshopsaleaudits"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_SHOPADMIN, AppConstants.ROLE_CLIENTADMIN]}>
								<InvShopSaleReport />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/invtaxes"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<InvTaxes />
							</ProtectedRoute>
						}
					/>
				</Routes>
			</>
		);
	}
}
