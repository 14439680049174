import { Component } from "react";
import { Badge, Col, Row, Table } from "react-bootstrap";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaEdit } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { Navigate } from "react-router-dom";
import AppLayout from "../../Layouts/App/AppLayout";
import AuthService from "../../Services/Auth/Auth.service";
import PlatformService from "../../Services/Auth/Platform.service";
import { toast, ToastContainer } from "react-toastify";
import ApplicationDialog from "./ApplicationDialog.component";

type Props = {};
type State = {
	redirect: string | null;
	ready: boolean;
	currentUser: any | null;
	apps: Array<appType>;
	isShowDialog: boolean;
	selectedRecord: any | null;
	showDeleteConfirm: boolean;
	clients: Array<any>;
};

type clientType = {
	label: string;
	value: string;
};
type appType = {
	Id: string;
	Name: string;
	Route: string;
	Clients: Array<clientType>;
	Action: any;
};

export default class Clients extends Component<Props, State> {
	appTableHeaders: TableColumnType<appType>[] = [
		{
			prop: "Name",
			title: "Name",
			isFilterable: true,
			isSortable: true,
		},
		{
			prop: "Route",
			title: "Route",
			isFilterable: false,
			isSortable: false,
		},
		{
			prop: "Clients",
			title: "Clients",
			cell: (row) => (
				<div>
					{row.Clients.map((c: clientType) => (
						<div key={Math.random()}>
							<Badge bg="success">{c.label}</Badge>
						</div>
					))}
				</div>
			),
		},
		{
			prop: "Action",
			title: "Action",
			isFilterable: false,
			cell: (row) => (
				<div className="d-flex">
					<button
						className="btn btn-primary d-flex"
						onClick={() =>
							this.setState({
								selectedRecord: row,
								isShowDialog: true,
							})
						}
					>
						<span className="text-white">
							<FaEdit />
						</span>
						<span className="ms-3 mt-1">Edit</span>
					</button>
				</div>
			),
		},
	];
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			currentUser: null,
			apps: [],
			isShowDialog: false,
			selectedRecord: null,
			showDeleteConfirm: false,
			clients: [],
		};

		this.loadApps = this.loadApps.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	async componentDidMount() {
		const currentUser = AuthService.getCurrentUser();
		const res = await PlatformService.getClients();
		const clients: Array<any> = [];
		res.data.clients.map((c: any) =>
			clients.push({
				label: c.Name,
				value: c.Id,
			})
		);
		this.setState(
			{
				currentUser,
				clients,
			},
			async () => {
				await this.loadApps();
			}
		);
	}

	async loadApps() {
		const response = await PlatformService.getApps();
		if (response.status === 200) {
			this.setState({
				ready: true,
				apps: response.data.apps,
			});
		}
	}

	async handleSave(data: any) {
		const req = {
			Id: data.id,
			Name: data.name,
			Route: data.route,
			SelectedClients: data.selectedClients,
		};
		const res = req.Id == null ? await PlatformService.createApp(req) : await PlatformService.updateApp(req);
		if (res.status === 200) {
			if (res.data.success === false && res.data.errorMessage.length > 0) {
				toast.error(res.data.errorMessage[0]);
			} else if (res.data.success) {
				if (req.Id === null || req.Id === undefined) {
					toast.success("App created successfully!");
				} else {
					toast.success("App updated successfully!");
				}
				this.setState(
					{
						isShowDialog: false,
					},
					async () => await this.loadApps()
				);
			}
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<AppLayout>
				<>
					{this.state.ready && this.state.currentUser !== null && (
						<>
							<div className="mt-60">
								<div className="d-sm-flex align-items-center justify-content-between mb-12">
									<h1 className="h3 mb-0 text-gray-800">Applications</h1>
								</div>
							</div>
							<DatatableWrapper
								body={this.state.apps}
								headers={this.appTableHeaders}
								paginationOptionsProps={{
									initialState: {
										rowsPerPage: 10,
										options: [5, 10, 15, 20],
									},
								}}
							>
								<Row className="mb-10">
									<Col className="col-3 d-flex flex-col justify-content-end align-items-end">
										<Filter />
									</Col>
									<Col className="d-flex justify-content-end align-items-end">
										<PaginationOptions />
										<button
											className="btn btn-primary d-flex ms-10"
											onClick={() =>
												this.setState({
													selectedRecord: null,
													isShowDialog: true,
												})
											}
										>
											<span className="text-white">
												<FaPlus />
											</span>
											<span className="ms-3 mt-1">Create New</span>
										</button>
									</Col>
								</Row>
								<Table>
									<TableHeader />
									<TableBody />
								</Table>
								<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
									<Pagination />
								</Col>
							</DatatableWrapper>
						</>
					)}
					<ApplicationDialog
						show={this.state.isShowDialog}
						selectedRecord={this.state.selectedRecord}
						handleSave={this.handleSave}
						handleClose={() => {
							//TODO - clear out all variables that is used in the form
							this.setState({ isShowDialog: false });
						}}
						clients={this.state.clients}
						key={Math.random()}
					></ApplicationDialog>
					<ToastContainer />
				</>
			</AppLayout>
		);
	}
}
