/* eslint-disable array-callback-return */
import { Component } from "react";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import InvService from "../../Services/Inv/Inv.service";
import { DatatableWrapper, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaEdit } from "react-icons/fa";
import { FaRegFloppyDisk, FaXmark, FaTrash, FaFloppyDisk } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import { Button, Col, Container, FormLabel, Row, Table } from "react-bootstrap";
import moment from "moment";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "../../Styles/invshopsale.custom.css";
import ConfirmDialog from "../Shared/ConfirmDialog";
import Select from "react-select";
import AuthService from "../../Services/Auth/Auth.service";
import deepCopy from "../../Utils/DeepCopy";

type Props = {
	isShop: boolean;
};

type State = {
	redirect: string;
	saleData: any;
	saleDataCopy: any;
	isLoading: boolean;
	items: Array<any>;
	showConfirm: boolean;
	showConfirmOnSave: boolean;
	deleteItem: any;
	tableKey: any;
	paymentKey: any;
	paymentConfirm: boolean;
	paymentDeleteId: string;
	shops: Array<any>;
	selectedShop: any;
	currentUser: any;
	translatableHeaders: any;
	salesHeader: any;
	isEditMode: any;
	ready: any;
	isSubmitClicked: any;
};

type saleItemType = {
	No: number;
	SaleItemId: string;
	ItemId: string;
	ItemName: string;
	SKU: string;
	HSNCode: string;
	BatchNumber: string;
	VATP: number;
	TAX2P: number;
	Rate: number;
	Quantity: any;
	AvailableQuantity: number;
	VAT: number; // state tax
	TAX2: number; // central tax
	Discount: any;
	Amount: number;
	IsEdit: boolean;
	Action: any;
	ExpiryDate: any;
	Warranty: any;
	PurchaseId: any;
};

type paymentType = {
	Id: string;
	PaymentAmount: number;
	PaymentType: string;
	PaymentDate: Date;
	Action: any;
	IsNew: boolean;
	RowIndex: number;
};

const warranty = [
	{ label: 1, value: "1" },
	{ label: 2, value: "2" },
	{ label: 3, value: "3" },
	{ label: 4, value: "4" },
	{ label: 5, value: "5" },
	{ label: 6, value: "6" },
	{ label: 7, value: "7" },
	{ label: 8, value: "8" },
	{ label: 9, value: "9" },
	{ label: 10, value: "10" },
];

export default class InvShopSale extends Component<Props, State> {
	salesId: any = null;
	shopId: any = null;
	editingRows: any = [];
	saleTableHeaders: TableColumnType<saleItemType>[] = [
		{
			prop: "No",
			title: "No",
		},
		{
			prop: "ItemName",
			title: "Item",
			cell: (row) => {
				return (
					<div>
						{row.IsEdit && (
							<>
								<div>Item</div>
								<div>
									<AsyncTypeahead
										id={Math.random().toString()}
										minLength={3}
										isLoading={this.state.isLoading}
										labelKey="Name"
										defaultSelected={[
											{
												Id: row.ItemId,
												Name: row.ItemName,
											},
										]}
										onSearch={(query: any) => {
											this.setState({ isLoading: true });
											InvService.searchSaleItem({ term: query, shopId: this.shopId }).then((result) => {
												this.setState({
													items: result.data,
													isLoading: false,
												});
											});
										}}
										options={this.state.items}
										onChange={(e: any) => {
											if (e.length > 0) {
												// check if item is already added
												const itemName = (e[0] as any).Name;
												const items = this.state.saleData.Items.filter((x: any) => x.ItemName === itemName);

												if (items.length > 0 && row.ItemName !== itemName) {
													toast.error("This item was already added. Please edit and update quantity or select another item!");
												} else {
													row.ItemId = (e[0] as any).Id;
													row.ItemName = (e[0] as any).Name;
													row.SKU = (e[0] as any).SKU;
													row.Rate = (e[0] as any).Rate;
													row.VATP = (e[0] as any).VAT;
													row.TAX2P = (e[0] as any).TAX2;
													row.ExpiryDate = (e[0] as any).ExpiryDate;
													row.HSNCode = (e[0] as any).HSNCode;
													row.BatchNumber = (e[0] as any).BatchNumber;
													row.VAT = 0;
													row.TAX2 = 0;
													row.AvailableQuantity = (e[0] as any).Quantity;
													row.PurchaseId = (e[0] as any).PurchaseId;
													this.setState({
														saleData: this.state.saleData,
													});
												}
											}
										}}
										style={{ minWidth: "150px" }}
									/>
								</div>
								<div className="small">{row.ExpiryDate ? moment(row.ExpiryDate).format("DD/MM/YYYY") : ""}</div>
							</>
						)}
						{!row.IsEdit && <span>{row.ItemName}</span>}
					</div>
				);
			},
		},
		//Tax%
		{
			prop: "Rate",
			title: "Rate",
			cell: (row) => (
				<>
					{row.IsEdit && <div>Rate</div>}
					<div>
						{row.IsEdit && (
							<input
								type="number"
								disabled={row.ItemId !== "" ? false : true}
								value={row.Rate}
								className="form-control"
								onChange={(e) => {
									row.Rate = Number(e.target.value);
									row.VAT = Number(((row.Rate * row.Quantity - row.Discount) * (row.VATP / 100)).toFixed(3));
									row.TAX2 = Number(((row.Rate * row.Quantity - row.Discount) * (row.TAX2P / 100)).toFixed(3));
									row.Amount = Number((row.VAT + row.TAX2 + row.Rate * row.Quantity - row.Discount).toFixed(3));
									this.setState({
										saleData: this.state.saleData,
									});
								}}
							/>
						)}
						{!row.IsEdit && row.Rate !== undefined && <span>{row.Rate}</span>}
					</div>
				</>
			),
		},
		{
			prop: "Quantity",
			title: "Qty",
			cell: (row) => (
				<>
					{row.IsEdit && <div>Qty</div>}
					<div>
						{row.IsEdit && (
							<div>
								<input
									type="number"
									disabled={row.ItemId !== "" ? false : true}
									value={row.Quantity}
									className="form-control"
									onChange={(e) => {
										if (Number(e.target.value) > row.AvailableQuantity) {
											toast.error("Entered quantity is greater than Available quantity " + row.AvailableQuantity);
										} else {
											row.Quantity = e.target.value;
											row.VAT = Number(((row.Rate * row.Quantity - row.Discount) * (row.VATP / 100)).toFixed(3));
											row.TAX2 = Number(((row.Rate * row.Quantity - row.Discount) * (row.TAX2P / 100)).toFixed(3));
											row.Amount = Number((row.VAT + row.TAX2 + row.Rate * row.Quantity - row.Discount).toFixed(3));
											this.setState({
												saleData: this.state.saleData,
											});
										}
									}}
								/>
							</div>
						)}
						{!row.IsEdit && (
							<>
								{row.Rate === undefined && <div className="fw-medium">{row.Quantity}</div>}
								{row.Rate !== undefined && <span>{row.Quantity}</span>}
							</>
						)}
					</div>
				</>
			),
		},
		{
			prop: "Discount",
			title: "Discount",
			cell: (row) => (
				<>
					{row.IsEdit && <div>Discount</div>}
					<div>
						{row.IsEdit && (
							<input
								type="number"
								disabled={row.ItemId !== "" ? false : true}
								value={row.Discount}
								className="form-control"
								onChange={(e) => {
									row.Discount = e.target.value;
									row.VAT = Number(((row.Rate * row.Quantity - row.Discount) * (row.VATP / 100)).toFixed(3));
									row.TAX2 = Number(((row.Rate * row.Quantity - row.Discount) * (row.TAX2P / 100)).toFixed(3));
									row.Amount = Number((row.VAT + row.TAX2 + row.Rate * row.Quantity - row.Discount).toFixed(3));
									this.setState({
										saleData: this.state.saleData,
									});
								}}
							/>
						)}
						{!row.IsEdit && (
							<>
								{row.Rate === undefined && <div className="fw-medium">{row.Discount}</div>}
								{row.Rate !== undefined && <span>{row.Discount}</span>}
							</>
						)}
					</div>
				</>
			),
		},

		{
			prop: "Amount",
			title: "Amount",
			cell: (row) => (
				<>
					{row.IsEdit && <div>Amount</div>}
					<div>
						{row.Rate === undefined && <div className="fw-medium text-decoration-underline">{row.Amount}</div>}
						{row.Rate !== undefined && <span>{row.Amount}</span>}
					</div>
				</>
			),
		},
		{
			prop: "Action",
			title: "Action",
			cell: (row) => (
				<div className="d-flex flex-column justify-content-between">
					{row.IsEdit && <div>Action</div>}
					<div className="d-flex">
						{row.Rate !== undefined && (
							<div className="d-print-none ">
								{!row.IsEdit && (
									<>
										<FaEdit
											title="Edit"
											className="cursor-pointer me-5"
											onClick={() => {
												this.editingRows.push({ ...row });
												row.IsEdit = !row.IsEdit;
												this.setState({
													saleData: this.state.saleData,
												});
											}}
										/>
										<FaTrash
											title="Remove"
											className="cursor-pointer text-danger"
											onClick={() => {
												this.setState({
													deleteItem: row,
													showConfirm: true,
												});
											}}
										/>
									</>
								)}
								{row.IsEdit && (
									<>
										<FaRegFloppyDisk
											tabIndex={0}
											title="Save"
											className="cursor-pointer text-success me-5"
											onClick={() => {
												this.saveValue(row);
											}}
										/>
										{row.SaleItemId !== null && (
											<FaXmark
												tabIndex={0}
												title="Discard"
												className="cursor-pointer text-warning me-5"
												onClick={() => {
													row.IsEdit = false;
													this.setState({
														saleData: deepCopy(this.state.saleDataCopy),
													});
													// this.loadSaleData();
												}}
											/>
										)}
									</>
								)}
							</div>
						)}
					</div>
				</div>
			),
		},
	];

	paymentTableHeaders: TableColumnType<paymentType>[] = [
		{
			prop: "PaymentType",
			title: "Payment Type",
			cell: (row: any) => {
				return (
					<>
						{!row.Id ? (
							<Select
								onChange={(e: any) => {
									const d = this.state.saleData;
									row.PaymentType = e.value;
									this.setState({
										saleData: d,
									});
								}}
								options={[
									{ label: "Card", value: "Card" },
									{ label: "Cash", value: "Cash" },
								]}
							/>
						) : (
							<>
								<div>{row.PaymentType}</div>
							</>
						)}
					</>
				);
			},
		},
		{
			prop: "PaymentAmount",
			title: "Amount",
			cell: (row: paymentType) => (
				<div>
					{row.Id === null && (
						<input
							type="number"
							value={row.PaymentAmount}
							onChange={(e) => {
								row.PaymentAmount = Number(e.target.value);
								this.setState({
									saleData: this.state.saleData,
								});
							}}
						/>
					)}
					{row.Id !== null && <span>{row.PaymentAmount.toFixed()}</span>}
				</div>
			),
		},
		{
			prop: "PaymentDate",
			title: "Payment Date",
			cell: (row) => <div>{moment(row.PaymentDate).format("DD/MM/YYYY HH:mm")}</div>,
		},
		{
			prop: "Action",
			cell: (row: paymentType) => (
				<div className="d-print-none m-1 ">
					{row.Id === null && (
						<Button
							title="Save Payment"
							onClick={async () => {
								if (row.PaymentAmount > this.state.saleData?.Amount - this.state.saleData?.PaidAmount) {
									return toast.error("Please enter amount less than or equal to " + (this.state.saleData?.Amount - this.state.saleData?.PaidAmount));
								}
								if (row.PaymentAmount <= 0) {
									toast.error("Invalid Amount!");
								} else if (!row.PaymentType || row.PaymentType === "") {
									toast.error("Select Payment Method");
								} else {
									const resp = await InvService.addSalePayment({
										Id: row.Id,
										SaleId: this.state.saleData.SaleId,
										PaymentType: row.PaymentType,
										PaymentAmount: row.PaymentAmount,
									});
									if (resp.data.success) {
										toast.success("Payment Added");
										await this.loadSaleData();
									}
								}
							}}
						>
							<FaFloppyDisk />
						</Button>
					)}
					<Button
						title="Delete Payment"
						className="bg-red-500 border-0 ms-5"
						onClick={() => {
							if (row.Id === null) {
								const sd = this.state.saleData;
								const payments = this.state.saleData.Payments.filter((x: any) => x.RowIndex !== row.RowIndex);

								sd.Payments = payments;
								this.setState({
									saleData: sd,
									paymentKey: Math.random(),
								});
							} else {
								// confirm before deleting
								this.setState({
									paymentDeleteId: row.Id,
									paymentConfirm: true,
								});
							}
						}}
					>
						<FaTrash />
					</Button>
				</div>
			),
		},
	];

	constructor(props: Props) {
		super(props);
		this.state = {
			redirect: "",
			saleData: null,
			saleDataCopy: null,
			isLoading: false,
			items: [],
			showConfirm: false,
			showConfirmOnSave: false,
			deleteItem: null,
			tableKey: Math.random(),
			paymentKey: Math.random(),
			paymentConfirm: false,
			paymentDeleteId: "",
			shops: [],
			selectedShop: null,
			currentUser: "",
			translatableHeaders: { Details: "Details", Signature: "Signature", Instructions: "Instructions", InvoiceDate: "Invoice Date", Invoice: "Invoice" },
			salesHeader: [],
			isEditMode: false,
			ready: false,
			isSubmitClicked: false,
		};

		this.deleteSaleItem = this.deleteSaleItem.bind(this);
		this.onShopChange = this.onShopChange.bind(this);
	}

	async componentDidMount() {
		const { translatableHeaders } = this.state;
		this.saleTableHeaders.map(async (key: any, index: any) => {
			this.saleTableHeaders[index].title = await this.getHeader(key.title);
		});
		Object.keys(translatableHeaders).map(async (x: any) => {
			const res = await InvService.getTranslatedHeaders({ key: translatableHeaders[x] });
			translatableHeaders[x] = res.data;
			this.setState({
				translatableHeaders,
			});
		});

		if (window.location.href.indexOf("/invshopsales/") > -1) {
			const temp = window.location.href.split("/");
			const tempId = temp.pop() ?? null;
			if (tempId !== null) {
				this.salesId = decodeURIComponent(tempId);
			}
			await this.loadSaleData();
		}
		if (this.props.isShop === false) {
			const response = await InvService.getShops();
			const shops = new Array<any>();
			if (window.location.href.indexOf("/invadminsales?") > -1) {
				const url = new URL(window.location.href);
				const temp = url.searchParams.get("SalesId");
				this.shopId = temp?.split("?ShopId=")[1];
				this.salesId = temp?.split("?ShopId=")[0];
				this.loadSaleData();
			}
			response.data.map((s: any) =>
				shops.push({
					label: s.Name,
					value: s.Id,
				})
			);
			this.setState({
				shops,
			});
		} else {
			this.loadSaleData();
		}
		this.setState(
			{
				currentUser: AuthService.getCurrentUser(),
			},
			() => this.setHeaders()
		);
	}

	setHeaders() {
		if (this.state.currentUser.user.VatTax) {
			if (this.state.currentUser.headers.TAX1)
				this.saleTableHeaders.splice(6, 0, {
					prop: "VAT",
					title: this.state.currentUser.headers.TAX1,
					cell: (row) => (
						<>
							{row.IsEdit && <div>{this.state.currentUser.headers.TAX1}</div>}
							<div>
								{row.Rate === undefined && <div className="fw-medium">{row.VAT}</div>}
								{row.Rate !== undefined && <span>{row.VAT}</span>}
							</div>
						</>
					),
				});
			this.saleTableHeaders.splice(2, 0, {
				prop: "VATP",
				title: "VAT%",
				cell: (row) => (
					<>
						{row.IsEdit && <div>VAT%</div>}
						<div>{isNaN(row.VATP) === false && <span>{row.VATP}</span>}</div>
					</>
				),
			});
		} else {
			if (this.state.currentUser.headers.TAX1)
				this.saleTableHeaders.splice(6, 0, {
					prop: "TAX2",
					title: this.state.currentUser.headers.TAX1,
					cell: (row) => (
						<>
							{row.IsEdit && <div>{this.state.currentUser.headers.TAX1}</div>}
							<div>
								{row.Rate === undefined && <div className="fw-medium">{row.TAX2}</div>}
								{row.Rate !== undefined && <span>{row.TAX2}</span>}
							</div>
						</>
					),
				});
			if (this.state.currentUser.headers.TAX2)
				this.saleTableHeaders.splice(6, 0, {
					prop: "VAT",
					title: this.state.currentUser.headers.TAX2,
					cell: (row) => (
						<>
							{row.IsEdit && <div>{this.state.currentUser.headers.TAX2}</div>}
							<div>
								{row.Rate === undefined && <div className="fw-medium">{row.VAT}</div>}
								{row.Rate !== undefined && <span>{row.VAT}</span>}
							</div>
						</>
					),
				});

			this.saleTableHeaders.splice(2, 0, {
				prop: "VATP",
				title: "TAX%",
				cell: (row) => (
					<>
						{row.IsEdit && <div>TAX%</div>}
						<div>{isNaN(row.VATP + row.TAX2P) === false && <span>{row.VATP + row.TAX2P}</span>}</div>
					</>
				),
			});
		}
		if (this.state.currentUser.headers.BatchNumber) {
			this.saleTableHeaders.splice(2, 0, {
				prop: "BatchNumber",
				title: this.state.currentUser.headers.BatchNumber,
				cell: (row) => {
					return (
						<>
							{row.IsEdit && row.SKU && <div>BatchNumber</div>}
							<div>{row.BatchNumber}</div>
						</>
					);
				},
			});
		}
		if (this.state.currentUser.headers.HSNCode) {
			this.saleTableHeaders.splice(2, 0, {
				prop: "HSNCode",
				title: this.state.currentUser.headers.HSNCode,
				cell: (row) => {
					return (
						<>
							{row.IsEdit && row.SKU && <div>HSNCode</div>}
							<div>{row.HSNCode}</div>
						</>
					);
				},
			});
		}

		if (this.state.currentUser.headers.SKU) {
			this.saleTableHeaders.splice(2, 0, {
				prop: "SKU",
				title: this.state.currentUser.headers.SKU,
				cell: (row) => {
					return (
						<>
							<td className="tbody-td">
								{row.IsEdit && row.SKU && <div>SKU</div>}
								<div>{row.SKU}</div>
							</td>
						</>
					);
				},
			});
		}

		if (this.state.currentUser.headers.Warranty) {
			this.saleTableHeaders.splice(2, 0, {
				prop: "Warranty",
				title: this.state.currentUser.headers.Warranty,
				cell: (row) => {
					return (
						<>
							<td className="tbody-td">
								{row.IsEdit && <div>Warranty</div>}
								{row.IsEdit ? (
									<Select
										id="id"
										name="value"
										isMulti={false}
										options={warranty}
										onChange={(selectedOption: any) => {
											row.Warranty = selectedOption.value;
											this.setState({
												saleData: this.state.saleData,
											});
										}}
										className="form-control p-0 border-0"
									/>
								) : (
									<div>{row.Warranty}</div>
								)}
							</td>
						</>
					);
				},
			});
		}
		this.setState({
			tableKey: Math.random(),
		});
	}

	async loadSaleData() {
		if (this.props.isShop === false && this.shopId === null) {
			return;
		}
		const response = await InvService.getSaleData({
			SaleId: this.salesId,
			ShopId: this.shopId,
		});
		if (response.data.Shop.InvoiceHeader) {
			this.setState({
				salesHeader: response.data.Shop.InvoiceHeader?.split("\n"),
			});
		}
		const saleData = response.data;
		if (window.location.href.indexOf("/invshopsales/") > -1 && window.location.href.indexOf("view") <= -1) {
			this.setState({
				isEditMode: true,
			});
		} else if (window.location.href.indexOf("/invadminsales?") > -1 && window.location.href.indexOf("view") <= -1) {
			this.setState({
				isEditMode: true,
			});
		}
		if (saleData.HasPrinted === false) {
			// not finalized invoice - push an empty item
			saleData.Items.push({
				No: saleData.Items.length + 1,
				SaleItemId: null,
				ItemId: "",
				ItemName: "",
				SKU: "",
				VATP: 0,
				TAX2P: 0,
				Rate: "",
				Quantity: "",
				Discount: "",
				VAT: 0,
				TAX2: 0,
				Amount: 0,
				IsEdit: true,
			});
		}
		if (saleData.HasPrinted === true) {
			this.saleTableHeaders.pop();
			this.setState({
				tableKey: Math.random(),
			});
		}
		saleData.Items.push({
			Quantity: saleData.TotalItems,
			Amount: saleData.Amount,
			Discount: saleData.Discount,
			VAT: saleData.Tax1,
			TAX2: saleData.Tax2,
		});
		this.setState({
			saleData,
			saleDataCopy: deepCopy(saleData),
			paymentKey: Math.random(),
			ready: true,
		});
		this.salesId = saleData.SaleId;
	}

	async saveValue(data: any) {
		if (data.ItemId === "") {
			toast.error("Please select item");
		} else if (data.Quantity === "" || data.Quantity.toString() === "0") {
			toast.error("Please enter quantity");
		} else {
			const response = await InvService.saveSaleData(this.state.saleData);
			if (response.data.success) {
				this.salesId = response.data.saleId;
				await this.loadSaleData();
			} else {
				toast.error("Please enter positive values for quantity and rate");
			}
		}
	}

	async deleteSaleItem(data: any) {
		if (data) {
			//delete item
			await InvService.deleteSaleItem({
				saleId: this.salesId,
				saleItemId: this.state.deleteItem.SaleItemId,
			});

			toast.success("Item removed successfully!");

			this.loadSaleData();

			this.setState({
				showConfirm: false,
				deleteItem: null,
			});
		} else {
			// cancel dialog
			this.setState({
				showConfirm: false,
				deleteItem: null,
			});
		}
	}

	async onShopChange(e: any) {
		this.shopId = e.value;
		this.setState(
			{
				selectedShop: e,
			},
			() => this.loadSaleData()
		);
	}

	printInvoice = async (saleId: any) => {
		const res = await InvService.generateInvoice({ ShopId: this.shopId, SaleId: saleId });
		const uint8Array = new Uint8Array(res.data.data);
		const blob = new Blob([uint8Array], { type: "application/pdf" });
		const blobUrl = URL.createObjectURL(blob);
		window.open(blobUrl, "_blank");
	};

	getHeader = async (key: any) => {
		const res = await InvService.getTranslatedHeaders({ key });
		return res.data;
	};
	render() {
		const { translatableHeaders, salesHeader } = this.state;
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<ConfirmDialog message="Do you want to remove this item?" show={this.state.showConfirm} key={Math.random()} title="Delete item" confirmModal={this.deleteSaleItem} />
				<div className="d-print-none mt-60">
					{this.props.isShop === false && window.location.href.indexOf("/invadminsales?") <= -1 && (
						<div className="col-md-12 col-lg-6">
							<Select placeholder="Select Shop" name="shops" value={this.state.selectedShop} onChange={this.onShopChange} options={this.state.shops}></Select>
						</div>
					)}
				</div>
				<div className="d-print-block mt-30"></div>
				{(this.props.isShop || (this.props.isShop === false && this.shopId !== null)) && this.state.ready && (
					<Container fluid={true} className="w-100 salesContainer">
						{salesHeader && salesHeader.length ? (
							salesHeader.map((x: any, index: any) => {
								return (
									<>
										<Row>
											<Col className="d-flex justify-content-center align-content-center">
												<span className={`${index === 0 && "fs-5 fw-bold"}`}>{x}</span>
											</Col>
										</Row>
									</>
								);
							})
						) : (
							<>
								<Row>
									<Col className="d-flex justify-content-center align-content-center">
										<span className="fs-5 fw-bold">{this.state.saleData?.Shop?.Name}</span>
									</Col>
								</Row>
								<Row>
									<Col className="d-flex justify-content-center align-content-center">
										{this.state.saleData?.Shop?.Address1}, {this.state.saleData?.Shop?.Address2}, {this.state.saleData?.Shop?.City},{this.state.saleData?.Shop?.State}, {this.state.saleData?.Shop?.Country}, {this.state.saleData?.Shop?.ZipCode}
									</Col>
								</Row>
								<Row>
									<Col className="d-flex justify-content-center align-content-center">
										{this.state.saleData?.Shop?.Phone}, {this.state.saleData?.Shop?.Email}
									</Col>
								</Row>
							</>
						)}
						<hr />
						<Row className="mt-5 mb-10">
							<Col xs={12} md={6}>
								<div>
									<span className="fw-bold">{translatableHeaders.Invoice} :</span>
									<span className="fw-medium ms-3">{this.state.saleData?.Invoice}</span>
								</div>
								<div className="mt-3">
									<span className="fw-bold align-top ">Bill To:</span>
									{this.state.saleData?.HasPrinted === false && (
										<textarea
											rows={1}
											cols={70}
											className="ms-3 alert-dark commentBox d-print-none"
											value={this.state.saleData?.CustomerName}
											onChange={(e) => {
												const salesData = this.state.saleData;
												salesData.CustomerName = e.target.value;
												this.setState({
													saleData: this.state.saleData,
												});
											}}
										/>
									)}
									{this.state.saleData?.HasPrinted && <span className="d-print-none">{this.state.saleData?.CustomerName}</span>}
									<span className="d-none d-print-block ">{this.state.saleData?.CustomerName}</span>
								</div>
							</Col>
							<Col className="d-flex justify-content-end align-content-end">
								<span>
									<b>{translatableHeaders.InvoiceDate}:</b>
									<span className="fw-medium ms-3">{moment(this.state.saleData?.InvoiceDate).format("DD/MM/YYYY")}</span>
								</span>
							</Col>
						</Row>
						<Row className="">
							{this.state.saleData !== null && (
								<DatatableWrapper
									sortProps={{
										initialState: {
											order: "asc",
											prop: "No",
										},
									}}
									key={this.state.tableKey}
									body={this.state.saleData.Items}
									headers={this.saleTableHeaders}
									paginationOptionsProps={{
										initialState: {
											rowsPerPage: 1000,
											options: [5000],
										},
									}}
								>
									<Table className="salesTable">
										<TableHeader />
										<TableBody />
									</Table>
								</DatatableWrapper>
							)}
						</Row>
						<Row className="bg-white">
							<Col xs={12} md={6}>
								<Row className="mt-5">
									<FormLabel className="fw-medium">{translatableHeaders.Details}</FormLabel>
								</Row>
								<Row className=" d-print-none">
									<Col>
										{this.state.saleData?.HasPrinted === false && (
											<textarea
												style={{ height: "150px" }}
												className="commentBox"
												value={this.state.saleData?.Details}
												onChange={(e) => {
													const salesData = this.state.saleData;
													salesData.Details = e.target.value;
													this.setState({
														saleData: this.state.saleData,
													});
												}}
											/>
										)}
										{this.state.saleData?.HasPrinted === true && <div className="">{this.state.saleData?.Details}</div>}
									</Col>
								</Row>
								<div className="d-none d-print-block">{this.state.saleData?.Details}</div>
								<Row className="bg-white">
									<Col className="m-5 d-flex fw-bold">{this.state.saleData?.FinalInWords}</Col>
								</Row>
								{this.state.saleData?.FinalInWordsOther && (
									<Row className="bg-white">
										<Col className="m-5 d-flex fw-bold">{this.state.saleData?.FinalInWordsOther}</Col>
									</Row>
								)}
							</Col>
							<Col xs={12} md={6} className="totalBox">
								<Row className="border-bottom">
									<Col className="fw-bold">Items</Col>
									<Col className="fw-medium justify-content-end d-flex">{this.state.saleData?.TotalItems}</Col>
								</Row>
								<Row className="border-bottom">
									<Col className="fw-bold">Total Discount</Col>
									<Col className="fw-medium justify-content-end d-flex">{this.state.saleData?.Discount}</Col>
								</Row>
								{!this.state.currentUser.user.VatTax && (
									<>
										{this.state.currentUser.headers.TAX1 && (
											<Row className="border-bottom">
												<Col className="fw-bold">Total {this.state.currentUser.headers.TAX1}</Col>
												<Col className="fw-medium justify-content-end d-flex">{this.state.saleData?.Tax1}</Col>
											</Row>
										)}
										{this.state.currentUser.headers.TAX2 && (
											<Row className="border-bottom">
												<Col className="fw-bold">Total {this.state.currentUser.headers.TAX2}</Col>
												<Col className="fw-medium justify-content-end d-flex">{this.state.saleData?.Tax2}</Col>
											</Row>
										)}
									</>
								)}
								{this.state.currentUser.user.VatTax && this.state.currentUser.headers.TAX1 && (
									<Row className="border-bottom">
										<Col className="fw-bold">Total {this.state.currentUser.headers.TAX1}</Col>
										<Col className="fw-medium justify-content-end d-flex">{this.state.saleData?.Tax1}</Col>
									</Row>
								)}
								<Row className="border-bottom">
									<Col className="fw-bold">Amount</Col>
									<Col className="fw-medium justify-content-end d-flex">{this.state.saleData?.Amount}</Col>
								</Row>
								<Row className="border-bottom">
									<Col className="fw-bold">Payment Type</Col>
									<Col className="fw-medium justify-content-end d-flex">
										{this.state.saleData?.HasPrinted === true && <span>{this.state.saleData?.PaymentType}</span>}
										{this.state.saleData?.HasPrinted === false && (
											<Select
												onChange={(e: any) => {
													const d = this.state.saleData;
													d.PaymentType = e.value;
													this.setState({
														saleData: d,
													});
												}}
												value={{
													label: this.state.saleData?.PaymentType,
													value: this.state.saleData?.PaymentType,
												}}
												options={[
													{ label: "Card", value: "Card" },
													{ label: "Cash", value: "Cash" },
												]}
											/>
										)}
									</Col>
								</Row>
								<Row className="border-bottom">
									<Col className="fw-bold">Amount Paid</Col>
									<Col className="fw-medium justify-content-end d-flex">
										{this.state.saleData?.HasPrinted === true && <span>{this.state.saleData?.PaidAmount}</span>}
										{this.state.saleData?.HasPrinted === false && (
											<input
												type="number"
												value={this.state.saleData?.PaidAmount}
												className="form-control"
												onChange={(e) => {
													const d = this.state.saleData;
													d.PaidAmount = e.target.value;
													this.setState({
														saleData: d,
													});
												}}
											/>
										)}
									</Col>
								</Row>
								<Row className="border-bottom">
									<Col className="fw-bold">Balance</Col>
									<Col className="fw-medium justify-content-end d-flex">{this.state.saleData && <span>{this.state.saleData?.Amount - this.state.saleData?.PaidAmount}</span>}</Col>
								</Row>
								<Row className="border-bottom d-print-none">
									<Col className="fw-medium d-flex justify-content-end gap-2 m-4 p-0">
										{this.state.saleData?.HasPrinted === false && (
											<>
												<Button
													className="bg-primary "
													onClick={async () => {
														if (this.state.saleData.PaidAmount > this.state.saleData.Amount) {
															toast.error("Please enter amount less than or equal to " + this.state.saleData.Amount);
														}
														if (this.state.saleData.SaleId && this.state.isEditMode) {
															this.setState({
																showConfirmOnSave: true,
																isSubmitClicked: false,
															});
														} else {
															const response = await InvService.saveSaleData({ ...this.state.saleData, IsPrinting: false });
															if (response.data.success) {
																this.salesId = response.data.saleId;
																this.setState({
																	redirect: "/invshopsaleaudits",
																});
															} else {
																toast.error("Please enter positive values for quantity and Rate");
															}
														}
													}}
												>
													Save
												</Button>
												<Button
													disabled={!this.state.saleData.SaleId}
													className="bg-primary "
													onClick={async () => {
														if (this.state.saleData.PaidAmount > this.state.saleData.Amount) {
															toast.error("Please enter amount less than or equal to " + this.state.saleData.Amount);
														} else {
															if (this.state.saleData.SaleId && this.state.isEditMode) {
																this.setState({
																	showConfirmOnSave: true,
																	isSubmitClicked: true,
																});
															} else {
																var saleData = { ...this.state.saleData };
																saleData.Items = saleData.Items.filter((x: any) => x.SaleItemId !== null);
																const response = await InvService.saveSaleData({ ...saleData, IsPrinting: true });
																if (response.data.success) {
																	await this.printInvoice(response.data.saleId);
																	toast.success("Invoice generated successfully!");
																	this.setState({
																		redirect: "/invshopsaleaudits",
																	});
																} else {
																	toast.error("Please enter positive values for quantity and rate");
																}
															}
														}
													}}
												>
													Submit & Print
												</Button>
											</>
										)}
										{this.state.saleData?.HasPrinted === true && (
											<Button className="bg-secondary" onClick={() => this.printInvoice(this.salesId)}>
												Print
											</Button>
										)}
										{this.state.saleData?.HasPrinted === true && this.state.saleData?.PaidAmount < this.state.saleData?.Amount && (
											<Button
												className="bg-primary ms-5"
												onClick={() => {
													const payments = this.state.saleData?.Payments;
													payments.push({
														Id: null,
														PaymentAmount: 0,
														PaymentType: "",
														PaymentDate: new Date(),
														RowIndex: Math.random(),
													});
													this.setState({
														saleData: this.state.saleData,
														paymentKey: Math.random(),
													});
												}}
											>
												Add Payment
											</Button>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
						{this.state.saleData?.Payments.length > 0 && (
							<Row className="bg-white">
								<DatatableWrapper key={this.state.paymentKey} body={this.state.saleData.Payments} headers={this.paymentTableHeaders}>
									<Table className="">
										<TableHeader />
										<TableBody />
									</Table>
								</DatatableWrapper>
							</Row>
						)}
						<hr />
						<Row className="bg-white">
							<Col className="w-25">
								{this.state.saleData?.Shop?.InvoiceInstructions !== null && this.state.saleData?.Shop?.InvoiceInstructions !== "" && this.state.saleData?.Shop?.InvoiceInstructions !== undefined && (
									<>
										<span className="fw-bold">{translatableHeaders.Instructions}</span>
										<div>{this.state.saleData?.Shop?.InvoiceInstructions}</div>
									</>
								)}
							</Col>
							<Col className="w-75">
								<span className="fw-bold">{translatableHeaders.Signature}</span>
							</Col>
						</Row>
						<div className="fixed-bottom d-print-block w-100">
							<hr />
							<span className="d-flex m-auto justify-content-center mb-5">System generated invoice</span>
						</div>
					</Container>
				)}
				<ToastContainer />
				<ConfirmDialog
					message="Do you want to remove this payment?"
					show={this.state.paymentConfirm}
					title="Delete Payment"
					key={Math.random()}
					confirmModal={async (selection: boolean) => {
						if (selection) {
							await InvService.deleteSalePayment({
								salePaymentId: this.state.paymentDeleteId,
							});
							toast.success("Payment record deleted!");
							await this.loadSaleData();
							this.setState({
								paymentConfirm: false,
							});
						} else {
							this.setState({
								paymentConfirm: false,
							});
						}
					}}
				/>
				<ConfirmDialog
					message="This will reset all the current payments on this sale. Are you sure you want to continue"
					show={this.state.showConfirmOnSave}
					title="Please confirm"
					key={Math.random()}
					confirmModal={async (selection: boolean) => {
						if (selection) {
							var saleData = this.state.saleData;
							saleData.Items = saleData.Items.filter((x: any) => x.SaleItemId !== null);
							if (this.state.isSubmitClicked) {
								const response = await InvService.saveSaleData({ ...saleData, IsPrinting: true });
								if (response.data.success) {
									await this.printInvoice(response.data.saleId);
									toast.success("Invoice generated successfully!");
									this.setState({
										redirect: "/invshopsaleaudits",
									});
								} else {
									toast.error("Please enter positive values for quantity and rate");
								}
							} else {
								const response = await InvService.saveSaleData({ ...this.state.saleData, IsPrinting: false });
								if (response.data.success) {
									this.salesId = response.data.saleId;
									this.setState({
										redirect: "/invshopsaleaudits",
									});
								} else {
									toast.error("Please enter positive values for quantity and Rate");
								}
							}
						}
						this.setState({
							showConfirmOnSave: false,
						});
					}}
				/>
			</InvLayout>
		);
	}
}
