import { Component } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { FaUserCircle, FaBell } from "react-icons/fa";
import AuthService from "../../Services/Auth/Auth.service";
import { Navigate } from "react-router-dom";
import "../../Styles/appheader.layout.css";
import { AppConstants } from "../../Utils/AppConstants";

type Props = {};
type State = {
	redirect: string | null;
	currentUser: any;
	ready: boolean;
};

export default class NavHeaderRight extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			currentUser: null,
			ready: false,
		};
	}

	componentDidMount(): void {
		const currentUser = AuthService.getCurrentUser();
		this.setState({ currentUser, ready: true });
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		return (
			<>
				<Nav>
					{this.state.ready && this.state.currentUser.isImpersonate !== undefined && (
						<Nav.Link>
							<div className="bg-danger text-white px-10 py-2">You are impersonating {this.state.currentUser.user.Name} !</div>
						</Nav.Link>
					)}
					<Nav.Link>
						<FaBell className="text-white fs-5 " title="Notifications" />
					</Nav.Link>
					<NavDropdown align="end" title={<FaUserCircle className="text-white  fs-5" title="Profile" />}>
						<NavDropdown.Header className="ms-5 me-5">{"Welcome " + this.state.currentUser?.user?.Name + "!"}</NavDropdown.Header>
						<NavDropdown.Divider />
						<NavDropdown.Item
							className="p-5"
							onClick={() => {
								this.setState({
									redirect: "/profile",
								});
							}}
						>
							My Profile
						</NavDropdown.Item>
						{this.state.ready && this.state.currentUser.isImpersonate === undefined && (
							<NavDropdown.Item
								className="p-5"
								onClick={() => {
									AuthService.logout();
									window.location.reload();
								}}
							>
								Logout
							</NavDropdown.Item>
						)}
						{this.state.ready && this.state.currentUser.isImpersonate !== undefined && (
							<NavDropdown.Item
								className="p-5"
								onClick={() => {
									const adminContext = sessionStorage.getItem(AppConstants.SESSION_KEY_IMPERSONATION);
									sessionStorage.removeItem(AppConstants.SESSION_KEY_IMPERSONATION);
									if (adminContext !== null) {
										sessionStorage.setItem(AppConstants.SESSION_KEY_PAYLOAD, adminContext);
										window.location.href = "/users";
									}
								}}
							>
								Exit Impersonation
							</NavDropdown.Item>
						)}
					</NavDropdown>
				</Nav>
			</>
		);
	}
}
