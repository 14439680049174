import { Component } from "react";
import ModalPopup from "../Shared/ModalPopup";
import InvService from "../../Services/Inv/Inv.service";
import { DatatableWrapper, Pagination, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { Badge, Col, Table } from "react-bootstrap";

type Props = {
	show: boolean;
	handleClose: () => void;
	handleSave: (data: any) => void;
	shopStockId: string;
	itemName: string;
};

type State = {
	audits: Array<any>;
};

type auditType = {
	PreviousQuantity: number;
	NewQuantity: number;
	PreviousRate: number;
	NewRate: number;
	DisplayName: string;
	UpdatedDate: Date;
	InvoiceNumber: string;
	Supplier: string;
	SalesNumber: string;
};

export default class InvShopStockAuditDialog extends Component<Props, State> {
	auditTableHeaders: TableColumnType<auditType>[] = [
		{
			prop: "PreviousQuantity",
			title: "Old Qty",
		},
		{
			prop: "NewQuantity",
			title: "New Qty",
			cell: (row) => (
				<div>
					{row.NewQuantity > row.PreviousQuantity && <Badge bg="success">{row.NewQuantity}</Badge>}
					{row.NewQuantity < row.PreviousQuantity && <Badge bg="danger">{row.NewQuantity}</Badge>}
					{row.NewQuantity === row.PreviousQuantity && <Badge bg="secondary">{row.NewQuantity}</Badge>}
				</div>
			),
		},
		{
			prop: "PreviousRate",
			title: "Old Rate",
		},
		{
			prop: "NewRate",
			title: "New Rate",
			cell: (row) => (
				<div>
					{row.NewRate !== row.PreviousRate && <Badge bg="success">{row.NewRate}</Badge>}
					{row.NewRate === row.PreviousRate && <Badge bg="secondary">{row.NewRate}</Badge>}
				</div>
			),
		},
		{
			prop: "InvoiceNumber",
			title: "Purchase#",
		},
		{
			prop: "SalesNumber",
			title: "Sale#",
		},
		{
			prop: "DisplayName",
			title: "Updated By",
		},
		{
			prop: "UpdatedDate",
			title: "Date Updated",
			isSortable: true,
		},
	];
	constructor(props: Props) {
		super(props);

		this.state = {
			audits: [],
		};
	}

	async componentDidMount() {
		const audits = await InvService.shopStockAudits({
			ShopStockId: this.props.shopStockId,
		});
		this.setState({
			audits: audits.data,
		});
	}

	render() {
		return (
			<ModalPopup
				showSubmit={false}
				hideSave={true}
				show={this.props.show}
				handleClose={this.props.handleClose}
				handleSave={this.props.handleClose}
				title={"Stock history for " + this.props.itemName}
				dialogClassName="modal-90w"
				content={
					<>
						<DatatableWrapper
							body={this.state.audits}
							headers={this.auditTableHeaders}
							paginationOptionsProps={{
								initialState: {
									rowsPerPage: 10,
									options: [5, 10, 15, 20],
								},
							}}
						>
							<Table className="dataTable">
								<TableHeader />
								<TableBody />
							</Table>
							{this.state.audits?.length > 9 && (
								<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
									<Pagination />
								</Col>
							)}
						</DatatableWrapper>
					</>
				}
			/>
		);
	}
}
