import { Component } from "react";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import InvService from "../../Services/Inv/Inv.service";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaEdit } from "react-icons/fa";
import { FaRegFloppyDisk, FaXmark, FaPlus, FaTrash } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, Col, Row, Table } from "react-bootstrap";
import moment from "moment";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "../../Styles/invpurchaseitems.custom.css";
import "../../Styles/invTable.custom.css";
import ConfirmDialog from "../Shared/ConfirmDialog";

type Props = {
	isShop: boolean;
};

type State = {
	redirect: string | null;
	purchaseItems: Array<any>;
	ready: boolean;
	tableKey: number;
	reload: any;
	purchase: any;
	purchaseId: any;
	items: Array<any>;
	isLoading: boolean;
	showDeleteConfirm: boolean;
	deleteId: any;
};

type purchaseItem = {
	No: number;
	Id: string;
	ItemId: string;
	ItemName: string;
	Rate: number;
	Quantity: number;
	BoxOrPieces: string;
	Tax: number;
	TaxPercentage: number;
	Amount: number;
	ManufacturedDate: any;
	ExpiryDate: any;
	Action: any;
	IsEdit: boolean;
	NewRandomId: any;
	DistributionHTML: any;
	Balance: number;
};

export default class InvPurchaseItems extends Component<Props, State> {
	purchaseTableHeaders: TableColumnType<purchaseItem>[] = [
		{
			prop: "No",
			title: "No",
			isSortable: true,
		},
		{
			prop: "ItemName",
			title: "Item",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div style={{ width: "200px" }}>
							<label>Item</label>
							<AsyncTypeahead
								defaultSelected={[
									{
										Id: row.ItemId,
										Name: row.ItemName,
									},
								]}
								id={Math.random().toString()}
								minLength={3}
								isLoading={this.state.isLoading}
								labelKey="Name"
								onSearch={(query: any) => {
									this.setState({ isLoading: true });
									InvService.searchItem({ term: query }).then((result) => {
										console.log(result.data);
										this.setState({
											items: result.data,
											isLoading: false,
										});
									});
								}}
								options={this.state.items}
								onChange={(e: any) => {
									if (e.length === 0) {
										return;
									}
									row.ItemId = (e[0] as any).Id;
									row.ItemName = (e[0] as any).Name;
									this.setState({
										purchaseItems: this.state.purchaseItems,
									});
								}}
							/>
						</div>
					)}
					{!row.IsEdit && <span>{row.ItemName}</span>}
				</div>
			),
		},
		{
			prop: "BoxOrPieces",
			title: "Pcs/Box",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div style={{ width: "100px" }}>
							<label>Pcs/Box</label>
							<select
								value={row.BoxOrPieces}
								className="form-select"
								onChange={(e) => {
									row.BoxOrPieces = e.target.value;
									this.setState({
										purchaseItems: this.state.purchaseItems,
									});
								}}
							>
								<option value="Pieces">Pieces</option>
								<option value="Box">Box</option>
							</select>
						</div>
					)}
					{!row.IsEdit && <span>{row.BoxOrPieces}</span>}
				</div>
			),
		},
		{
			prop: "Rate",
			title: "Rate",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div style={{ width: "100px" }}>
							<label>Rate</label>
							<input
								min={0}
								type="number"
								value={row.Rate}
								className="form-control"
								onChange={(e) => {
									row.Rate = Number(e.target.value);
									this.setState({
										purchaseItems: this.state.purchaseItems,
									});
								}}
							/>
						</div>
					)}
					{!row.IsEdit && <span>{row.Rate}</span>}
				</div>
			),
		},
		{
			prop: "Quantity",
			title: "Quantity",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div>
							<label>Quantity</label>
							<input
								min={0}
								type="number"
								value={row.Quantity}
								className="form-control"
								onChange={(e) => {
									row.Quantity = Number(e.target.value);
									this.setState({
										purchaseItems: this.state.purchaseItems,
									});
								}}
							/>
						</div>
					)}
					{!row.IsEdit && <span>{row.Quantity}</span>}
				</div>
			),
		},
		{
			prop: "ManufacturedDate",
			title: "Manufactured",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div>
							<label>Manufactured on</label>
							<input
								min={0}
								type="date"
								value={moment(row.ManufacturedDate).format("YYYY-MM-DD")}
								className="form-control"
								onChange={(e) => {
									row.ManufacturedDate = new Date(e.target.value);
									this.setState({
										purchaseItems: this.state.purchaseItems,
									});
								}}
							/>
						</div>
					)}
					{!row.IsEdit && row.ManufacturedDate !== null && <span>{moment(row.ManufacturedDate).format("DD/MM/YYYY")}</span>}
				</div>
			),
		},
		{
			prop: "ExpiryDate",
			title: "Expiry",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div>
							<label>Expiry Date</label>
							<input
								min={0}
								type="date"
								value={moment(row.ExpiryDate).format("YYYY-MM-DD")}
								className="form-control"
								onChange={(e) => {
									row.ExpiryDate = new Date(e.target.value);
									this.setState({
										purchaseItems: this.state.purchaseItems,
									});
								}}
							/>
						</div>
					)}
					{!row.IsEdit && row.ExpiryDate !== null && <span>{moment(row.ExpiryDate).format("DD/MM/YYYY")}</span>}
				</div>
			),
		},
		{
			prop: "Tax",
			title: "Tax",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div style={{ width: "100px" }}>
							<label>Tax</label>
							<input
								min={0}
								type="number"
								value={row.Tax}
								className="form-control"
								onChange={(e) => {
									row.Tax = Number(e.target.value);
									this.setState({
										purchaseItems: this.state.purchaseItems,
									});
								}}
							/>
						</div>
					)}
					{!row.IsEdit && <span>{row.Tax}</span>}
				</div>
			),
		},
		{
			prop: "TaxPercentage",
			title: "Tax %",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div style={{ width: "100px" }}>
							<label>Tax %</label>
							<input
								min={0}
								type="number"
								value={row.TaxPercentage}
								className="form-control"
								onChange={(e) => {
									row.TaxPercentage = Number(e.target.value);
									this.setState({
										purchaseItems: this.state.purchaseItems,
									});
								}}
							/>
						</div>
					)}
					{!row.IsEdit && <span>{row.TaxPercentage}</span>}
				</div>
			),
		},
		{
			prop: "Amount",
			title: "Amount",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div>
							<label>Amount</label>
							<input
								min={0}
								type="number"
								value={row.Amount}
								className="form-control"
								onChange={(e) => {
									row.Amount = Number(e.target.value);
									this.setState({
										purchaseItems: this.state.purchaseItems,
									});
								}}
							/>
						</div>
					)}
					{!row.IsEdit && <span>{row.Amount}</span>}
				</div>
			),
		},
		{
			prop: "Action",
			title: "Action",
			cell: (row) => (
				<div className="d-flex">
					{this.props.isShop === false && !row.IsEdit && (
						<>
							<button
								title="Edit"
								className="btn btn-primary d-flex"
								onClick={() => {
									row.IsEdit = !row.IsEdit;
									this.setState({
										purchaseItems: this.state.purchaseItems,
									});
								}}
							>
								<span className="text-white">
									<FaEdit />
								</span>
							</button>
						</>
					)}
					{row.IsEdit && (
						<>
							<button
								title="Save"
								className="btn btn-success d-flex"
								onClick={() => {
									this.saveValue(row);
								}}
							>
								<span className="text-white">
									<FaRegFloppyDisk />
								</span>
							</button>
							<button
								title="Remove"
								className="btn btn-warning d-flex ms-4 "
								onClick={() => {
									row.IsEdit = !row.IsEdit;
									this.setState({
										purchaseItems: this.state.purchaseItems.filter((x) => x.NewRandomId === undefined || x.NewRandomId !== row.NewRandomId),
									});
								}}
							>
								<span className="text-white">
									<FaXmark />
								</span>
							</button>
						</>
					)}
					{(row.DistributionHTML === null || row.DistributionHTML === "" || this.props.isShop) && (
						<button
							title="Delete"
							className="btn btn-danger d-flex ms-5"
							onClick={() => {
								console.log(row);
								this.setState({
									showDeleteConfirm: true,
									deleteId: row.Id,
								});
							}}
						>
							<span className="text-white">
								<FaTrash />
							</span>
						</button>
					)}
				</div>
			),
		},
	];
	constructor(props: Props) {
		super(props);
		const temp = window.location.href.split("/");
		const tempId = temp.pop() ?? "";
		this.state = {
			redirect: null,
			purchaseItems: [],
			ready: false,
			tableKey: Math.random(),
			reload: Math.random(),
			purchaseId: decodeURIComponent(tempId),
			purchase: null,
			items: [],
			isLoading: false,
			showDeleteConfirm: false,
			deleteId: null,
		};
		this.loadValues = this.loadValues.bind(this);
		this.deleteItem = this.deleteItem.bind(this);
	}

	async componentDidMount() {
		if (this.props.isShop) {
			// remove distribution and balance columns
			//this.purchaseTableHeaders.splice(7, 1);
			//this.purchaseTableHeaders.splice(6, 1);
		}
		await this.loadValues();
	}

	async loadValues() {
		const purchase = await InvService.getPurchaseById({ purchaseId: this.state.purchaseId });
		const response = await InvService.getPurchaseItems({ purchaseId: this.state.purchaseId });
		console.log(response);
		this.setState({
			ready: true,
			purchaseItems: response.data,
			purchase: purchase.data,
			showDeleteConfirm: false,
			deleteId: null,
		});
	}

	async saveValue(data: any) {
		console.log(data);
		if (data.ItemId === "" || data.ItemId === null) {
			toast.error("Please select item");
		} else if (data.Rate === "" || data.Rate === null || data.Rate < 0) {
			toast.error("Please enter Rate");
		} else if (data.Quantity === "" || data.Quantity === null || data.Quantity < 0) {
			toast.error("Please enter Quantity");
		} else {
			const res = await InvService.createOrUpdatePurchaseItem({
				Id: data.Id,
				ItemId: data.ItemId,
				PurchaseId: this.state.purchaseId,
				Rate: data.Rate,
				BoxOrPieces: data.BoxOrPieces,
				Tax: data.Tax,
				TaxPercentage: data.TaxPercentage,
				Quantity: data.Quantity,
				Amount: data.Amount,
				ManufacturedDate: data.ManufacturedDate,
				ExpiryDate: data.ExpiryDate,
			});
			if (res.status === 200 && res.data.success) {
				toast.success(res.data.message);
				this.loadValues();
			} else {
				toast.error(res.data.message);
			}
		}
	}

	async deleteItem(confirm: boolean) {
		if (confirm) {
			const resp = await InvService.deletePurchaseItem({
				Id: this.state.deleteId,
			});

			if (resp.data.success) {
				toast.success(resp.data.message);
				await this.loadValues();
			}
		} else {
			this.setState({
				showDeleteConfirm: false,
				deleteId: null,
			});
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<>
					<div className="mt-55 px-10">
						<div className="row">
							<div className="col">
								<Breadcrumb>
									<Breadcrumb.Item
										href="#"
										onClick={() => {
											if (this.props.isShop) {
												this.setState({
													redirect: "/manage/invshoppurchases",
												});
											} else {
												this.setState({
													redirect: "/manage/invstorepurchases",
												});
											}
										}}
									>
										Purchases
									</Breadcrumb.Item>
									<Breadcrumb.Item active>{this.state.purchase?.InvoiceNumber}</Breadcrumb.Item>
								</Breadcrumb>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<b>Invoice Number:</b> {this.state.purchase?.InvoiceNumber}
							</div>
							<div className="col">
								<b>Supplier:</b> {this.state.purchase?.SupplierName}
							</div>
							<div className="col">
								<b>Supplier VATIN:</b> {this.state.purchase?.SupplierVATIN}
							</div>
							<div className="col">
								<b>Invoice Date:</b> {moment(this.state.purchase?.InvoiceDate).format("DD-MM-YYYY")}
							</div>
							<div className="col">
								<b>Total Items:</b>
								<h3>{this.state.purchase?.TotalItems.toLocaleString()}</h3>
							</div>
							<div className="col">
								<b>Total Amount:</b>
								<h3>{this.state.purchase?.TotalAmount.toLocaleString()}</h3>
							</div>
						</div>
					</div>
					{this.state.ready && (
						<>
							<div className="d-flex"></div>
							<DatatableWrapper
								body={this.state.purchaseItems}
								headers={this.purchaseTableHeaders}
								paginationOptionsProps={{
									initialState: {
										rowsPerPage: 10,
										options: [5, 10, 15, 20],
									},
								}}
								key={this.state.tableKey}
							>
								<Row className="mb-10">
									<Col className="col-12 mt-5 col-md mt-md-0 d-flex flex-col justify-content-end align-items-end mb-3 mb-md-0">
										<Filter />
									</Col>
									<Col className="col-12 col-md d-flex justify-content-end align-items-end">
										<button
											className="btn btn-primary d-flex ms-10"
											onClick={() => {
												const purchaseItems = this.state.purchaseItems;
												purchaseItems.unshift({
													Id: undefined,
													ItemId: "",
													ItemName: "",
													Rate: "",
													Quantity: "",
													ManufacturedDate: null,
													ExpiryDate: null,
													Amount: "",
													IsEdit: true,
													NewRandomId: Math.random(),
												});
												this.setState({
													purchaseItems,
													tableKey: Math.random(),
												});
											}}
										>
											<span className="text-white">
												<FaPlus />
											</span>
											<span className="ms-3 mt-1">Add Item</span>
										</button>
									</Col>
								</Row>
								<Table className="dataTable">
									<TableHeader />
									<TableBody />
								</Table>
								<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
									<PaginationOptions />
									<div className="ms-5" />
									<Pagination />
								</Col>
							</DatatableWrapper>
						</>
					)}
					<ConfirmDialog confirmModal={this.deleteItem} message="Do you want to delete this item?" show={this.state.showDeleteConfirm} title="Delete Purchase Item" id={this.state.deleteId} />
					<ToastContainer />
				</>
			</InvLayout>
		);
	}
}
