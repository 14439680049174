import { Component } from "react";
import { FaChartLine, FaRegBuilding, FaUsers } from "react-icons/fa6";
import { BiUser } from "react-icons/bi";
import { Navigate } from "react-router-dom";
import TRadLayout from "../../Layouts/TRad/TRadLayout";
import AuthService from "../../Services/Auth/Auth.service";
import "../../Styles/tradhome.custom.css";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import TRadService from "../../Services/TRad/TRad.service";
import { Badge } from "react-bootstrap";

type Props = {};
type State = {
	redirect: string | null;
	ready: boolean;
	currentUser: any | null;
	radiologists: any;
	scanningCenters: any;
	newScans: any;
	underReview: any;
	selectedFilter: any;
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);
export const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: "top" as const,
		},
		title: {
			display: false,
			text: "Chart.js Line Chart",
		},
	},
};
const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
	labels,
	datasets: [
		{
			label: "Earnings This Year",
			data: [10, 20, 45, 70, 90, 95, 110, 123, 100, 110, 150, 130],
			borderColor: "rgb(255, 99, 132)",
			backgroundColor: "rgba(255, 99, 132, 0.5)",
		},
	],
};

export const revenueData = {
	labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
	datasets: [
		{
			label: "# of Votes",
			data: [12, 19, 3, 5, 2, 3],
			backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)", "rgba(75, 192, 192, 0.2)", "rgba(153, 102, 255, 0.2)", "rgba(255, 159, 64, 0.2)"],
			borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
			borderWidth: 1,
		},
	],
};

export default class TRadHome extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			currentUser: null,
			radiologists: 0,
			newScans: 0,
			underReview: 0,
			scanningCenters: 0,
			selectedFilter: "Today",
		};
	}

	componentDidMount(): void {
		const currentUser = AuthService.getCurrentUser();
		this.setState({ currentUser, ready: true });
		this.getDashboardData();
	}

	getDashboardData = async () => {
		const res = await TRadService.getDashboardData({ filter: this.state.selectedFilter });

		if (res.data.response[0][0]) {
			this.setState({
				radiologists: res.data.response[0][0].RadiologistCount,
				scanningCenters: res.data.response[0][0].ScanningCentreCount,
				newScans: res.data.response[0][0].NewScansCount,
				underReview: res.data.response[0][0].UnderReviewScansCount,
			});
		}
	};
	filters = ["Today", "This Month", "This Year"];

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<TRadLayout showMargin={true}>
				<>
					<div className="mt-50 px-10">
						<div className="d-sm-flex align-items-center justify-content-between mb-12">
							<h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
							<div className="d-flex gap-5 ">
								{this.filters.map((x) => (
									<Badge
										key={Math.random()}
										className={x === this.state.selectedFilter ? "bg-secondary cursor-pointer" : "bg-primary cursor-pointer"}
										onClick={() =>
											this.setState(
												{
													selectedFilter: x,
												},
												() => this.getDashboardData()
											)
										}
									>
										{x}
									</Badge>
								))}
							</div>
						</div>
						<div className="row">
							<div className="col-xl-3 col-sm-6 col-12">
								<div className="card p-20 shadow border-left-success">
									<div className="card-content">
										<div className="card-body">
											<div className="media d-flex">
												<div className="align-self-center">
													<FaUsers className="text-success fs-1 float-left" />
												</div>
												<div className="ms-auto text-end">
													<h3>{this.state.radiologists}</h3>
													<span>Radiologists</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 col-12">
								<div className="card p-20 shadow border-left-warning">
									<div className="card-content">
										<div className="card-body">
											<div className="media d-flex">
												<div className="align-self-center">
													<FaRegBuilding className="text-warning fs-1 float-left" />
												</div>
												<div className="ms-auto text-end">
													<h3>{this.state.scanningCenters}</h3>
													<span>Scaning Centers</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 col-12">
								<div className="card p-20 shadow border-left-primary">
									<div className="card-content">
										<div className="card-body">
											<div className="media d-flex">
												<div className="align-self-center">
													<FaChartLine className="text-primary fs-1 float-left" />
												</div>
												<div className="ms-auto text-end">
													<h3>{this.state.newScans}</h3>
													<span>New Scans</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 col-12">
								<div className="card p-20 shadow border-left-danger">
									<div className="card-content">
										<div className="card-body">
											<div className="media d-flex">
												<div className="align-self-center">
													<BiUser className="text-danger fs-1 float-left" />
												</div>
												<div className="ms-auto text-end">
													<h3>{this.state.underReview}</h3>
													<span>Under Review</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-8 col-lg-7">
								<div className="card shadow mb-24 rounded-top-4">
									<div className="card-card-header p-16 d-flex flex-row align-items-center justify-content-between">
										<h6 className="m-0 fw-bold text-primary">Earnings Overview</h6>
									</div>
									<div className="card-body">
										<Line options={options} data={data} height={400} />
									</div>
								</div>
							</div>
							<div className="col-xl-4 col-lg-5">
								<div className="card shadow mb-24 rounded-top-4">
									<div className="card-card-header p-16 d-flex flex-row align-items-center justify-content-between">
										<h6 className="m-0 fw-bold text-primary">Revenue</h6>
									</div>
									<div className="card-body">
										<Doughnut data={revenueData} height={400} options={options} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			</TRadLayout>
		);
	}
}
