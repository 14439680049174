import { Component } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import AuthService from "../../Services/Auth/Auth.service";
import { FaEdit } from "react-icons/fa";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaPlus, FaRegFloppyDisk, FaXmark } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import MasterDataService from "../../Services/MasterData.service";

type Props = {
	type: string;
};
type State = {
	redirect: string | null;
	ready: boolean;
	currentUser: any | null;
	values: Array<any>;
	tableKey: number;
};

type valueType = {
	Value: string;
	Action: any;
	IsEdit: boolean;
};

export default class GenericMasterData extends Component<Props, State> {
	valueTableHeaders: TableColumnType<valueType>[] = [
		{
			prop: "Value",
			title: "Scan Type",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<input
							value={row.Value}
							className="form-control"
							onChange={(e) => {
								row.Value = e.target.value;
								this.setState({
									values: this.state.values,
								});
							}}
						/>
					)}
					{!row.IsEdit && <span>{row.Value}</span>}
				</div>
			),
		},
		{
			prop: "Action",
			title: "Action",
			isFilterable: false,
			cell: (row) => (
				<div className="d-flex">
					{!row.IsEdit && (
						<button
							className="btn btn-primary d-flex"
							onClick={() => {
								row.IsEdit = !row.IsEdit;
								this.setState({
									values: this.state.values,
								});
							}}
						>
							<span className="text-white">
								<FaEdit />
							</span>
							<span className="ms-3 mt-1">Edit</span>
						</button>
					)}
					{row.IsEdit && (
						<>
							<button
								className="btn btn-success d-flex"
								onClick={() => {
									this.saveValue(row);
								}}
							>
								<span className="text-white">
									<FaRegFloppyDisk />
								</span>
								<span className="ms-3 mt-1">Save</span>
							</button>
							<button
								className="btn btn-warning d-flex ms-4 "
								onClick={() => {
									row.IsEdit = !row.IsEdit;
									this.setState({
										values: this.state.values,
									});
								}}
							>
								<span className="text-white">
									<FaXmark />
								</span>
								<span className="ms-3 mt-1 text-white">Cancel</span>
							</button>
						</>
					)}
				</div>
			),
		},
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			currentUser: null,
			values: [],
			tableKey: Math.random(),
		};
	}

	async componentDidMount() {
		const currentUser = AuthService.getCurrentUser();
		this.setState(
			{
				currentUser,
			},
			async () => await this.loadValues()
		);
	}

	async loadValues() {
		const value = await MasterDataService.getByType(this.props.type);
		if (value.status === 200 && value.data.success) {
			this.setState({
				values: value.data.results,
				ready: true,
			});
		}
	}

	async saveValue(data: any) {
		if (data.Value === "") {
			toast.error("Please enter scan type");
			return;
		}
		const res = await MasterDataService.createOrUpdateValue({
			Id: data.Id,
			Value: data.Value,
			Type: this.props.type,
		});
		if (res.status === 200 && res.data.success) {
			toast.success(res.data.message);
			this.loadValues();
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<div className="px-10">
				{this.state.ready && this.state.currentUser !== null && (
					<DatatableWrapper
						body={this.state.values}
						headers={this.valueTableHeaders}
						paginationOptionsProps={{
							initialState: {
								rowsPerPage: 10,
								options: [5, 10, 15, 20],
							},
						}}
						key={this.state.tableKey}
					>
						<Row className="mb-10">
							<Col className="col-3 d-flex flex-col justify-content-end align-items-end">
								<Filter />
							</Col>
							<Col className="d-flex justify-content-end align-items-end">
								<PaginationOptions />
								<button
									className="btn btn-primary d-flex ms-10"
									onClick={() => {
										const values = this.state.values;
										values.push({
											Id: undefined,
											Value: "",
											IsEdit: true,
										});
										this.setState({
											values,
											tableKey: Math.random(),
										});
										console.log(this.state.values);
									}}
								>
									<span className="text-white">
										<FaPlus />
									</span>
									<span className="ms-3 mt-1">Create New</span>
								</button>
							</Col>
						</Row>
						<Table>
							<TableHeader />
							<TableBody />
						</Table>
						<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
							<Pagination />
						</Col>
					</DatatableWrapper>
				)}
				<ToastContainer />
			</div>
		);
	}
}
