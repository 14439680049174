import { Component } from "react";
import { Navigate } from "react-router-dom";
import AppLayout from "../../Layouts/App/AppLayout";
import AuthService from "../../Services/Auth/Auth.service";
import profilePhoto from "../../Assets/Images/profile_head.jpg";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import GenericUserService from "../../Services/GenericUser.service";
import { toast, ToastContainer } from "react-toastify";
import React from "react";

type Props = {};
type State = {
  currentUser: any | null;
  redirect: string | null;
  ready: boolean;
};

export default class MyProfile extends Component<Props, State> {
  userSubmitButtonRef: React.Ref<HTMLButtonElement> = null;
  initialValues = {
    id: "",
    userName: "",
    displayName: "",
    email: "",
    mobile: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  };
  constructor(props: Props) {
    super(props);

    this.readyToSave = this.readyToSave.bind(this);
    this.userSubmitButtonRef = React.createRef<HTMLButtonElement>();
    this.state = {
      currentUser: null,
      redirect: null,
      ready: false,
    };
  }

  componentDidMount(): void {
    this.loadUser();
  }

  async readyToSave(data: any) {
    const req = {
      Id: data.id,
      UserName: data.userName,
      DisplayName: data.displayName,
      Email: data.email,
      Mobile: data.mobile,
      Address1: data.address1,
      Address2: data.address2,
      City: data.city,
      State: data.state,
      Country: data.country,
    };
    var re = await GenericUserService.update(req);
    if (re.status === 200) {
      if (re.data.success) {
        toast.success("User updated successfully");
        this.loadUser();
      } else toast.error(re.data.errorMessage[0]);
    }
  }

  loadUser = async () => {
    const res = await AuthService.getLoggedUser();

    if (res.response.success) {
      const user = res.response.data;
      this.initialValues = {
        id: user.userId,
        userName: user.userName,
        displayName: user.displayName,
        email: user.email,
        mobile: user.mobile,
        address1: user.address1,
        address2: user.address2,
        city: user.city,
        state: user.state,
        country: user.country,
        zipCode: user.zipCode,
      };
      this.setState({
        currentUser: res.response.data,
        ready: true,
      });
    }
  };

  validationSchema = Yup.object().shape({
    userName: Yup.string().required("UserName is required"),

    displayName: Yup.string().required("DisplayName is required"),
    email: Yup.string().required("Email is required").email("Invalid email address"),
    mobile: Yup.string()
      .required("Mobile is required")
      .matches(/^\d{10}$/, "Invalid mobile number"),
    country: Yup.string().required("Country is required"),
  });

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <AppLayout>
        <>
          {this.state.ready && (
            <div className="mt-60">
              <div className="d-sm-flex align-items-center justify-content-between mb-12">
                <h1 className="h3 mb-0 text-gray-800">My Profile</h1>
              </div>
              <div className="row">
                <div className="col-md-3 border-right">
                  <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                    <img className="rounded-circle mt-5" width="150px" src={profilePhoto} alt="Profile" />
                    <span className="font-weight-bold">{this.state.currentUser.displayName}</span>
                    <span className="text-black-50">{this.state.currentUser.email}</span>
                    <span> </span>
                  </div>
                </div>
                <Formik enableReinitialize initialValues={this.initialValues} onSubmit={this.readyToSave}>
                  <div className="col-md-5 border-right">
                    <Form className="p-10">
                      <div className="p-3 py-5">
                        <div className="row mt-5">
                          <div className="col-md-7">
                            <label htmlFor="userName">User Name</label>
                            <Field type="text" id="userName" name="userName" placeholder="User Name" className="form-control" />
                            <ErrorMessage name="userName" component="div" className="text-danger" />
                          </div>
                          <div className="col-md-7 mt-4">
                            <label htmlFor="displayName">Display Name</label>
                            <Field type="text" id="displayName" name="displayName" placeholder="Display Name" className="form-control" />
                            <ErrorMessage name="displayName" component="div" className="text-danger" />
                          </div>
                          <div className="col-md-7 mt-4">
                            <label htmlFor="mobile">Mobile Number</label>
                            <Field type="text" id="mobile" name="mobile" placeholder="Mobile Number" className="form-control" />
                            <ErrorMessage name="mobile" component="div" className="text-danger" />
                          </div>
                          <div className="col-md-7 mt-4">
                            <label htmlFor="email">Email</label>
                            <Field type="text" id="email" name="email" placeholder="Email" className="form-control" />
                            <ErrorMessage name="email" component="div" className="text-danger" />
                          </div>
                          <div className="col-md-7 mt-4">
                            <label htmlFor="address1">Address 1</label>
                            <Field type="text" id="address1" name="address1" placeholder="Address 1" className="form-control" />
                          </div>
                          <div className="col-md-7 mt-4">
                            <label htmlFor="address2">Address 2</label>
                            <Field type="text" id="address2" name="address2" placeholder="Address 2" className="form-control" />
                          </div>
                          <div className="col-md-7 mt-4">
                            <label htmlFor="state">State</label>
                            <Field type="text" id="state" name="state" placeholder="State" className="form-control" />
                          </div>
                          <div className="col-md-7 mt-4">
                            <label htmlFor="city">City</label>
                            <Field type="text" id="city" name="city" placeholder="City" className="form-control" />
                          </div>
                          <div className="col-md-7 mt-4">
                            <label htmlFor="country">Country</label>
                            <Field type="text" id="country" name="country" placeholder="Country" className="form-control" />
                            <ErrorMessage name="country" component="div" className="text-danger" />
                          </div>
                        </div>
                        <div className="mt-20 text-center">
                          <button type="submit" className="btn btn-primary profile-button">
                            Save Profile
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Formik>
                <div className="col-md-4">
                  <div className="p-3 py-5"></div>
                </div>
              </div>
            </div>
          )}
        </>
        <ToastContainer />
      </AppLayout>
    );
  }
}
