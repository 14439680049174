import { Component } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import AuthService from "../../Services/Auth/Auth.service";
import { Navigate } from "react-router-dom";
import "../../Styles/appheader.layout.css";
import { FaHome } from "react-icons/fa";
import NavHeaderRight from "../Shared/NavHeaderRight";
import { AppConstants } from "../../Utils/AppConstants";

type Props = {
	currentUser: any;
};
type State = {
	redirect: string | null;
	userRole: string | null;
	links: Array<any>;
};

export default class InvHeader extends Component<Props, State> {
	allLinks = [
		{
			title: "Home",
			route: "/invhome",
			roles: [AppConstants.ROLE_CLIENTADMIN, AppConstants.ROLE_SHOPADMIN, AppConstants.ROLE_SALESUSER],
		},
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			userRole: null,
			links: [],
		};
	}

	componentDidMount(): void {
		const currentUser = AuthService.getCurrentUser();
		const userRole = currentUser.user.Roles;
		const links = this.allLinks.filter((x: any) => x.roles.indexOf(userRole) > -1);
		this.setState({ userRole, links });
	}

	getActiveClass(route: any) {
		const w = window.location;
		if (w.href.indexOf(route) > -1) {
			return "active";
		}

		return "";
	}

	render() {
		const { userRole } = this.state;

		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		return (
			<>
				<Navbar fixed="top" bg="secondary" expand="md" className="ps-10 pe-10 pt-10 pb-10 d-print-none">
					{this.props.currentUser.applications.length > 1 && (
						<Navbar.Brand href="/home" className="text-white">
							<FaHome className="text-white fs-5 " />
						</Navbar.Brand>
					)}
					<Navbar.Brand href="/invhome" className="text-white">
						InvoiceManagement [{this.props.currentUser?.user.Client}]
					</Navbar.Brand>
					<Navbar.Toggle className="text-white" aria-controls="navbarScroll" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto text-white" navbarScroll>
							{this.state.links &&
								this.state.links.length > 0 &&
								this.state.links.map((l: any) => {
									return (
										<Nav.Item key={Math.random()}>
											<Nav.Link key={Math.random()} href={l.route} className={"text-white fs-6 fw-semibold " + this.getActiveClass(l.route)}>
												{l.title}
											</Nav.Link>
										</Nav.Item>
									);
								})}
							{userRole && userRole.includes(AppConstants.ROLE_CLIENTADMIN) && (
								<>
									<Nav.Item>
										<Nav.Link key={Math.random()} href="/invadminsales" className={"text-white fs-6 fw-semibold " + this.getActiveClass("invadminsales")}>
											New Sale
										</Nav.Link>
									</Nav.Item>
									<NavDropdown title="Manage" className={this.getActiveClass("manage")}>
										<NavDropdown.Item href="/manage/invusers" className="p-5">
											Logins
										</NavDropdown.Item>
										<NavDropdown.Item href="/manage/invcats" className="p-5">
											Categories
										</NavDropdown.Item>
										<NavDropdown.Item href="/manage/invitems" className="p-5">
											Items
										</NavDropdown.Item>
										<NavDropdown.Item href="/manage/invshoppurchases" className="p-5">
											Shop Purchases
										</NavDropdown.Item>
										<NavDropdown.Item href="/manage/invstorepurchases" className="p-5">
											Store Purchases
										</NavDropdown.Item>
										<NavDropdown.Item href="/manage/invadminshopstocks" className="p-5">
											Shop Stocks
										</NavDropdown.Item>
										<NavDropdown.Item href="/manage/invadminexpenses" className="p-5">
											Expenses
										</NavDropdown.Item>
									</NavDropdown>
									<NavDropdown title="Reports" className={this.getActiveClass("/invshopsaleaudits")}>
										<NavDropdown.Item href="/invshopsaleaudits" className="p-5">
											Sales Report
										</NavDropdown.Item>
										<NavDropdown.Item href="/invtaxes" className="p-5">
											Tax Report
										</NavDropdown.Item>
									</NavDropdown>
								</>
							)}
							{userRole && userRole.includes(AppConstants.ROLE_SHOPADMIN) && (
								<>
									<Nav.Item>
										<Nav.Link href="/invshopsales" className={"text-white fs-6 fw-semibold " + this.getActiveClass("/invshopsales")}>
											Sale
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link href="/invshopstocks" className={"text-white fs-6 fw-semibold " + this.getActiveClass("/invshopstocks")}>
											Stock
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link href="/invshopsaleaudits" className={"text-white fs-6 fw-semibold " + this.getActiveClass("/invshopsaleaudits")}>
											Sales Report
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link href="/manageinvexpenses" className={"text-white fs-6 fw-semibold " + this.getActiveClass("/manageinvexpenses")}>
											Expenses
										</Nav.Link>
									</Nav.Item>
								</>
							)}
						</Nav>
						<NavHeaderRight />
					</Navbar.Collapse>
				</Navbar>
			</>
		);
	}
}
