import { Component } from "react";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import InvService from "../../Services/Inv/Inv.service";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaFilePdf } from "react-icons/fa6";
import { Badge, Col, Row, Table } from "react-bootstrap";
import moment from "moment";
import AuthService from "../../Services/Auth/Auth.service";
import { AppConstants } from "../../Utils/AppConstants";
import Select from "react-select";
import ExportToExcel from "../Shared/ExportToExcel";
import { FaEdit } from "react-icons/fa";
import ConfirmDialog from "../Shared/ConfirmDialog";
import "../../Styles/invTable.custom.css";

type Props = {};

type State = {
	redirect: string | null;
	items: Array<any>;
	ready: boolean;
	selectedFilter: string;
	currentUser: any;
	shops: Array<any>;
	selectedShops: any;
	showConfirm: any;
	selectedRow: any;
};

type itemType = {
	SalesId: string;
	Customer: string;
	Invoice: string;
	TotalItems: number;
	Discount: number;
	TotalTax: number;
	TotalPrice: number;
	PaidAmount: number;
	CreatedDate: Date;
	CreatedBy: string;
	Action: any;
	ShopId: any;
	HasPrinted: any;
	Status: any;
};

export default class InvShopSaleReport extends Component<Props, State> {
	itemTableHeaders: TableColumnType<itemType>[] = [
		{
			prop: "Invoice",
			title: "Invoice",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<div>
					{this.state.currentUser?.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) === -1 && <a href={"/invshopsales/" + row.SalesId + "?view"}>{row.Invoice}</a>}
					{this.state.currentUser?.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) > -1 && <a href={"/invadminsales?SalesId=" + row.SalesId + "?ShopId=" + row.ShopId + "?view"}>{row.Invoice}</a>}
				</div>
			),
		},
		{
			prop: "CreatedDate",
			title: "Invoice Date",
			isSortable: true,
			isFilterable: true,
			cell: (row) => <div>{moment(row.CreatedDate).format("DD/MM/YYYY HH:mm")}</div>,
		},
		{
			prop: "Customer",
			title: "Customer",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "TotalItems",
			title: "Total Items",
			isSortable: true,
		},
		{
			prop: "Discount",
			title: "Discount",
			isSortable: true,
		},

		{
			prop: "TotalPrice",
			title: "Amount",
			isSortable: true,
			cell: (row) => <div>{row.TotalPrice.toFixed()}</div>,
		},
		{
			prop: "PaidAmount",
			title: "Payment",
			isSortable: true,
			cellProps: {
				className: (row) => (row.PaidAmount < row.TotalPrice ? "bg-red-100" : ""),
			},
		},
		{
			prop: "Status",
			title: "Status",
			isSortable: true,
			cell: (row) => {
				return (
					<>
						<div>{row.HasPrinted === "true" ? <Badge bg="success">Submitted</Badge> : <Badge bg="danger">Draft</Badge>}</div>
					</>
				);
			},
		},
		{
			prop: "Action",
			title: "Action",
			cell: (row) => (
				<div className="d-flex gap-2">
					<button title="Invoice" onClick={() => this.generateInvoice(row.SalesId)} className="btn btn-primary d-flex">
						<span className="text-white cursor-pointer">
							<FaFilePdf />
						</span>
					</button>
					<button
						title="Edit"
						onClick={() => {
							console.log(row);
							if (row.HasPrinted === "true") {
								this.setState({
									selectedRow: row,
									showConfirm: true,
								});
							} else {
								if (this.state.currentUser?.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) === -1) {
									window.location.href = "/invshopsales/" + row.SalesId;
								} else if (this.state.currentUser?.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) > -1) {
									window.location.href = "/invadminsales?SalesId=" + row.SalesId + "?ShopId=" + row.ShopId;
								}
							}
						}}
						className="btn btn-warning d-flex"
					>
						<span className="text-white cursor-pointer">
							<FaEdit />
						</span>
					</button>
				</div>
			),
		},
	];

	filters = ["Today", "This Month", "This Year", "All Credit"];

	constructor(props: Props) {
		super(props);
		this.state = {
			redirect: null,
			ready: true,
			items: [],
			selectedFilter: this.filters[0],
			currentUser: AuthService.getCurrentUser(),
			shops: [],
			selectedShops: [],
			showConfirm: false,
			selectedRow: null,
		};

		if (this.state.currentUser.user.VatTax) {
			this.itemTableHeaders.splice(5, 0, {
				prop: "TotalTax",
				title: "VAT",
				isSortable: true,
			});
			//if vattax = true then we
		} else {
			this.itemTableHeaders.splice(5, 0, {
				prop: "TotalTax",
				title: "Total Tax",
				isSortable: true,
			});
		}
		this.loadValues = this.loadValues.bind(this);
		this.onShopChange = this.onShopChange.bind(this);
	}

	async componentDidMount() {
		console.log(this.state.currentUser);
		if (this.state.currentUser?.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) > -1) {
			const response = await InvService.getShops();
			const shops = new Array<any>();
			response.data.map((s: any) =>
				shops.push({
					label: s.Name,
					value: s.Id,
				})
			);
			this.setState({
				shops,
			});
		}
		await this.loadValues();
	}

	generateInvoice = async (SaleId: any) => {
		var selectedShops = null;
		if (this.state.currentUser?.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) > -1) {
			if (this.state.selectedShops === null || this.state.selectedShops.length === 0) return;
			selectedShops = this.state.selectedShops.map((c: any) => c.value).join(",");
		}
		const res = await InvService.generateInvoice({ ShopId: selectedShops, SaleId });
		const uint8Array = new Uint8Array(res.data.data);
		const blob = new Blob([uint8Array], { type: "application/pdf" });
		const blobUrl = URL.createObjectURL(blob);
		window.open(blobUrl, "_blank");
	};

	async loadValues() {
		var selectedShops = null;
		if (this.state.currentUser?.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) > -1) {
			if (this.state.selectedShops === null || this.state.selectedShops.length === 0) return;
			selectedShops = this.state.selectedShops.map((c: any) => c.value).join(",");
		}

		const resp = await InvService.shopSalesReport({ filter: this.state.selectedFilter, shopId: selectedShops });
		this.setState({
			items: resp.data,
			ready: true,
		});
	}

	onShopChange(e: any) {
		if (e === null || e.length === 0) {
			this.setState({
				items: [],
			});
		}

		this.setState(
			{
				selectedShops: e,
				ready: true,
			},
			async () => await this.loadValues()
		);
	}

	editSubmittedSale = async (confirm: any) => {
		if (confirm) {
			if (this.state.currentUser?.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) === -1) {
				await InvService.editSubmittedSale({ salesId: this.state.selectedRow.SalesId, shopId: null });
				window.location.href = "/invshopsales/" + this.state.selectedRow.SalesId;
			} else if (this.state.currentUser?.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) > -1) {
				window.location.href = "/invadminsales?SalesId=" + this.state.selectedRow.SalesId + "?ShopId=" + this.state.selectedRow.ShopId;
				await InvService.editSubmittedSale({ salesId: this.state.selectedRow.SalesId, shopId: this.state.selectedRow.ShopId });
			}
		}
		this.setState({
			showConfirm: false,
		});
	};

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<>
					<ConfirmDialog message="This will restore the stocks, are you sure you want continue to edit?" show={this.state.showConfirm} key={Math.random()} title="Reset Stock" confirmModal={this.editSubmittedSale} />
					<div className="mt-55 px-10"></div>
					{this.state.ready && (
						<>
							<DatatableWrapper
								body={this.state.items}
								headers={this.itemTableHeaders}
								paginationOptionsProps={{
									initialState: {
										rowsPerPage: 10,
										options: [5, 10, 15, 20],
									},
								}}
								sortProps={{
									initialState: {
										order: "desc",
										prop: "Invoice",
									},
								}}
								key={Math.random()}
							>
								<Row className="mb-10">
									{this.state.currentUser?.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) > -1 && (
										<Col className="mb-3 mb-md-0">
											<Select isMulti={true} placeholder="Select shop" name="shop" value={this.state.selectedShops} onChange={this.onShopChange} options={this.state.shops}></Select>
										</Col>
									)}
									<Col xs={12} md={6} lg={4} className="d-flex flex-col justify-content-end align-items-end gap-5 mb-3 mb-md-0">
										<Filter placeholder="Search..." />
										<ExportToExcel fileName={`SalesReport_${moment().format("DD-MM-YYYY-HH-mm")}`} data={this.state.items} sheetName="Sales Report" fields={["Invoice", "Customer", "TotalItems", "Discount", "TotalTax", "TotalPrice", "PaidAmount", "CreatedDate", "CreatedBy"]} />
									</Col>
									<Col xs={12} md={6} lg={6} className="d-flex justify-content-end align-items-end gap-5 ">
										{this.filters.map((x) => (
											<Badge
												key={Math.random()}
												className={x === this.state.selectedFilter ? "bg-blue-400 cursor-pointer" : "bg-blue-700 cursor-pointer"}
												onClick={() =>
													this.setState(
														{
															selectedFilter: x,
														},
														() => {
															this.loadValues();
														}
													)
												}
											>
												{x}
											</Badge>
										))}
									</Col>
								</Row>
								<Table className="dataTable">
									<TableHeader />
									<TableBody />
								</Table>
								<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
									<PaginationOptions />
									<div className="ms-5" />
									<Pagination />
								</Col>
							</DatatableWrapper>
						</>
					)}
				</>
			</InvLayout>
		);
	}
}
