import { Component } from "react";
import { FaChartLine, FaRegBuilding, FaUsers } from "react-icons/fa6";
import { BiUser } from "react-icons/bi";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import AuthService from "../../Services/Auth/Auth.service";
import "../../Styles/invhome.custom.css";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";
import { Badge } from "react-bootstrap";
import { AppConstants } from "../../Utils/AppConstants";
import InvService from "../../Services/Inv/Inv.service";

type Props = {};
type State = {
	redirect: string | null;
	ready: boolean;
	currentUser: any | null;
	selectedFilter: any;
	dataDetails: any;
	saleByShopKey: any;
	earningsKey: any;
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

export default class InvHome extends Component<Props, State> {
	earingsData = {
		labels: Array<any>(),
		datasets: [
			{
				label: `Earnings`,
				data: Array<any>(),
				borderColor: "rgb(255, 99, 132)",
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
		],
	};
	lineChartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: "top" as const,
			},
			title: {
				display: false,
				text: "Chart.js Line Chart",
			},
		},
	};
	salesByShopData = {
		labels: Array<any>(),
		datasets: [
			{
				label: "Sales",
				data: Array<any>(),
				backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40", "#66FF66", "#FFD966", "#FF6666", "#3399FF"],
				borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
				borderWidth: 1,
			},
		],
	};
	filters = ["Today", "This Month", "This Year"];

	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			currentUser: null,
			selectedFilter: "Today",
			dataDetails: null,
			saleByShopKey: Math.random(),
			earningsKey: Math.random(),
		};
	}

	componentDidMount(): void {
		const currentUser = AuthService.getCurrentUser();
		if (currentUser.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) > -1) {
			this.setState({ currentUser, ready: true }, () => this.loadData());
		} else {
			this.setState({ currentUser, ready: true });
		}
	}

	async loadData() {
		const dataResponse = await InvService.getDashboardData({ filter: this.state.selectedFilter });
		if (dataResponse.data.length > 1) {
			this.salesByShopData.labels = [];
			this.salesByShopData.datasets[0].data = [];
			Object.values(dataResponse.data[1]).map((d: any) => {
				this.salesByShopData.labels.push(d.Name);
				this.salesByShopData.datasets[0].data.push(d.ShopSales);
				return "";
			});
			if (dataResponse.data.length > 2) {
				this.earingsData.labels = [];
				this.earingsData.datasets[0].data = [];
				Object.values(dataResponse.data[2]).map((d: any) => {
					this.earingsData.labels.push(d.MonthYear);
					this.earingsData.datasets[0].data.push(d.Earnings);
					return "";
				});
			}
			this.setState({
				dataDetails: dataResponse.data[0][0],
				saleByShopKey: Math.random(),
				earningsKey: Math.random(),
			});
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<>
					{this.state.ready && this.state.currentUser.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) > -1 && (
						<div className="mt-60">
							<div className="d-sm-flex align-items-center justify-content-between mb-12">
								<h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
								<div className="d-flex gap-5 ">
									{this.filters.map((x) => (
										<Badge
											key={Math.random()}
											className={x === this.state.selectedFilter ? "bg-blue-400 cursor-pointer" : "bg-blue-700 cursor-pointer"}
											onClick={() =>
												this.setState(
													{
														selectedFilter: x,
													},
													() => {
														this.loadData();
													}
												)
											}
										>
											{x}
										</Badge>
									))}
								</div>
							</div>
							<div className="row">
								<div className="col-xl-3 col-sm-6 col-12">
									<div className="card p-20 shadow border-left-success">
										<div className="card-content">
											<div className="card-body">
												<div className="media d-flex">
													<div className="align-self-center">
														<FaUsers className="text-success fs-1 float-left" />
													</div>
													<div className="ms-auto text-end">
														<h3>{this.state.dataDetails?.Shopcount}</h3>
														<span>Shops</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6 col-12">
									<div className="card p-20 shadow border-left-warning">
										<div className="card-content">
											<div className="card-body">
												<div className="media d-flex">
													<div className="align-self-center">
														<FaRegBuilding className="text-warning fs-1 float-left" />
													</div>
													<div className="ms-auto text-end">
														<h3>{this.state.dataDetails?.TotalSalescount}</h3>
														<span>Total Sales</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6 col-12">
									<div className="card p-20 shadow border-left-primary">
										<div className="card-content">
											<div className="card-body">
												<div className="media d-flex">
													<div className="align-self-center">
														<FaChartLine className="text-primary fs-1 float-left" />
													</div>
													<div className="ms-auto text-end">
														<h3>{this.state.dataDetails?.Itemcount}</h3>
														<span>Items</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-sm-6 col-12">
									<div className="card p-20 shadow border-left-danger">
										<div className="card-content">
											<div className="card-body">
												<div className="media d-flex">
													<div className="align-self-center">
														<BiUser className="text-danger fs-1 float-left" />
													</div>
													<div className="ms-auto text-end">
														<h3>{this.state.dataDetails?.TotalSalesAmount}</h3>
														<span>Total Sales Amount</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-xl-8 col-lg-7">
									<div className="card shadow mb-24 rounded-top-4">
										<div className="card-card-header p-16 d-flex flex-row align-items-center justify-content-between">
											<h6 className="m-0 fw-bold text-primary">Earnings</h6>
										</div>
										<div className="card-body">
											<Line key={this.state.earningsKey} options={this.lineChartOptions} data={this.earingsData} height={400} />
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-lg-5">
									<div className="card shadow mb-24 rounded-top-4">
										<div className="card-card-header p-16 d-flex flex-row align-items-center justify-content-between">
											<h6 className="m-0 fw-bold text-primary">Sales By Shop</h6>
										</div>
										<div className="card-body">
											<Doughnut key={this.state.saleByShopKey} data={this.salesByShopData} height={400} options={this.lineChartOptions} />
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{this.state.ready && this.state.currentUser.user.Roles.indexOf(AppConstants.ROLE_SHOPADMIN) > -1 && (
						<div className="mt-60">
							<h1>Welcome {this.state.currentUser.user.Name} !</h1>
						</div>
					)}
				</>
			</InvLayout>
		);
	}
}
