import moment from "moment";
import { Component } from "react";
import { Badge, Col, Row, Table } from "react-bootstrap";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaEdit } from "react-icons/fa";
import { FaPlus, FaTrash } from "react-icons/fa6";
import { Navigate } from "react-router-dom";
import AppLayout from "../../Layouts/App/AppLayout";
import AuthService from "../../Services/Auth/Auth.service";
import PlatformService from "../../Services/Auth/Platform.service";
import ClientDialog from "./ClientDialog.component";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDialog from "../Shared/ConfirmDialog";

type Props = {};
type State = {
	redirect: string | null;
	ready: boolean;
	currentUser: any | null;
	clients: Array<clientType>;
	isShowDialog: boolean;
	selectedRecord: any | null;
	showDeleteConfirm: boolean;
};

type clientType = {
	Id: string;
	CreatedDate: string;
	IsActive: boolean;
	ModifiedDate: string;
	Name: string;
	PrimaryColor: string;
	SecondaryColor: string;
	Logo: string;
	Action: any;
};

export default class Clients extends Component<Props, State> {
	clientTableHeaders: TableColumnType<clientType>[] = [
		{
			prop: "Name",
			title: "Name",
			isFilterable: true,
			isSortable: true,
		},
		{
			prop: "IsActive",
			title: "Is Active",
			isFilterable: true,
			cell: (row) => (
				<div>
					{row.IsActive && <Badge bg="success">Yes</Badge>}
					{!row.IsActive && <Badge bg="danger">No</Badge>}
				</div>
			),
		},
		{
			prop: "CreatedDate",
			title: "Created Date",
			isFilterable: false,
			cell: (row) => <div>{moment(row.CreatedDate).format("DD/MM/YYYY")}</div>,
		},
		{
			prop: "PrimaryColor",
			title: "Primary Color",
			isFilterable: false,
			cell: (row) => <div style={{ backgroundColor: row.PrimaryColor }}>{row.PrimaryColor}</div>,
		},
		{
			prop: "SecondaryColor",
			title: "Secondary Color",
			isFilterable: false,
			cell: (row) => <div style={{ backgroundColor: row.SecondaryColor }}>{row.SecondaryColor}</div>,
		},
		{
			prop: "Logo",
			title: "Logo",
			isFilterable: false,
			cell: (row) => <div>{row.Logo !== null && row.Logo !== "" && <img width={50} src={row.Logo} alt="Logo" />}</div>,
		},
		{
			prop: "ModifiedDate",
			title: "Modified Date",
			isFilterable: false,
			cell: (row) => <div>{moment(row.ModifiedDate).format("DD/MM/YYYY")}</div>,
		},
		{
			prop: "Action",
			title: "Action",
			isFilterable: false,
			cell: (row) => (
				<div className="d-flex">
					<button
						className="btn btn-primary d-flex"
						onClick={() =>
							this.setState({
								selectedRecord: row,
								isShowDialog: true,
							})
						}
					>
						<span className="text-white">
							<FaEdit />
						</span>
						<span className="ms-3 mt-1">Edit</span>
					</button>
					<button
						className="btn btn-danger d-flex ms-5"
						onClick={() => {
							this.setState({
								selectedRecord: row,
								showDeleteConfirm: true,
							});
						}}
					>
						<span className="text-white">
							<FaTrash />
						</span>
						<span className="ms-3 mt-1">Delete</span>
					</button>
				</div>
			),
		},
	];
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			currentUser: null,
			clients: [],
			isShowDialog: false,
			selectedRecord: null,
			showDeleteConfirm: false,
		};

		this.loadClients = this.loadClients.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	componentDidMount() {
		const currentUser = AuthService.getCurrentUser();
		this.setState(
			{
				currentUser,
			},
			async () => {
				await this.loadClients();
			}
		);
	}

	async loadClients() {
		const response = await PlatformService.getClients();
		if (response.status === 200) {
			this.setState({
				ready: true,
				clients: response.data.clients,
			});
		}
	}

	async handleSave(data: any) {
		console.log("when saving", data);
		let preferredLanguagesString = "";

		if (Array.isArray(data.preferredLanguages)) {
			if (data.preferredLanguages.length === 1) {
				preferredLanguagesString = data.preferredLanguages[0];
			} else {
				preferredLanguagesString = data.preferredLanguages.join(",");
			}
		}
		const req = {
			Id: data.id,
			Name: data.name,
			PrimaryColor: data.primarycolor,
			SecondaryColor: data.secondarycolor,
			Logo: data.logo,
			PreferredLanguage: preferredLanguagesString,
		};
		console.log("req", req);
		const res = req.Id == null ? await PlatformService.createClient(req) : await PlatformService.updateClient(req);
		if (res.status === 200) {
			if (res.data.success === false && res.data.errorMessage.length > 0) {
				toast.error(res.data.errorMessage[0]);
			} else if (res.data.success) {
				if (req.Id === null) {
					toast.success("Client created successfully!");
				} else {
					toast.success("Client updated successfully!");
				}
				this.setState(
					{
						isShowDialog: false,
					},
					async () => await this.loadClients()
				);
			}
		}
	}

	async onDelete(confirm: boolean) {
		this.setState({
			showDeleteConfirm: false,
		});
		if (confirm) {
			console.log("delete");
			const res = await PlatformService.deleteClient({
				Id: this.state.selectedRecord.Id,
			});

			if (res.status === 200 && res.data.success) {
				toast.success("Client deleted successfully!");
				this.loadClients();
			} else {
				toast.error("Client deletion failed!");
			}
		} else {
			console.log("cancel");
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<AppLayout>
				<>
					{this.state.ready && this.state.currentUser !== null && (
						<>
							<div className="mt-60">
								<div className="d-sm-flex align-items-center justify-content-between mb-12">
									<h1 className="h3 mb-0 text-gray-800">Clients</h1>
								</div>
							</div>

							<DatatableWrapper
								body={this.state.clients}
								headers={this.clientTableHeaders}
								paginationOptionsProps={{
									initialState: {
										rowsPerPage: 10,
										options: [5, 10, 15, 20],
									},
								}}
							>
								<Row className="mb-10">
									<Col className="col-3 d-flex flex-col justify-content-end align-items-end">
										<Filter />
									</Col>
									<Col className="d-flex justify-content-end align-items-end">
										<PaginationOptions />
										<button
											className="btn btn-primary d-flex ms-10"
											onClick={() =>
												this.setState({
													selectedRecord: null,
													isShowDialog: true,
												})
											}
										>
											<span className="text-white">
												<FaPlus />
											</span>
											<span className="ms-3 mt-1">Create New</span>
										</button>
									</Col>
								</Row>
								<Table>
									<TableHeader />
									<TableBody />
								</Table>
								<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
									<Pagination />
								</Col>
							</DatatableWrapper>
						</>
					)}
					<ClientDialog
						show={this.state.isShowDialog}
						selectedRecord={this.state.selectedRecord}
						handleSave={this.handleSave}
						handleClose={() => {
							//TODO - clear out all variables that is used in the form
							this.setState({ isShowDialog: false });
						}}
						key={Math.random()}
					></ClientDialog>
					<ToastContainer />
					<ConfirmDialog confirmModal={this.onDelete} message="Do you want to delete this client?" show={this.state.showDeleteConfirm} title="Delete Client" key={Math.random()} />
				</>
			</AppLayout>
		);
	}
}
