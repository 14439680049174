import { Component } from "react";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import InvService from "../../Services/Inv/Inv.service";
import { AppConstants } from "../../Utils/AppConstants";
import GenericUsers from "../Shared/GenericUsers.component";
import Select from "react-select";

type Props = {};
type State = {
	redirect: string | null;
	shops: Array<any>;
	selectedShop: any;
	reload: any;
	ready: boolean;
};

export default class InvUser extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			shops: [],
			selectedShop: null,
			reload: Math.random(),
			ready: false,
		};

		this.onShopChange = this.onShopChange.bind(this);
	}

	async componentDidMount() {
		const response = await InvService.getShops();
		const shops = new Array<any>();
		response.data.map((s: any) =>
			shops.push({
				label: s.Name,
				value: s.Id,
			})
		);
		this.setState({
			shops,
		});
	}

	onShopChange(e: any) {
		this.setState({
			selectedShop: e,
			reload: Math.random(),
			ready: true,
		});
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<>
					<div className="mt-50 px-10">
						<div className="row">
							<div className="col-12">
								<h4 className="h3 mb-0 text-gray-800">Manage Shop Logins</h4>
								<p>A place to manage who can access each shops</p>
							</div>
						</div>
						<div className="row mb-3 mb-md-0">
							<div className="d-flex">
								<label className="col col-lg-1 my-auto">Select Shop</label>
								<div className="col col-lg-2">
									<Select name="client" value={this.state.selectedShop} onChange={this.onShopChange} options={this.state.shops}></Select>
								</div>
							</div>
						</div>
					</div>
					{this.state.ready && <GenericUsers key={this.state.reload} role={AppConstants.ROLE_SHOPADMIN} shopId={this.state.selectedShop?.value} />}
				</>
			</InvLayout>
		);
	}
}
