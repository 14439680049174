import moment from "moment";
import React, { Component } from "react";
import { FaFileExcel } from "react-icons/fa6";
import * as XLSX from "xlsx";

type Props = {
	data: any;
	fileName: string;
	sheetName: string;
	// List of property names that should be exported from the data array
	fields?: any;
	// The header titles - incase if you want to have a different header instead of the property name
	headers?: any;
};

type State = {};

export default class ExportToExcel extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	handleExportClick = () => {
		const wb = XLSX.utils.book_new();
		var newData = this.props.data;
		if (this.props.fields !== null) {
			newData = this.props.data.map((item: any) => {
				var newItem: { [key: string]: any } = {};
				this.props.fields.forEach((field: any) => {
					if (field.toLowerCase().indexOf("date") > -1) {
						// assuming its a date value - trying to parse and format it
						if (moment(item[field]).isValid()) {
							newItem[field] = moment(item[field]).format("DD-MM-YYYY HH:mm");
						}
					} else {
						newItem[field] = item[field];
					}
				});
				return newItem;
			});
		}
		const ws = XLSX.utils.json_to_sheet(newData, { header: this.props.headers });
		XLSX.utils.book_append_sheet(wb, ws, this.props.sheetName);
		const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const blob = new Blob([buffer], { type: "application/octet-stream" });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = this.props.fileName + ".xlsx";
		link.click();
	};

	render() {
		return (
			<button onClick={this.handleExportClick} className="btn btn-success" title="Export to Excel">
				<FaFileExcel />
			</button>
		);
	}
}
