import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../Utils/ProtectedRoute";
import Home from "../Components/Auth/Home.component";
import { AppConstants } from "../Utils/AppConstants";
import Login from "../Components/Auth/Login.component";
import MyProfile from "../Components/Auth/MyProfile.component";

import "../App.css";
import "../Styles/bootstrap.custom.scss";
import "react-toastify/dist/ReactToastify.css";
import "../Styles/loader.custom.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

export default class GeneralRoutes extends React.Component {
	render(): React.ReactNode {
		return (
			<>
				<Routes>
					<Route
						path="/"
						element={
							<ProtectedRoute roles={AppConstants.ALL_ROLES}>
								<Home />
							</ProtectedRoute>
						}
					/>
					<Route path="/login" element={<Login />} />
					<Route
						path="/home"
						element={
							<ProtectedRoute roles={AppConstants.ALL_ROLES}>
								<Home />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/profile"
						element={
							<ProtectedRoute roles={AppConstants.ALL_ROLES}>
								<MyProfile />
							</ProtectedRoute>
						}
					/>
				</Routes>
			</>
		);
	}
}
