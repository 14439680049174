export default function deepCopy(obj: any) {
	if (obj === null || typeof obj !== "object") {
		return obj;
	}

	if (Array.isArray(obj)) {
		const arrCopy: any = [];
		for (let i = 0; i < obj.length; i++) {
			arrCopy[i] = deepCopy(obj[i]);
		}
		return arrCopy;
	}

	const objCopy: any = {};
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			objCopy[key] = deepCopy(obj[key]);
		}
	}

	return objCopy;
}
