import { Component } from "react";
import { Navigate } from "react-router-dom";
import AppLayout from "../../Layouts/App/AppLayout";
import AuthService from "../../Services/Auth/Auth.service";

type Props = {};
type State = {
	redirect: string | null;
	ready: boolean;
	currentUser: any | null;
};

export default class UnAuthorized extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			currentUser: null,
		};
	}

	componentDidMount(): void {
		const currentUser = AuthService.getCurrentUser();
		this.setState({ currentUser, ready: true });
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<AppLayout>
				<>
					<div className="mt-50">
						<div className="row">Sorry you dont have access to this application.</div>
					</div>
				</>
			</AppLayout>
		);
	}
}
