import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../Utils/ProtectedRoute";
import { AppConstants } from "../Utils/AppConstants";
import TRadHome from "../Components/TRad/TRadHome.component";
import TRadUnderReview from "../Components/TRad/TRadUnderReview.component";
import TRadLoading from "../Components/TRad/TRadLoading.component";
import TRadNew from "../Components/TRad/TRadNew.component";
import TRadCompleted from "../Components/TRad/TRadCompleted.component";
import TRadViewScan from "../Components/TRad/TRadViewScan.component";
import TRadManageRadiologist from "../Components/TRad/TRadManageRadiologist.component";
import TRadManageScanningCentre from "../Components/TRad/TRadManageScanningCentre.component";
import TRadManageScanTypes from "../Components/TRad/TRadManageScanTypes.component";
import TRadManageStudyNames from "../Components/TRad/TRadManageStudyNames.component";
import TRadDefaultRadiologistAssignment from "../Components/TRad/TRadDefaultRadiologistAssignment.component";

export class TRadRoutes extends React.Component {
	render(): React.ReactNode {
		return (
			<>
				<Routes>
					<Route
						path="/tradhome"
						element={
							<ProtectedRoute roles={AppConstants.TRAD_ROLES}>
								<TRadHome />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/tradunderreview"
						element={
							<ProtectedRoute roles={AppConstants.TRAD_ROLES}>
								<TRadUnderReview />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/tradloading"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN, AppConstants.ROLE_SCANNINGCENTRE]}>
								<TRadLoading />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/tradnewscans"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<TRadNew />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/tradcompleted"
						element={
							<ProtectedRoute roles={AppConstants.TRAD_ROLES}>
								<TRadCompleted />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/tradunderreview/:id"
						element={
							<ProtectedRoute roles={AppConstants.TRAD_ROLES}>
								<TRadViewScan />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/settings/tradmanagerads"
						element={
							<ProtectedRoute roles={AppConstants.TRAD_ROLES}>
								<TRadManageRadiologist />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/settings/tradscanningcentres"
						element={
							<ProtectedRoute roles={AppConstants.TRAD_ROLES}>
								<TRadManageScanningCentre />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/settings/scantypes"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<TRadManageScanTypes />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/settings/studynames"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<TRadManageStudyNames />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/settings/defaultRadiologistAssignment"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_CLIENTADMIN]}>
								<TRadDefaultRadiologistAssignment />
							</ProtectedRoute>
						}
					/>
				</Routes>
			</>
		);
	}
}
