import { Component } from "react";
import { Nav, Navbar } from "react-bootstrap";
import logo from "../../Assets/Logos/logo-no-background.png";
import { Navigate } from "react-router-dom";
import NavHeaderRight from "../Shared/NavHeaderRight";

type Props = {
	currentUser: any;
};
type State = {
	redirect: string | null;
};

export default class AppHeader extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
		};
	}

	componentDidMount(): void {
		console.log(this.props.currentUser);
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		return (
			<>
				<Navbar fixed="top" bg="primary" expand="md" className="ps-10 pe-10 pt-5 pb-5">
					<Navbar.Brand href="/home" className="text-white">
						<img src={logo} alt="NewDawnIT Core Apps" width={150} />
					</Navbar.Brand>
					<Navbar.Toggle className="text-white" aria-controls="navbarScroll" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto text-white" navbarScroll>
							<Nav.Item>
								<Nav.Link href="/home" className={"text-white  fs-6 fw-semibold " + (window.location.href.indexOf("/home") > -1 ? "active" : "")}>
									Home
								</Nav.Link>
							</Nav.Item>
							{this.props.currentUser !== null && this.props.currentUser !== undefined && this.props.currentUser.user.Roles.indexOf("PlatformAdmin") > -1 && (
								<>
									<Nav.Item>
										<Nav.Link href="/clients" className={"text-white  fs-6 fw-semibold " + (window.location.href.indexOf("/clients") > -1 ? "active" : "")}>
											Clients
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link href="/applications" className={"text-white  fs-6 fw-semibold " + (window.location.href.indexOf("/applications") > -1 ? "active" : "")}>
											Applications
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link href="/users" className={"text-white  fs-6 fw-semibold " + (window.location.href.indexOf("/users") > -1 ? "active" : "")}>
											Users
										</Nav.Link>
									</Nav.Item>
								</>
							)}
						</Nav>
						<NavHeaderRight />
					</Navbar.Collapse>
				</Navbar>
			</>
		);
	}
}
