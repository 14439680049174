import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../Utils/ProtectedRoute";
import { AppConstants } from "../Utils/AppConstants";
import Clients from "../Components/Platform/Clients.component";
import Applications from "../Components/Platform/Applications.component";
import Users from "../Components/Platform/Users.component";

export class PlatformRoutes extends React.Component {
	render(): React.ReactNode {
		return (
			<>
				<Routes>
					<Route
						path="/clients"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_PLATFORMADMIN]}>
								<Clients />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/applications"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_PLATFORMADMIN]}>
								<Applications />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/users"
						element={
							<ProtectedRoute roles={[AppConstants.ROLE_PLATFORMADMIN]}>
								<Users />
							</ProtectedRoute>
						}
					/>
				</Routes>
			</>
		);
	}
}
