import { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../../Services/Auth/Auth.service";
import TRadHeader from "./TRadHeader";
import TRadFooter from "./TRadFooter";
import "../../Styles/tradlayout.custom.css";
import { FaUsers } from "react-icons/fa6";
import { AppConstants } from "../../Utils/AppConstants";
import TRadRadiologistList from "../../Components/TRad/TRadRadiologistList.component";

type Props = {
  children: React.ReactNode;
  showMargin: boolean;
};
type State = {
  redirect: string | null;
  ready: boolean;
  showMargin: boolean;
  show: boolean;
  userRoles: string | null;
};

export default class TRadLayout extends Component<Props, State> {
  currentUser = null;
  constructor(props: Props) {
    super(props);

    this.state = {
      redirect: null,
      ready: false,
      showMargin: this.props.showMargin,
      show: false,
      userRoles: null,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser) {
      this.setState({ redirect: "/login", ready: true });
      return;
    }

    const userRoles = currentUser.user.Roles.split(",");
    this.setState({ userRoles, ready: true });

    console.log(userRoles);
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    const currentPath = window.location.pathname;
    return (
      <>
        {this.state.ready && (
          <>
            <TRadHeader currentUser={this.currentUser}></TRadHeader>
            <div className="overflow-auto container-fluid bg-light vh-100 outerwrapper">
              {this.props.children}
              {this.state.showMargin && <div className="mb-50"></div>}
            </div>
            {this.state.userRoles && this.state.userRoles.includes(AppConstants.ROLE_CLIENTADMIN) && currentPath !== "/tradhome" && (
              <div className="floatingwrapper-left">
                <div
                  title="Radiologist"
                  onClick={() => {
                    this.setState({
                      show: true,
                    });
                  }}
                >
                  <FaUsers className="iconstyle" />
                </div>
              </div>
            )}
            <TRadFooter />
          </>
        )}
        <TRadRadiologistList
          show={this.state.show}
          handleClose={() => {
            this.setState({
              show: false,
            });
          }}
        />
      </>
    );
  }
}
