import { Component } from "react";
import { GiTeleport } from "react-icons/gi";
import { Navigate } from "react-router-dom";
import "../../Styles/home.component.css";

type Props = {
	appName: string;
	route: string;
	colorIndex: number;
};
type State = {
	redirect: string | null;
};

export default class HomeCard extends Component<Props, State> {
	colors = ["bg-c-blue", "bg-c-green", "bg-c-yellow", "bg-c-pink"];
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
		};
	}

	componentDidMount(): void {}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<div className="col-md-4 col-xl-3">
				<div
					className={"card order-card " + this.colors[this.props.colorIndex]}
					onClick={() => {
						this.setState({ redirect: this.props.route });
					}}
				>
					<div className="card-block">
						<h6 className="mb-20">{this.props.appName}</h6>
						<h2 className="text-right">
							<GiTeleport className="float-end" />
							<span>PlaceHolder</span>
						</h2>
						<p className="mb-0">
							Placeholder 2<span className="f-right">PH3</span>
						</p>
					</div>
				</div>
			</div>
		);
	}
}
