import axios from "axios";
import { AppConstants } from "../Utils/AppConstants";

let baseURL = "/api";
if (process.env.NODE_ENV !== "production") {
	baseURL = "http://localhost:4001/api";
}

const api = axios.create({
	baseURL: baseURL,
});

api.interceptors.request.use(
	function (config) {
		const loader = document.getElementById("loader");
		if (loader !== undefined && loader !== null) {
			loader.style.display = "block";
		}
		const userStr = sessionStorage.getItem(AppConstants.SESSION_KEY_PAYLOAD);
		let user = null;
		if (userStr) user = JSON.parse(userStr);

		if (user && user.token) {
			config.headers["authorization"] = `Bearer ${user.token}`;
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	function (response) {
		const loader = document.getElementById("loader");
		if (loader !== undefined && loader !== null) {
			loader.style.display = "none";
		}
		return response;
	},
	function (error) {
		console.log(error);
		if (error.response.status === 403) {
			console.log("Token expired");
			sessionStorage.clear();
			window.location.href = "/login";
		}
		const loader = document.getElementById("loader");
		if (loader !== undefined && loader !== null) {
			loader.style.display = "none";
		}
		return Promise.reject(error);
	}
);

export default api;
