import { Component } from "react";
import ModalPopup from "../Shared/ModalPopup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React from "react";
import Select from "react-select";

type Props = {
	show: boolean;
	handleClose: () => void;
	handleSave: (data: any) => void;
	selectedRecord: any | null;
	roles: Array<any>;
};

type State = {
	selectedRoles: Array<any>;
};

export default class UserDialog extends Component<Props, State> {
	userSubmitButtonRef: React.Ref<HTMLButtonElement> = null;
	initialValues = {
		id: "",
		userName: "",
		password: "",
		displayName: "",
		email: "",
		mobile: "",
		selectedRoles: [],
		isActive: true,
	};

	constructor(props: Props) {
		super(props);

		this.readyToSave = this.readyToSave.bind(this);
		this.userSubmitButtonRef = React.createRef<HTMLButtonElement>();
		this.initialValues = {
			id: this.props.selectedRecord?.Id,
			userName: this.props.selectedRecord?.UserName,
			password: this.props.selectedRecord?.Password,
			displayName: this.props.selectedRecord?.DisplayName,
			email: this.props.selectedRecord?.Email,
			mobile: this.props.selectedRecord?.Mobile,
			selectedRoles: this.props.selectedRecord?.Roles,
			isActive: this.props.selectedRecord === null ? true : this.props.selectedRecord?.IsActive,
		};

		this.state = {
			selectedRoles: this.props.selectedRecord !== null ? this.props.selectedRecord.Roles : [],
		};
		console.log(this.initialValues);
	}

	validationSchema = Yup.object().shape({
		userName: Yup.string().required("UserName is required"),
		password: this.props.selectedRecord != null ? Yup.string() : Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
		displayName: Yup.string().required("DisplayName is required"),
		email: Yup.string().required("Email is required").email("Invalid email address"),
		mobile: Yup.string()
			.required("Mobile is required")
			.matches(/^\d{10}$/, "Invalid mobile number"),
		isActive: Yup.boolean().required("Is Active is required"),
		selectedRoles: Yup.array().required("Role is required"),
	});

	handleSave = () => {
		if (this.userSubmitButtonRef !== null) {
			(this.userSubmitButtonRef as any).current.click();
		}
	};

	async readyToSave(data: any) {
		console.log(data);
		this.props.handleSave(data);
	}

	render() {
		return (
			<ModalPopup
				show={this.props.show}
				handleClose={this.props.handleClose}
				handleSave={this.handleSave}
				title={this.props.selectedRecord !== null ? "Edit User" : "Create User"}
				content={
					<Formik enableReinitialize initialValues={this.initialValues} validationSchema={this.validationSchema} onSubmit={this.readyToSave}>
						{({ setFieldValue }) => {
							return (
								<Form className="p-10">
									<div className="form-group">
										<label htmlFor="userName">User Name</label>
										<Field type="text" id="userName" name="userName" className="form-control" />
										<ErrorMessage name="userName" component="div" className="text-danger" />
									</div>
									<div className="form-group">
										<label htmlFor="password">Password</label>
										<Field type="password" id="password" name="password" className="form-control" />
										<ErrorMessage name="password" component="div" className="text-danger" />
									</div>
									<div className="form-group">
										<label htmlFor="displayName">Display Name</label>
										<Field type="text" id="displayName" name="displayName" className="form-control" />
										<ErrorMessage name="displayName" component="div" className="text-danger" />
									</div>
									<div className="form-group">
										<label htmlFor="email">Email</label>
										<Field type="text" id="email" name="email" className="form-control" />
										<ErrorMessage name="email" component="div" className="text-danger" />
									</div>
									<div className="form-group">
										<label htmlFor="mobile">Mobile</label>
										<Field type="text" id="mobile" name="mobile" className="form-control" />
										<ErrorMessage name="mobile" component="div" className="text-danger" />
									</div>
									<div className="form-check mt-5">
										<Field type="checkbox" id="isActive" name="isActive" className="form-check-input" />
										<label htmlFor="isActive" className="form-check-label">
											Is Active
										</label>
										<ErrorMessage name="isActive" component="div" className="text-danger" />
									</div>

									<div className="form-group pt-5">
										<label htmlFor="logo">Roles</label>
										<Select
											name="selectedRoles"
											id="selectedRoles"
											isMulti={true}
											options={this.props.roles}
											onChange={(e) => {
												console.log(e);
												this.setState({
													selectedRoles: e as any,
												});
												setFieldValue("selectedRoles", e);
											}}
											value={this.state.selectedRoles}
										></Select>
										<ErrorMessage name="selectedRoles" component="div" className="text-danger" />
									</div>

									<button type="submit" ref={this.userSubmitButtonRef} className="d-none"></button>
								</Form>
							);
						}}
					</Formik>
				}
			/>
		);
	}
}
