/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Navigate } from "react-router-dom";
import "../../Styles/tradviewscanleft.custom.css";

type State = {
	selectedFile: any;
	imageFiles: any;
	selectedImage: any;
	isShowDialog: boolean;
	redirect: any;
};

type Props = {
	imageFiles: any;
	selectedImage(imageFile: any): void;
};

export default class TRadViewScanLeft extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);

		this.state = {
			selectedFile: [],
			imageFiles: this.props.imageFiles,
			selectedImage: this.props.imageFiles[0],
			isShowDialog: false,
			redirect: null,
		};
	}

	handleImageClick = (index: number) => {
		const { imageFiles } = this.state;
		this.props.selectedImage(imageFiles[index]);
		this.setState({ selectedImage: imageFiles[index] });
	};

	async componentDidMount(): Promise<void> {
		const { imageFiles } = this.props;
		console.log(imageFiles);
	}

	render(): React.ReactNode {
		const { imageFiles } = this.state;
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		return (
			<>
				<div className="bg-secondary">
					<div className="d-flex float-end">
						<div className="d-flex flex-column align-items-center leftwrapper px-5 pb-50">
							{imageFiles.map((imageBlob: any, index: any) => (
								<div key={index} className={`d-flex flex-column align-items-center w-25 ${this.props.selectedImage === imageBlob ? "selected" : ""}`}>
									<div className="text-white" style={{ textAlign: "center", marginBottom: "2px" }}>{`${index + 1}`}</div>
									<img
										width="100px"
										src={URL.createObjectURL(imageBlob)}
										alt={`${index + 1}`}
										onClick={() => this.handleImageClick(index)}
										style={{
											border: this.state.selectedImage === imageBlob ? "1px solid white" : "none",
										}}
										className="rounded float-end cursor-pointer"
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</>
		);
	}
}
