import { Component } from "react";
import ModalPopup from "../Shared/ModalPopup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import TRadService from "../../Services/TRad/TRad.service";
import Select from "react-select";

type Props = {
	show: boolean;
	handleClose: () => void;
	handleSave: (data: any) => void;
	selectedPatientRecord: any;
};

type State = {
	selectedCliendId: any;
	patientData: any;
	initialValues: any;
};

export default class TRadPatientEditDialog extends Component<Props, State> {
	patientSubmitButtonRef: React.Ref<HTMLButtonElement>;

	constructor(props: Props) {
		super(props);

		this.state = {
			selectedCliendId: " ",
			patientData: [],
			initialValues: this.props.selectedPatientRecord,
		};

		this.readyToSave = this.readyToSave.bind(this);
		this.patientSubmitButtonRef = React.createRef<HTMLButtonElement>();
	}

	gender = [
		{ value: 0, label: "Male" },
		{ value: 1, label: "Female" },
		{ value: 2, label: "Other" },
	];

	componentDidMount() {}

	async onPatientSelected() {
		const { selectedPatientRecord } = this.props;
		if (selectedPatientRecord && selectedPatientRecord.PatientId) {
			try {
				const res = await TRadService.getPatientsByID(selectedPatientRecord.PatientId);
				if (res) {
					const patientData = res.data.response.data[0];

					const initialValues = {
						patientid: patientData.id,
						patientNumber: patientData.patientNo || "",
						patientFirstName: patientData.firstName || "",
						patientLastName: patientData.lastName || "",
						mobile: patientData.phone || "",
						age: patientData.age || "",
						email: patientData.email || "",
						address1: patientData.address || "",
						city: patientData.city || "",
						state: patientData.state || "",
						country: patientData.country || "",
						gender: patientData.gender || "",
					};
					this.setState({ initialValues });
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		}
	}

	validationSchema = Yup.object().shape({
		patientNumber: Yup.string().required("PatientId is required"),
		gender: Yup.string().required("Gender Name is required"),
		age: Yup.string().required("Age is required"),
		email: Yup.string().email("Invalid email address"),
		mobile: Yup.string().matches(/^\d{10}$/, "Invalid mobile number"),
	});

	// Inside TRadDialog component
	handleSave = () => {
		if (this.patientSubmitButtonRef !== null) {
			(this.patientSubmitButtonRef as any).current.click();
		}
	};

	readyToSave = (data: any) => {
		this.props.handleSave(data);
		this.props.handleClose();
	};

	render() {
		const { initialValues } = this.state;
		return (
			<div>
				<ModalPopup
					show={this.props.show}
					handleClose={this.props.handleClose}
					handleSave={this.handleSave}
					title={"Edit Patient"}
					content={
						<Formik initialValues={initialValues} validationSchema={this.validationSchema} onSubmit={this.readyToSave}>
							{({ setFieldValue }) => {
								return (
									<Form className="p-10">
										<Row></Row>
										<Row>
											<Col>
												<div className="form-group">
													<label htmlFor="patientId">Patient Id</label>
													<Field type="number" id="patientId" name="patientNumber" className="form-control" disabled />
													<ErrorMessage name="patientId" component="div" className="text-danger" />
												</div>
											</Col>

											<Col>
												<div className="form-group">
													<label htmlFor="patientFirstName">Patient First Name</label>
													<Field type="text" id="patientFirstName" name="patientFirstName" className="form-control" />
													<ErrorMessage name="patientFirstName" component="div" className="text-danger" />
												</div>
											</Col>

											<Col>
												<div className="form-group">
													<label htmlFor="patientLastName">Patient Last Name</label>
													<Field type="text" id="patientLastName" name="patientLastName" className="form-control" />
													<ErrorMessage name="patientLastName" component="div" className="text-danger" />
												</div>
											</Col>
										</Row>

										<Row>
											<Col>
												<div className="form-group">
													<label htmlFor="mobile">Phone</label>
													<Field type="text" id="mobile" name="mobile" className="form-control" />
													<ErrorMessage name="mobile" component="div" className="text-danger" />
												</div>
											</Col>

											<Col>
												<div className="form-group">
													<label htmlFor="age">Age</label>
													<Field type="number" id="age" name="age" className="form-control" />
													<ErrorMessage name="age" component="div" className="text-danger" />
												</div>
											</Col>
											<Col>
												<div className="form-group">
													<label htmlFor="email">Email</label>
													<Field type="text" id="email" name="email" className="form-control" />
													<ErrorMessage name="email" component="div" className="text-danger" />
												</div>
											</Col>
										</Row>

										<Row>
											<div className="form-group">
												<label htmlFor="address1">Address 1</label>
												<Field type="text" id="address1" name="address1" className="form-control" />
												<ErrorMessage name="address1" component="div" className="text-danger" />
											</div>
										</Row>

										<Row>
											<Col>
												<div className="form-group">
													<label htmlFor="city">City</label>
													<Field type="text" id="city" name="city" className="form-control" />
													<ErrorMessage name="city" component="div" className="text-danger" />
												</div>
											</Col>
											<Col>
												<div className="form-group">
													<label htmlFor="state">State</label>
													<Field type="text" id="state" name="state" className="form-control" />
													<ErrorMessage name="state" component="div" className="text-danger" />
												</div>
											</Col>
											<Col>
												<div className="form-group">
													<label htmlFor="country">Country</label>
													<Field type="text" id="country" name="country" className="form-control" />
													<ErrorMessage name="country" component="div" className="text-danger" />
												</div>
											</Col>
											<Col>
												<div className="form-group">
													<label htmlFor="country">Gender</label>
													<Select
														name="gender"
														id="gender"
														isMulti={false}
														options={this.gender}
														onChange={(e) => {
															setFieldValue("gender", e?.label);
														}}
														defaultValue={this.state.initialValues.gender ? { label: this.state.initialValues.gender } : null}
													></Select>
													<ErrorMessage name="gender" component="div" className="text-danger" />
												</div>
											</Col>
										</Row>

										<div></div>

										<button type="submit" ref={this.patientSubmitButtonRef} className="d-none"></button>
									</Form>
								);
							}}
						</Formik>
					}
				/>
			</div>
		);
	}
}
