/* eslint-disable array-callback-return */
import { Component } from "react";
import { Navigate } from "react-router-dom";
import TRadLayout from "../../Layouts/TRad/TRadLayout";
import Select from "react-select";
import GenericUserService from "../../Services/GenericUser.service";
import { AppConstants } from "../../Utils/AppConstants";
import TRadService from "../../Services/TRad/TRad.service";
import { DatatableWrapper, Filter, PaginationOptions, TableBody, TableColumnType, TableHeader, Pagination } from "react-bs-datatable";
import { Col, Row, Table } from "react-bootstrap";
import { FaEdit, FaPlus } from "react-icons/fa";
import { FaRegFloppyDisk, FaXmark } from "react-icons/fa6";
import MasterDataService from "../../Services/MasterData.service";
import { toast, ToastContainer } from "react-toastify";
type Props = {};

export default class TRadDefaultRadiologistAssignment extends Component<Props, any> {
	assignmentsTableHeaders: TableColumnType<any>[] = [
		{
			prop: "scanType",
			title: "Scan Type",
			isSortable: true,
			cell: (row) => {
				return (
					<div>
						{row.IsEdit && (
							<Select
								name="scanType"
								id="scanType"
								placeholder="Select Scan Type"
								isMulti={false}
								options={this.state.scanTypes}
								onChange={(selectedOption: any) => {
									row.scanType = selectedOption.label;
									row.scanTypeId = selectedOption.value;
									this.setState({
										assignments: this.state.assignments,
									});
								}}
								value={
									row.scanTypeId
										? {
												label: row.scanType,
												value: row.scanTypeId,
										  }
										: null
								}
								className="form-control p-0 border-0"
							/>
						)}
						{!row.IsEdit && <span>{row.scanType}</span>}
					</div>
				);
			},
		},
		{
			prop: "radiologists",
			title: "Radiologists",
			isSortable: true,
			cell: (row: any) => {
				return (
					<>
						<div>
							{row.IsEdit && (
								<Select
									name="radiologists"
									id="radiologists"
									placeholder="Select Radiologists"
									isMulti={true}
									options={this.state.radiologists.filter((x: any) => {
										return !row.radiologist.some((item2: any) => x.label === item2.label);
									})}
									onChange={(selectedOption: any) => {
										row.radiologist = selectedOption;
										this.setState({
											assignments: this.state.assignments,
										});
									}}
									value={row.radiologist}
									className="form-control p-0 border-0"
								/>
							)}
							{!row.IsEdit && <span>{row.radiologist.map((item: any) => item.label).join(", ")}</span>}
						</div>
					</>
				);
			},
		},
		{
			prop: "Action",
			title: "Action",
			isFilterable: false,
			cell: (row) => (
				<div className="d-flex">
					{!row.IsEdit && (
						<button
							className="btn btn-primary d-flex"
							onClick={() => {
								row.IsEdit = true;
								this.setState({
									assignments: this.state.assignments,
								});
							}}
						>
							<span className="text-white">
								<FaEdit />
							</span>
							<span className="ms-3 mt-1">Edit</span>
						</button>
					)}
					{row.IsEdit && (
						<>
							<button
								className="btn btn-success d-flex"
								onClick={() => {
									const array: any = [];

									row.radiologist.forEach((rad: any) => {
										array.push(rad.value);
									});
									const obj: any = {
										scanTypeId: row.scanTypeId,
										radiologists: array,
									};
									if (!row.isNew) {
										obj.assignmentId = row.assignmentId;
										this.updateAssignments(obj);
									} else {
										this.saveAssignments(obj);
									}
								}}
							>
								<span className="text-white">
									<FaRegFloppyDisk />
								</span>
								<span className="ms-3 mt-1">Save</span>
							</button>
							<button
								className="btn btn-warning d-flex ms-4 "
								onClick={() => {
									let { assignments } = this.state;
									if (row.isNew) {
										assignments = assignments.filter((item: any) => {
											return item.assignmentId !== row.assignmentId;
										});
									}
									row.IsEdit = !row.IsEdit;
									this.setState({
										assignments,
									});
									this.loadAssignments();
								}}
							>
								<span className="text-white">
									<FaXmark />
								</span>
								<span className="ms-3 mt-1 text-white">Cancel</span>
							</button>
						</>
					)}
				</div>
			),
		},
	];
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			scanningCenters: [],
			selectedCenterId: null,
			assignments: [],
			assignmentsCopy: [],
			scanTypes: [],
			radiologists: [],
			tableKey: Math.random(),
		};
	}

	getAllRadiologists = async () => {
		const res = await TRadService.getAllRadiologist();
		if (res?.response?.data) {
			const dropdown: any = [];
			res?.response?.data.map((x: any) => {
				dropdown.push({
					value: x.Id,
					label: x.UserName,
				});
				this.setState({
					radiologists: dropdown,
				});
			});
		}
	};

	async componentDidMount(): Promise<void> {
		this.getScanTypes();
		this.getAllRadiologists();
		const res = await GenericUserService.getUsersByType(AppConstants.ROLE_SCANNINGCENTRE);
		if (res.status === 200 && res.data.success) {
			const dropDown: any = [];
			res.data.results.map((item: any) => {
				dropDown.push({
					label: item.DisplayName,
					value: item.Id,
				});
			});
			this.setState({
				scanningCenters: dropDown,
				ready: true,
			});
		}
	}

	getScanTypes = async () => {
		const res = await MasterDataService.getByType(AppConstants.MASTER_TYPE_SCAN);
		if (res.status === 200 && res.data.success) {
			const dropDown: any = [];
			res.data.results.map((x: any) => {
				dropDown.push({
					label: x.Value,
					value: x.Id,
				});
			});
			this.setState({
				scanTypes: dropDown,
			});
		}
	};

	onScanningCenterClicked = (e: any) => {
		this.setState(
			{
				selectedCenterId: e.value,
			},
			() => this.loadAssignments()
		);
	};

	loadAssignments = async () => {
		const res = await TRadService.getDefaultRadiologistAssignments({ centerId: this.state.selectedCenterId });
		if (res.response.success) {
			const data = res.response.data.map((item: any) => ({ ...item }));
			this.setState({
				assignments: [...data],
				assignmentsCopy: [...data],
			});
		}
	};

	saveAssignments = async (data: any) => {
		data.centerId = this.state.selectedCenterId;
		if (!data.radiologists.length) {
			toast.error("Please select a radiologist");
			return;
		}
		if (data.scanTypeId === 0) {
			toast.error("Please select a ScanType");
			return;
		}
		const res = await TRadService.assignDefaultRadiologist(data);

		if (res.response.success) {
			toast.success("Radiologist Assigned");
			this.loadAssignments();
		} else {
			toast.error(res.response.message);
		}
	};

	updateAssignments = async (data: any) => {
		data.centerId = this.state.selectedCenterId;
		if (!data.radiologists.length) {
			toast.error("Please select a radiologist");
			return;
		}
		if (data.scanTypeId === 0) {
			toast.error("Please select a ScanType");
			return;
		}
		const res = await TRadService.updateDefaultRadiologist(data);

		if (res.response.success) {
			toast.success("Radiologist Updated");
			this.loadAssignments();
		} else {
			toast.error(res.response.message);
		}
	};

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<TRadLayout showMargin={true}>
				{this.state.ready && (
					<div className="mt-60 p-4">
						<div className="d-sm-flex align-items-center justify-content-between mb-12">
							<h1 className="h3 mb-0 text-gray-800">Manage Default Radiologist Assignments</h1>
						</div>
						<div className="d-flex">
							<label className="col-2 my-auto">Select Scanning Center</label>
							<div className="col-2">
								<Select name="client" value={this.state.selectedClientId} onChange={this.onScanningCenterClicked} options={this.state.scanningCenters}></Select>
							</div>
						</div>
						<DatatableWrapper
							body={this.state.assignments}
							headers={this.assignmentsTableHeaders}
							paginationOptionsProps={{
								initialState: {
									rowsPerPage: 10,
									options: [5, 10, 15, 20],
								},
							}}
							key={this.state.tableKey}
						>
							<Row className="mb-10">
								<Col className="col-3 d-flex flex-col justify-content-end align-items-end">
									<Filter />
								</Col>
								<Col className="d-flex justify-content-end align-items-end">
									<PaginationOptions />

									{this.state.selectedCenterId && (
										<button
											className="btn btn-primary d-flex ms-10"
											onClick={() => {
												const { assignments } = this.state;
												assignments.push({
													assignmentId: Math.random(),
													centerId: this.state.selectedCenterId,
													scanTypeId: 0,
													scanType: "",
													radiologist: [],
													IsEdit: true,
													isNew: true,
												});
												this.setState({
													assignments,
													tableKey: Math.random(),
												});
											}}
										>
											<span className="text-white">
												<FaPlus />
											</span>
											<span className="ms-3 mt-1">Create New</span>
										</button>
									)}
								</Col>
							</Row>
							<Table>
								<TableHeader />
								<TableBody />
							</Table>
							<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
								<Pagination />
							</Col>
						</DatatableWrapper>
					</div>
				)}
				<ToastContainer />
			</TRadLayout>
		);
	}
}
