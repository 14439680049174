import { Component } from "react";
import { Routes, Route } from "react-router-dom";
import UnAuthorized from "./Components/Auth/UnAuthorized.component";
import { PlatformRoutes } from "./Routes/PlatformRoutes";
import { TRadRoutes } from "./Routes/TRadRoutes.component";
import GeneralRoutes from "./Routes/GeneralRoutes.component";
import Loader from "./Utils/Loader";

import "./App.css";
import "./Styles/bootstrap.custom.scss";
import "react-toastify/dist/ReactToastify.css";
import "./Styles/loader.custom.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { InvRoutes } from "./Routes/InvRoutes.component";

type Props = {};

type State = {};

export default class App extends Component<Props, State> {
	componentDidMount() {}

	render() {
		return (
			<>
				<Loader></Loader>

				<GeneralRoutes></GeneralRoutes>
				<TRadRoutes></TRadRoutes>
				<PlatformRoutes></PlatformRoutes>
				<InvRoutes></InvRoutes>
				<Routes>
					<Route path="/403" element={<UnAuthorized />} />
				</Routes>
			</>
		);
	}
}
