import { Component } from "react";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import InvService from "../../Services/Inv/Inv.service";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaFileArchive } from "react-icons/fa";
import { FaBuildingColumns, FaDollarSign, FaPlus } from "react-icons/fa6";
import { Col, Row, Table } from "react-bootstrap";
import InvShopStockAddDialog from "./InvShopStockAddDialog.component";
import { toast, ToastContainer } from "react-toastify";
import InvShopStockAuditDialog from "./InvShopStockAuditDialog.component";
import ExportToExcel from "../Shared/ExportToExcel";
import moment from "moment";
import Select from "react-select";
import "../../Styles/invshopstocks.custom.css";
import "../../Styles/invTable.custom.css";
import AuthService from "../../Services/Auth/Auth.service";

type Props = {
	isShop: boolean;
};

type State = {
	redirect: string | null;
	items: Array<any>;
	ready: boolean;
	tableKey: number;
	reload: any;
	isShowDialog: boolean;
	selectedItem: any;
	isShowAuditDialog: boolean;
	shops: Array<any>;
	selectedShop: any;
	dialogIsQuantity: boolean;
	dialogIsRate: boolean;
	dialogIsVat: boolean;
	currentUser: any;
};

type itemType = {
	StockId: string;
	CategoryName: string;
	ItemId: string;
	ItemName: string;
	Rate: number;
	Quantity: number;
	Amount: number;
	VAT: number;
	TAX2: number;
	Action: any;
	CombinedName: any;
	ExpiryDate: any;
	BatchNumber: any;
};

export default class InvShopStocks extends Component<Props, State> {
	shopId: any = null;
	itemTableHeaders: TableColumnType<itemType>[] = [
		{
			prop: "CombinedName",
			title: "Item",
			isSortable: true,
			isFilterable: true,
		},
		{
			prop: "Rate",
			title: "Rate",
			isSortable: true,
		},
		{
			prop: "Quantity",
			title: "Quantity",
			isSortable: true,
		},
		{
			prop: "Action",
			title: "Action",
			cell: (row) => (
				<div className="d-flex gap-5">
					<button
						title="Update quantity"
						className="btn btn-primary d-flex"
						onClick={() => {
							this.setState(
								{
									selectedItem: row,
								},
								() => {
									this.setState({
										dialogIsQuantity: true,
										dialogIsRate: false,
										dialogIsVat: false,
										isShowDialog: true,
									});
								}
							);
						}}
					>
						<span className="text-white">
							<FaPlus />
						</span>
					</button>
					<button
						title="Update rate"
						className="btn btn-success d-flex"
						onClick={() => {
							this.setState(
								{
									selectedItem: row,
								},
								() => {
									this.setState({
										dialogIsQuantity: false,
										dialogIsRate: true,
										dialogIsVat: false,
										isShowDialog: true,
									});
								}
							);
						}}
					>
						<span className="text-white">
							<FaDollarSign />
						</span>
					</button>
					<button
						title="Update Tax"
						className="btn bg-orange-400 d-flex"
						onClick={() => {
							this.setState(
								{
									selectedItem: row,
								},
								() => {
									this.setState({
										dialogIsQuantity: false,
										dialogIsRate: false,
										dialogIsVat: true,
										isShowDialog: true,
									});
								}
							);
						}}
					>
						<span className="text-white">
							<FaBuildingColumns />
						</span>
					</button>
					<button
						title="View changes"
						className="btn btn-secondary d-flex"
						onClick={() => {
							this.setState(
								{
									selectedItem: row,
								},
								() => {
									this.setState({
										isShowAuditDialog: true,
									});
								}
							);
						}}
					>
						<span className="text-white">
							<FaFileArchive />
						</span>
					</button>
				</div>
			),
		},
	];

	constructor(props: Props) {
		super(props);
		this.state = {
			redirect: null,
			ready: false,
			tableKey: Math.random(),
			reload: Math.random(),
			items: [],
			isShowDialog: false,
			selectedItem: null,
			isShowAuditDialog: false,
			shops: [],
			selectedShop: null,
			dialogIsQuantity: false,
			dialogIsRate: false,
			dialogIsVat: false,
			currentUser: AuthService.getCurrentUser(),
		};

		this.loadValues = this.loadValues.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.onShopChange = this.onShopChange.bind(this);
	}

	async componentDidMount() {
		this.setHeaders();
		if (this.props.isShop === false) {
			const response = await InvService.getShops();
			const shops = new Array<any>();
			response.data.map((s: any) =>
				shops.push({
					label: s.Name,
					value: s.Id,
				})
			);
			this.setState({
				shops,
			});
		}
		await this.loadValues();
	}

	setHeaders = () => {
		if (this.state.currentUser.user.VatTax && this.state.currentUser.headers.TAX1) {
			this.itemTableHeaders.splice(3, 0, {
				prop: "VAT",
				title: this.state.currentUser.headers.TAX1,
				isSortable: true,
			});
		} else {
			if (this.state.currentUser.headers.TAX2)
				this.itemTableHeaders.splice(3, 0, {
					prop: "TAX2",
					title: this.state.currentUser.headers.TAX2,
					isSortable: true,
				});
			if (this.state.currentUser.headers.TAX1)
				this.itemTableHeaders.splice(3, 0, {
					prop: "VAT",
					title: this.state.currentUser.headers.TAX1,
					isSortable: true,
				});
		}
		if (this.state.currentUser.headers.ExpiryDate) {
			this.itemTableHeaders.splice(3, 0, {
				prop: "ExpiryDate",
				title: this.state.currentUser.headers.ExpiryDate,
				isSortable: true,
				cell: (row: any) => <div>{moment(row.ExpiryDate).format("DD/MM/YYYY")}</div>,
			});
		}
		if (this.state.currentUser.headers.BatchNumber) {
			this.itemTableHeaders.splice(3, 0, {
				prop: "BatchNumber",
				title: this.state.currentUser.headers.BatchNumber,
				isSortable: true,
			});
		}
	};

	async loadValues() {
		if (this.props.isShop === false && this.shopId === null) {
			return;
		}
		console.log(this.shopId);
		const resp = await InvService.getShopStock({
			ShopId: this.shopId,
		});
		this.setState({
			items: resp.data,
			ready: true,
			isShowDialog: false,
		});
	}

	async handleSave(data: any) {
		const req = {
			ShopId: this.shopId,
			StockId: this.state.selectedItem?.StockId,
			ItemId: this.state.selectedItem.ItemId,
			Quantity: data.quantity,
			Rate: data.rate,
			VAT: data.vat,
			TAX2: data.tax2,
			PurchaseId: data.purchase,
		};

		if (this.state.dialogIsQuantity) {
			const resp = await InvService.addShopStockQuantity(req);
			if (resp.data.success === false) {
				toast.error(resp.data.message);
			} else {
				toast.success("Item quantity updated successfully!");
				await this.loadValues();
			}
		} else if (this.state.dialogIsRate) {
			const resp = await InvService.addShopStockRate(req);
			if (resp.data.success === false) {
				toast.error(resp.data.message);
			} else {
				toast.success("Item rate updated successfully!");
				await this.loadValues();
			}
		} else if (this.state.dialogIsVat) {
			const resp = await InvService.addShopStockTax(req);
			if (resp.data.success === false) {
				toast.error(resp.data.message);
			} else {
				toast.success("Item tax updated successfully!");
				await this.loadValues();
			}
		}
	}

	async onShopChange(e: any) {
		this.shopId = e.value;
		this.setState(
			{
				selectedShop: e,
			},
			() => this.loadValues()
		);
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<>
					<div className="mt-55 px-10"></div>
					<div className="d-print-none mt-60">
						{this.props.isShop === false && (
							<div className="col-md-12 col-lg-6 pb-5">
								<Select placeholder="Select Shop" name="shops" value={this.state.selectedShop} onChange={this.onShopChange} options={this.state.shops}></Select>
							</div>
						)}
					</div>
					{this.state.ready && (
						<>
							<DatatableWrapper
								body={this.state.items}
								headers={this.itemTableHeaders}
								paginationOptionsProps={{
									initialState: {
										rowsPerPage: 50,
										options: [50, 100, 500, 1000],
									},
								}}
								key={this.state.tableKey}
							>
								<Row className="mb-10">
									<Col className="col-12 col-md d-flex flex-col justify-content-end align-items-end gap-5">
										<Filter placeholder="Search..." />
										<ExportToExcel data={this.state.items} fileName={`stock_report_${moment().format("DD-MM-YYYY-HH-mm")}`} sheetName="Stocks" fields={["CategoryName", "ItemName", "Rate", "VAT", "Quantity"]} />
									</Col>
									<Col className="d-flex justify-content-end align-items-end"></Col>
								</Row>
								<Table className="dataTable">
									<TableHeader />
									<TableBody />
								</Table>
								<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
									<PaginationOptions />
									<div className="ms-5" />
									<Pagination />
								</Col>
							</DatatableWrapper>
						</>
					)}
					{this.state.isShowDialog && (
						<InvShopStockAddDialog
							show={this.state.isShowDialog}
							itemName={this.state.selectedItem?.ItemName}
							itemId={this.state.selectedItem?.ItemId}
							handleSave={this.handleSave}
							handleClose={() => {
								this.setState({ isShowDialog: false });
							}}
							key={Math.random()}
							isQuantity={this.state.dialogIsQuantity}
							isRate={this.state.dialogIsRate}
							isVat={this.state.dialogIsVat}
							shopId={this.shopId}
							isVatUser={this.state.currentUser.user.VatTax}
						></InvShopStockAddDialog>
					)}
					{this.state.isShowAuditDialog && (
						<InvShopStockAuditDialog
							show={this.state.isShowAuditDialog}
							itemName={this.state.selectedItem?.ItemName}
							handleClose={() => {
								this.setState({ isShowAuditDialog: false });
							}}
							handleSave={() => {
								this.setState({ isShowAuditDialog: false });
							}}
							shopStockId={this.state.selectedItem?.StockId}
							key={Math.random()}
						></InvShopStockAuditDialog>
					)}
					<ToastContainer />
				</>
			</InvLayout>
		);
	}
}
