/* eslint-disable array-callback-return */
import { Component } from "react";
import { Navigate } from "react-router-dom";
import AppLayout from "../../Layouts/App/AppLayout";
import AuthService from "../../Services/Auth/Auth.service";
import HomeCard from "./Home.Card.component";
import { AppConstants } from "../../Utils/AppConstants";

type Props = {};
type State = {
	redirect: string | null;
	ready: boolean;
	currentUser: any | null;
	applications: any | null;
};

export default class Home extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			currentUser: null,
			applications: [],
		};
	}

	componentDidMount(): void {
		const currentUser = AuthService.getCurrentUser();

		if (currentUser.applications.length === 1) {
			this.setState({
				redirect: currentUser.applications[0].Route,
			});
		} else {
			const { applications } = this.state;
			const userRolesArray = currentUser.user.Roles.split(",");

			if (currentUser.user.Roles.indexOf(AppConstants.ROLE_CLIENTADMIN) > -1) {
				currentUser.applications.map((x: any) => {
					applications.push(x);
				});
			} else if (userRolesArray.some((role: any) => AppConstants.TRAD_ROLES.includes(role.trim()))) {
				currentUser.applications.map((x: any) => {
					if (x.Name === AppConstants.APP_TRAD) {
						applications.push(x);
					}
				});
			} else if (userRolesArray.some((role: any) => AppConstants.INV_ROLES.includes(role.trim()))) {
				currentUser.applications.map((x: any) => {
					if (x.Name === AppConstants.APP_INVOICE) {
						applications.push(x);
					}
				});
			}
			this.setState({ currentUser, ready: true, applications });
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<AppLayout>
				<>
					<div className="mt-50">
						<div className="row">
							{this.state.ready && this.state.currentUser !== null && (
								<>
									{this.state.currentUser.user.Roles.indexOf("PlatformAdmin") > -1 && <h1>Hello, {this.state.currentUser.user.Name} !</h1>}
									{this.state.applications.map((m: any, idx: number) => {
										return <HomeCard key={"homecard_" + idx} appName={m.Name} route={m.Route} colorIndex={idx} />;
									})}
								</>
							)}
						</div>
					</div>
				</>
			</AppLayout>
		);
	}
}
