import { Component } from "react";
import { Badge, Col, Row, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import AuthService from "../../Services/Auth/Auth.service";
import GenericUserService from "../../Services/GenericUser.service";
import { FaEdit } from "react-icons/fa";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaPlus } from "react-icons/fa6";
import GenericUserDialog from "./GenericUserDialog.component";
import { toast, ToastContainer } from "react-toastify";
import { AppConstants } from "../../Utils/AppConstants";
import "../../Styles/invTable.custom.css";

type Props = {
	role: string;
	shopId?: string;
};
type State = {
	redirect: string | null;
	ready: boolean;
	currentUser: any | null;
	users: Array<any>;
	isShowDialog: boolean;
	isShowDefaultAssignment: boolean;
	selectedRecord: any | null;
};

type userType = {
	Id: string;
	UserName: string;
	DisplayName: string;
	Email: string;
	Mobile: string;
	Address1: string;
	Address2: string;
	City: string;
	State: string;
	Country: string;
	ZipCode: string;
	Latitude: string;
	Longitude: string;
	Signature?: any;
	defaultRadiologist?: any;
	RegistrationNumber?: any;
	EducationQualification?: any;
	IsActive: number;
	Roles: Array<string>;
	Action: any;
};

export default class GenericUsers extends Component<Props, State> {
	userTableHeaders: TableColumnType<userType>[] = [
		{
			prop: "UserName",
			title: "User Name",
			isSortable: true,
		},
		{
			prop: "DisplayName",
			title: "Display Name",
			isSortable: true,
		},
		{
			prop: "Email",
			title: "Email",
			isSortable: true,
		},
		{
			prop: "Mobile",
			title: "Mobile",
			isSortable: true,
		},
		{
			prop: "City",
			title: "City",
			isSortable: true,
		},
		{
			prop: "IsActive",
			title: "Active",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsActive === 1 && <Badge bg="success">Yes</Badge>}
					{row.IsActive === 0 && <Badge bg="danger">No</Badge>}
				</div>
			),
		},
		{
			prop: "Action",
			title: "Action",
			isFilterable: false,
			cell: (row) => (
				<div className="d-flex">
					<button
						className="btn btn-primary d-flex"
						onClick={() =>
							this.setState({
								selectedRecord: row,
								isShowDialog: true,
							})
						}
					>
						<span className="text-white">
							<FaEdit />
						</span>
						<span className="ms-3 mt-1">Edit</span>
					</button>
				</div>
			),
		},
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			currentUser: null,
			users: [],
			isShowDialog: false,
			selectedRecord: null,
			isShowDefaultAssignment: false,
		};

		if (this.props.role === AppConstants.ROLE_RADIOLOGIST) {
			this.userTableHeaders.splice(
				this.userTableHeaders.length - 2,
				0,
				{
					prop: "RegistrationNumber",
					title: "Reg No",
					isSortable: true,
				},
				{
					prop: "EducationQualification",
					title: "Qualification",
					isSortable: true,
				}
			);
		}

		this.handleSave = this.handleSave.bind(this);
	}

	async componentDidMount() {
		const currentUser = AuthService.getCurrentUser();
		this.setState(
			{
				currentUser,
			},
			async () => await this.loadUsers()
		);
	}

	async loadUsers() {
		const users = await GenericUserService.getUsersByType(this.props.role, this.props.shopId);
		if (users.status === 200 && users.data.success) {
			this.setState({
				users: users.data.results,
				ready: true,
			});
		}
	}

	async handleSave(data: any) {
		const req = {
			UserName: data.userName,
			DisplayName: data.displayName,
			Password: data.password || null,
			Email: data.email,
			Mobile: data.mobile,
			Address1: data.address1,
			Address2: data.address2,
			City: data.city,
			State: data.state,
			Country: data.country,
			ZipCode: data.zipCode,
			Role: this.props.role,
			IsActive: data.isActive,
			Latitude: data.latitude,
			Longitude: data.longitude,
			Signature: data.signature || null,
			EducationQualification: data.educationQualification,
			RegistrationNumber: data.registrationNumber,
			ShopId: this.props.shopId,
		};
		console.log(req);
		if (this.state.selectedRecord === null) {
			var res = await GenericUserService.create(req);
			if (res.status === 200) {
				if (res.data.success) {
					toast.success("User created successfully");
					this.setState(
						{
							isShowDialog: false,
						},
						async () => await this.loadUsers()
					);
				} else toast.error(res.data.errorMessage[0]);
			}
		} else {
			(req as any).Id = this.state.selectedRecord.Id;
			var re = await GenericUserService.update(req);
			if (re.status === 200) {
				if (re.data.success) {
					toast.success("User updated successfully");
					this.setState(
						{
							isShowDialog: false,
						},
						async () => await this.loadUsers()
					);
				} else toast.error(re.data.errorMessage[0]);
			}
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<div className="px-10">
				{this.state.ready && this.state.currentUser !== null && (
					<DatatableWrapper
						body={this.state.users}
						headers={this.userTableHeaders}
						paginationOptionsProps={{
							initialState: {
								rowsPerPage: 10,
								options: [5, 10, 15, 20],
							},
						}}
					>
						<Row className="mb-10">
							<Col className="col-3 d-flex flex-col justify-content-end align-items-end">
								<Filter />
							</Col>
							<Col className="d-flex justify-content-end align-items-end">
								<button
									className="btn btn-primary d-flex ms-10"
									onClick={() =>
										this.setState({
											selectedRecord: null,
											isShowDialog: true,
										})
									}
								>
									<span className="text-white">
										<FaPlus />
									</span>
									<span className="ms-3 mt-1">Create New</span>
								</button>
							</Col>
						</Row>
						<Table className="dataTable">
							<TableHeader />
							<TableBody />
						</Table>
						<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
							<PaginationOptions />
							<div className="ms-5" />
							<Pagination />
						</Col>
					</DatatableWrapper>
				)}
				<GenericUserDialog
					show={this.state.isShowDialog}
					selectedRecord={this.state.selectedRecord}
					handleSave={this.handleSave}
					handleClose={() => {
						this.setState({ isShowDialog: false });
					}}
					key={Math.random()}
					type={this.props.role}
				></GenericUserDialog>
				<ToastContainer />
			</div>
		);
	}
}
