import { Component } from "react";
import TRadService from "../../Services/TRad/TRad.service";
import ReactModal from "react-modal-resizable-draggable";
import "../../Styles/tradreportdialog.custom.css";

type Props = {
	isEdit: boolean;
	recordId: string;
	show: boolean;
	handleClose: () => void;
	handleSave: (data: any) => void;
	handleSubmit: (data: any) => void;
	savedReport: any;
};

export default class TRadReportDialog extends Component<Props, any> {
	constructor(props: Props) {
		super(props);

		this.state = {
			updateReport: "",
			modalIsOpen: true,
		};
	}

	componentDidMount(): void {
		console.log(this.props.show);
		this.setState({
			updateReport: this.props.savedReport,
		});
	}
	getScanIdFromURL() {
		const currentURL = window.location.href;
		const url = new URL(currentURL);
		const id = url.searchParams.get("id");
		return id;
	}

	savedReport() {
		const scanId = this.getScanIdFromURL();
		try {
			const respons: any = TRadService.downloadFileById(scanId);
			this.setState({
				updateReport: respons.data.data.report,
			});
		} catch (error) {
			console.error("Error:", error);
		}
	}

	handleSave = async () => {
		this.props.handleSave(this.state.updateReport);
		this.props.handleClose();
		this.savedReport();
	};

	handleSubmit = () => {
		const trimmedReport = this.state.updateReport.trim();
		if (trimmedReport !== "") {
			this.props.handleSubmit(trimmedReport);
			this.setState({ validationError: "" });
		} else {
			this.setState({
				validationError: "Please enter your observations before submitting.",
			});
		}
	};

	render(): React.ReactNode {
		const modalProps: any = {
			initWidth: 800,
			initHeight: 400,
			className: "my-modal-custom-class",
			isOpen: this.props.show,
			onclose: this.props.handleClose,
		};

		return (
			<div className="App">
				<ReactModal {...modalProps} minWidth={300} minHeight={400} disableVerticalResize>
					<h3 className="p-3 w-100 bg-primary text-light rounded-top p-lg-5 ">Enter Observations</h3>
					<div className="body p-lg-3 rounded-bottom">
						<textarea
							value={this.state.updateReport}
							onChange={(e) =>
								this.setState({
									updateReport: e.target.value,
									validationError: "",
								})
							}
							className="p-3 rounded"
							style={{ width: "100%", height: "290px" }}
							placeholder="Type your observations here"
						></textarea>
					</div>
					<div className="button-container">
						<button className="btn btn-secondary" onClick={this.props.handleClose}>
							Close
						</button>
						<button className="btn btn-primary" onClick={this.handleSave}>
							Save
						</button>
						<button className="btn btn-primary p-3" onClick={this.handleSubmit}>
							Submit
						</button>
					</div>
				</ReactModal>
			</div>
		);
	}
}
