import api from "../../Middleware/ApiClient";

export default class PlatformService {
	static getClients() {
		return api.post("/platform/clients", {});
	}

	static createClient(data: any) {
		return api.post("/platform/createclient", data);
	}

	static updateClient(data: any) {
		return api.post("/platform/updateClient", data);
	}

	static deleteClient(data: any) {
		return api.post("/platform/deleteClient", data);
	}

	static getApps() {
		return api.post("/platform/apps", {});
	}

	static createApp(data: any) {
		return api.post("/platform/createapp", data);
	}

	static updateApp(data: any) {
		return api.post("/platform/updateapp", data);
	}

	static getClientUsers(data: any) {
		return api.post("/platform/clientusers", data);
	}

	static impersonateUser(data: any) {
		return api.post("/platform/impersonateUser", data);
	}

	static getRoles() {
		return api.post("/platform/clientuserRoles");
	}

	static createUser(data: any) {
		return api.post("/platform/createclientuser", data);
	}

	static updateUser(data: any) {
		return api.post("/platform/updateclientuser", data);
	}
}
