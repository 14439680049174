/* eslint-disable array-callback-return */
import { Component } from "react";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import InvService from "../../Services/Inv/Inv.service";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaEdit } from "react-icons/fa";
import { FaRegFloppyDisk, FaXmark, FaPlus } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import { Col, Row, Table } from "react-bootstrap";
import Select from "react-select";
import "../../Styles/invitems.custom.css";
import "../../Styles/invTable.custom.css";
import AuthService from "../../Services/Auth/Auth.service";
import moment from "moment";

type Props = {};

type State = {
	redirect: string | null;
	items: Array<any>;
	selectedItem: any;
	ready: boolean;
	tableKey: number;
	selectedCategories: any;
	categories: Array<any>;
	reload: any;
	currentUser: any;
};

type itemType = {
	Name: string;
	BarCode: string;
	SKU: string;
	HSNCode: any;
	BatchNumber: any;
	IsEdit: boolean;
	Action: any;
	ShopQuantity: number;
	StoreQuantity: number;
	TotalQuantity: number;
	ShopQuantitiesHTML: any;
	CategoryId: string;
	NewRandomId: any;
	TotalAvailable: number;
	CategoryName: any;
	ExpiryDate: any;
};

export default class InvItems extends Component<Props, State> {
	itemTableHeaders: TableColumnType<itemType>[] = [
		{
			prop: "Name",
			title: "Item Name",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Item Name</div>}
					<div>
						{row.IsEdit && (
							<div>
								<input
									value={row.Name}
									className="form-control"
									onChange={(e) => {
										row.Name = e.target.value;
										this.setState({
											items: this.state.items,
										});
									}}
								/>
							</div>
						)}
						{!row.IsEdit && <span>{row.Name}</span>}
					</div>
				</>
			),
		},

		{
			prop: "BarCode",
			title: "BarCode",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>BarCode</div>}
					<div>
						{row.IsEdit && (
							<div>
								<input
									value={row.BarCode}
									className="form-control"
									onChange={(e) => {
										row.BarCode = e.target.value;
										this.setState({
											items: this.state.items,
										});
									}}
								/>
							</div>
						)}
						{!row.IsEdit && <span>{row.BarCode}</span>}
					</div>
				</>
			),
		},

		{
			prop: "StoreQuantity",
			title: "Store Quantity",
			isSortable: true,
			cellProps: {
				className: (row) => (Number(row.StoreQuantity) === 0 ? "bg-red-100" : ""),
			},
			cell: (row) => (
				<>
					{row.IsEdit && <div>Store Quantity</div>}
					<div>{row.StoreQuantity}</div>
				</>
			),
		},
		{
			prop: "ShopQuantity",
			title: "Shop Quantity",
			isSortable: true,
			cellProps: {
				className: (row) => (Number(row.ShopQuantity) === 0 ? "bg-red-100" : ""),
			},
			cell: (row) => (
				<>
					{row.IsEdit && <div>Shop Quantity</div>}
					<div>{row.ShopQuantity}</div>
				</>
			),
		},
		{
			prop: "ShopQuantitiesHTML",
			title: "By Shop",
			cell: (row) => (
				<>
					{row.IsEdit && <div>By Shop</div>}
					<div dangerouslySetInnerHTML={{ __html: row.ShopQuantitiesHTML }} />
				</>
			),
		},
		{
			prop: "TotalQuantity",
			title: "Total Quantity",
			isSortable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Total Quantity</div>}
					<div>{row.TotalQuantity}</div>
				</>
			),
		},
		{
			prop: "CategoryName",
			title: "Category",
			isSortable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Category</div>}
					<div>{row.CategoryName}</div>
				</>
			),
		},
		{
			prop: "Action",
			title: "Action",
			isFilterable: false,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Action</div>}
					<div className="d-flex">
						{!row.IsEdit && (
							<button
								className="btn btn-primary d-flex"
								onClick={() => {
									row.IsEdit = !row.IsEdit;
									this.setState({
										items: this.state.items,
									});
								}}
							>
								<span className="text-white">
									<FaEdit />
								</span>
								<span className="ms-3 mt-1">Edit</span>
							</button>
						)}
						{row.IsEdit && (
							<>
								<button
									className="btn btn-success d-flex"
									onClick={() => {
										this.saveValue(row);
									}}
								>
									<span className="text-white">
										<FaRegFloppyDisk />
									</span>
									<span className="ms-3 mt-1">Save</span>
								</button>
								<button
									className="btn btn-warning d-flex ms-4 "
									onClick={() => {
										row.IsEdit = !row.IsEdit;
										this.setState({
											items: this.state.items.filter((x) => x.NewRandomId === undefined || x.NewRandomId !== row.NewRandomId),
										});
									}}
								>
									<span className="text-white">
										<FaXmark />
									</span>
									<span className="ms-3 mt-1 text-white">Cancel</span>
								</button>
							</>
						)}
					</div>
				</>
			),
		},
	];
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			items: [],
			selectedItem: null,
			selectedCategories: [],
			ready: false,
			tableKey: Math.random(),
			categories: [],
			reload: Math.random(),
			currentUser: AuthService.getCurrentUser(),
		};

		this.onCategoryChange = this.onCategoryChange.bind(this);
		this.loadValues = this.loadValues.bind(this);
	}

	async componentDidMount() {
		const response = await InvService.getCategories();
		const categories = new Array<any>();
		response.data.map((s: any) =>
			categories.push({
				label: s.Name,
				value: s.Id,
			})
		);
		this.setState({
			categories,
			ready: true,
		});
		this.loadValues();
		this.setHeaders();
	}

	setHeaders = () => {
		const { currentUser } = this.state;
		if (currentUser.headers.SKU) {
			this.itemTableHeaders.splice(2, 0, {
				prop: "SKU",
				title: currentUser.headers.SKU,
				isSortable: true,
				isFilterable: true,
				cell: (row) => (
					<>
						{row.IsEdit && <div>{currentUser.headers.SKU}</div>}
						<div>
							{row.IsEdit && (
								<div>
									<input
										value={row.SKU}
										className="form-control"
										onChange={(e) => {
											row.SKU = e.target.value;
											this.setState({
												items: this.state.items,
											});
										}}
									/>
								</div>
							)}
							{!row.IsEdit && <span>{row.SKU}</span>}
						</div>
					</>
				),
			});
		}
		if (currentUser.headers.HSNCode) {
			this.itemTableHeaders.splice(2, 0, {
				prop: "HSNCode",
				title: currentUser.headers.HSNCode,
				isSortable: true,
				isFilterable: true,
				cell: (row) => (
					<>
						{row.IsEdit && <div>{currentUser.headers.HSNCode}</div>}
						<div>
							{row.IsEdit && (
								<div>
									<input
										value={row.HSNCode}
										className="form-control"
										onChange={(e) => {
											row.HSNCode = e.target.value;
											this.setState({
												items: this.state.items,
											});
										}}
									/>
								</div>
							)}
							{!row.IsEdit && <span>{row.HSNCode}</span>}
						</div>
					</>
				),
			});
		}
		if (currentUser.headers.BatchNumber) {
			this.itemTableHeaders.splice(2, 0, {
				prop: "BatchNumber",
				title: currentUser.headers.BatchNumber,
				isSortable: true,
				isFilterable: true,
				cell: (row) => (
					<>
						{row.IsEdit && <div>{currentUser.headers.BatchNumber}</div>}
						<div>
							{row.IsEdit && (
								<div>
									<input
										value={row.BatchNumber}
										className="form-control"
										onChange={(e) => {
											row.BatchNumber = e.target.value;
											this.setState({
												items: this.state.items,
											});
										}}
									/>
								</div>
							)}
							{!row.IsEdit && <span>{row.BatchNumber}</span>}
						</div>
					</>
				),
			});
		}
		if (currentUser.headers.ExpiryDate) {
			this.itemTableHeaders.splice(2, 0, {
				prop: "ExpiryDate",
				title: currentUser.headers.ExpiryDate,
				isSortable: true,
				isFilterable: true,
				cell: (row) => (
					<>
						{row.IsEdit && <div>{currentUser.headers.ExpiryDate}</div>}
						<div>
							{row.IsEdit && (
								<div>
									<input
										type="date"
										value={row.ExpiryDate}
										className="form-control"
										onChange={(e) => {
											row.ExpiryDate = e.target.value;
											this.setState({
												items: this.state.items,
											});
										}}
									/>
								</div>
							)}
							{!row.IsEdit && <span>{row.ExpiryDate ? moment(row.ExpiryDate).format("DD-MM-YYYY") : ""}</span>}
						</div>
					</>
				),
			});
		}
	};

	async loadValues() {
		if (this.state.selectedCategories === null || this.state.selectedCategories.length === 0) return;

		const selectedCategories = this.state.selectedCategories.map((c: any) => c.value).join(",");
		const response = await InvService.getItems({ categoryId: selectedCategories });
		this.setState({
			items: response.data,
			ready: true,
		});
	}

	async saveValue(data: any) {
		if (data.Name === "" || data.Name === "") {
			toast.error("Please enter name");
		} else if (data.SKU === "" || data.SKU === "") {
			toast.error("Please enter SKU");
		} else if (data.Price === "" || data.Price === null) {
			toast.error("Please enter Price");
		} else {
			const categoryId = data.CategoryId ?? this.state.selectedCategories[0].value;
			const res = await InvService.createOrUpdateItem({
				Id: data.Id,
				Name: data.Name,
				SKU: data.SKU,
				BarCode: data.BarCode,
				CategoryId: categoryId,
				HSNCode: data.HSNCode,
				BatchNumber: data.BatchNumber,
				ExpiryDate: data.ExpiryDate,
			});
			if (res.data.success) {
				toast.success(res.data.message);
				this.loadValues();
			} else {
				if (res.data.errorMessage.length) {
					res.data.errorMessage.map((x: any) => {
						toast.error(x);
					});
				}
			}
		}
	}

	onCategoryChange(e: any) {
		if (e === null || e.length === 0) {
			this.setState({
				items: [],
			});
		}
		this.setState(
			{
				selectedCategories: e,
				reload: Math.random(),
				ready: true,
			},
			() => this.loadValues()
		);
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<>
					<div className="mt-50 px-10">
						<div className="row">
							<div className="col-12">
								<h4 className="h3 mb-0 text-gray-800">Manage items</h4>
								<p>A place to manage all items</p>
							</div>
						</div>
					</div>
					{this.state.ready && (
						<DatatableWrapper
							body={this.state.items}
							headers={this.itemTableHeaders}
							paginationOptionsProps={{
								initialState: {
									rowsPerPage: 10,
									options: [5, 10, 15, 20],
								},
							}}
							key={this.state.tableKey}
						>
							<Row className="mb-10">
								<Col className="col-12 col-md d-flex flex-col justify-content-end align-items-end mb-3 mb-md-0">
									<Select isMulti={true} placeholder="Select Category" className="w-100" name="client" value={this.state.selectedCategories} onChange={this.onCategoryChange} options={this.state.categories}></Select>
								</Col>
								<Col className="col-12 col-md d-flex flex-col justify-content-end align-items-end mb-3 mb-md-0">
									<Filter />
								</Col>
								<Col className="col-12 col-md d-flex justify-content-end align-items-end ">
									{this.state.selectedCategories !== null && this.state.selectedCategories.length === 1 && (
										<button
											className="btn btn-primary d-flex ms-10"
											onClick={() => {
												const items = this.state.items;
												items.push({
													Id: undefined,
													Name: "",
													StoreQuantity: 0,
													ShopQuantity: 0,
													IsEdit: true,
													NewRandomId: Math.random(),
												});
												this.setState({
													items,
													tableKey: Math.random(),
												});
											}}
										>
											<span className="text-white">
												<FaPlus />
											</span>
											<span className="ms-3 mt-1">Create New</span>
										</button>
									)}
								</Col>
							</Row>
							<Table className="dataTable">
								<TableHeader />
								<TableBody />
							</Table>
							<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
								<PaginationOptions />
								<div className="ms-5">
									<Pagination />
								</div>
							</Col>
						</DatatableWrapper>
					)}
					<ToastContainer />
				</>
			</InvLayout>
		);
	}
}
