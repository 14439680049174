import { Component } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import AuthService from "../../Services/Auth/Auth.service";
import { Navigate } from "react-router-dom";
import "../../Styles/appheader.layout.css";
import { FaHome } from "react-icons/fa";
import NavHeaderRight from "../Shared/NavHeaderRight";
import { AppConstants } from "../../Utils/AppConstants";

type Props = {
	currentUser: any;
};
type State = {
	redirect: string | null;
	userRoles: string | null;
	links: Array<any>;
};

export default class TRadHeader extends Component<Props, State> {
	allLinks = [
		{
			title: "Home",
			route: "/tradhome",
			roles: [AppConstants.ROLE_CLIENTADMIN],
		},
		{
			title: "Loading",
			route: "/tradloading",
			roles: [AppConstants.ROLE_CLIENTADMIN, AppConstants.ROLE_SCANNINGCENTRE],
		},
		{
			title: "New Scans",
			route: "/tradnewscans",
			roles: [AppConstants.ROLE_CLIENTADMIN],
		},
		{
			title: "Under Review",
			route: "/tradunderreview",
			roles: [AppConstants.ROLE_RADIOLOGIST, AppConstants.ROLE_CLIENTADMIN],
		},
		{
			title: "Completed",
			route: "/tradcompleted",
			roles: [AppConstants.ROLE_RADIOLOGIST, AppConstants.ROLE_CLIENTADMIN, AppConstants.ROLE_SCANNINGCENTRE],
		},
	];
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			userRoles: null,
			links: [],
		};
	}

	componentDidMount(): void {
		const currentUser = AuthService.getCurrentUser();
		const userRoles = currentUser.user.Roles.split(",");
		const links = this.allLinks.filter((x: any) => {
			return userRoles.some((role: any) => x.roles.includes(role));
		});

		this.setState({ userRoles, links });
	}

	getActiveClass(route: any) {
		const w = window.location;

		if (w.href.indexOf(route) > -1) {
			return "active";
		}

		return "";
	}

	render() {
		const { userRoles } = this.state;
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		return (
			<>
				<Navbar fixed="top" bg="primary" expand="lg" className="ps-10 pe-10 pt-10 pb-10">
					<Navbar.Brand href="/home" className="text-white">
						<FaHome title="Home" className="text-white fs-5 " />
					</Navbar.Brand>
					<Navbar.Brand href="/tradhome" className="text-white">
						TeleRadiology
					</Navbar.Brand>
					<Navbar.Toggle className="text-white" aria-controls="navbarScroll" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto text-white" navbarScroll>
							{this.state.links &&
								this.state.links.length > 0 &&
								this.state.links.map((l: any) => {
									return (
										<Nav.Item key={Math.random()}>
											<Nav.Link key={Math.random()} href={l.route} className={"text-white fs-6 fw-semibold " + this.getActiveClass(l.route)}>
												{l.title}
											</Nav.Link>
										</Nav.Item>
									);
								})}
							{userRoles && userRoles.includes(AppConstants.ROLE_CLIENTADMIN) && (
								<NavDropdown title="Settings" className={this.getActiveClass("settings")}>
									<NavDropdown.Item href="/settings/tradscanningcentres" className="p-5">
										Manage Scanning Centres
									</NavDropdown.Item>
									<NavDropdown.Item href="/settings/tradmanagerads" className="p-5">
										Manage Radiologists
									</NavDropdown.Item>
									<NavDropdown.Item href="/settings/scantypes" className="p-5">
										Manage Scan Types
									</NavDropdown.Item>
									<NavDropdown.Item href="/settings/studynames" className="p-5">
										Manage Study Names
									</NavDropdown.Item>
									<NavDropdown.Item href="/settings/defaultRadiologistAssignment" className="p-5">
										Manage Default Radiologist Assignment
									</NavDropdown.Item>
								</NavDropdown>
							)}
						</Nav>
						<NavHeaderRight />
					</Navbar.Collapse>
				</Navbar>
			</>
		);
	}
}
