import api from "../../Middleware/ApiClient";

export default class TRadService {
	static downloadFileById(id: any) {
		const headers = {
			scanid: id,
		};

		// Make a POST request to download the file
		return api
			.post("/teleradiology/getScanById", null, {
				headers,
				// responseType: "blob", // Specify the response type as 'blob'
			})
			.then((response) => {
				if (response.status === 200) {
					// Create a Blob object and download the file
					return response;
				} else {
					console.log(response);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	static getAllRadiologist() {
		return api
			.post("/teleradiology/getAllRadiologist", null)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static saveScanReport(data: any) {
		return api
			.post("/teleradiology/addScanReport", data)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static assignRadiologist(data: any) {
		return api
			.post("/teleradiology/assignRadiologist", data)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static getScanByStatus(status: any) {
		return api
			.post("/teleradiology/getScanByStatus", status)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static getDropdownsByField(data: any) {
		return api
			.post("/teleradiology/getDropdownsByField", data)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static updateScanFields(data: any) {
		return api
			.post("/teleradiology/updateScanFields", data)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static assignDefaultRadiologist(data: any) {
		return api
			.post("/teleradiology/assignDefaultRadiologist", data)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static updateDefaultRadiologist(data: any) {
		return api
			.post("/teleradiology/updateDefaultRadiologist", data)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static getScanDocument(data: any) {
		return api
			.post("/teleradiology/getScanDocument", data)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static getDefaultRadiologistAssignments(data: any) {
		return api
			.post("/teleradiology/getDefaultRadiologistAssignments", data)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static getScanDocuments(data: any) {
		return api
			.post("/teleradiology/downloadFile", data)
			.then((response) => {
				if (response.status === 200 && response.data) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static addHistoryforScan(data: any) {
		console.log(data);
		const formData = new FormData();
		formData.append("scanId", data.scanId);
		if (data.scanTypeId) {
			formData.append("scanTypeId", data.scanTypeId);
		}
		if (data.studyTypeId) {
			formData.append("studyTypeId", data.studyTypeId);
		}
		if (data.description) {
			formData.append("description", data.description);
		}
		data.attachments.forEach((fileObject: any) => {
			formData.append(`file`, fileObject.file);
			formData.append(`fileName[]`, fileObject.fileName);
		});
		return api
			.post("/teleradiology/addScanHistory", formData, {
				headers: {
					"content-type": "multipart/form-data",
				},
			})
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static editHistoryforScan(data: any) {
		const formData = new FormData();
		if (data.scanTypeId) {
			formData.append("scanTypeId", data.scanTypeId);
		}
		if (data.studyTypeId) {
			formData.append("studyTypeId", data.studyTypeId);
		}
		if (data.description) {
			formData.append("description", data.description);
		}
		formData.append("historyId", data.id);
		data.attachments.forEach((fileObject: any) => {
			if (!fileObject.fileId) {
				formData.append(`file`, fileObject.file);
				formData.append(`fileName`, fileObject.fileName);
			} else if (fileObject.fileId) {
				formData.append("historyFiles[]", fileObject.fileId);
			}
		});

		return api
			.post("/teleradiology/editScanHistory", formData, {
				headers: {
					"content-type": "multipart/form-data",
				},
			})
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static getScanHistory(data: any) {
		return api
			.post("/teleradiology/getScanHistory", data)
			.then((response) => {
				if (response.status === 200 && response.data) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static downloadFile(data: any) {
		return api
			.post("/teleradiology/downloadFile", data)
			.then((response) => {
				if (response.status === 200 && response.data) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}
	static addDocument(data: any) {
		const formData = new FormData();
		formData.append("avatar", data.file);
		formData.append("scanId", data.scanId);
		formData.append("fileName", data.fileName);
		return api
			.post("/teleradiology/uploadPatientDocuments", formData, {
				headers: {
					"content-type": "multipart/form-data",
				},
			})
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static addNewScan(data: any) {
		const formData = new FormData();
		formData.append("avatar", data.imageInput);
		formData.append("PatientNumber", data.patientId);
		formData.append("TeleRadiologyCentreId", data.scaningCenter);
		formData.append("FirstName", data.patientFirstName);
		formData.append("LastName", data.patientLastName);
		formData.append("Email", data.email);
		formData.append("mobile", data.mobile);
		formData.append("Address", data.address1);
		formData.append("Age", data.age);
		formData.append("City", data.city);
		formData.append("state", data.state);
		formData.append("Country", data.country);
		formData.append("IsEmergency", data.isEmergencyScan);
		formData.append("ScanTypeId", data.scanType.value);
		formData.append("Gender", data.gender);
		formData.append("ClinicalHistory", data.clinicalHistory);
		formData.append("studyName", data.studyName);
		if (data.existingPatientId) {
			formData.append("patientId", data.existingPatientId);
		}
		return api
			.post("/teleradiology/addScan", formData, {
				headers: {
					"content-type": "multipart/form-data",
				},
			})
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static getPatients(type: string) {
		return api.post("/teleradiology/getPatients", { type });
	}
	static getScanTypes() {
		return api.post("/teleradiology/getScanTypes", null);
	}
	static getPatientsByID(id: string) {
		const body = {
			patientId: id,
		};

		return api
			.post("/teleradiology/getPatientById", body)
			.then((response) => {
				if (response.status === 200) {
					return response;
				} else {
					console.log(response);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	static editPatient(data: any) {
		return api
			.post("/teleradiology/editPatient", data)
			.then((response) => {
				if (response.status === 200 && response.data.response.token) {
					return response.data;
				} else {
					return response.data;
				}
			})
			.catch((error) => {
				console.log(error);
				return null;
			});
	}

	static getDashboardData(filter: any) {
		return api.post("/teleradiology/getDashboardData", filter);
	}
}
