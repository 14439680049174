import { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../../Services/Auth/Auth.service";
import InvHeader from "./InvHeader";
import InvFooter from "./InvFooter";

type Props = {
	children: React.ReactNode;
	showMargin: boolean;
};
type State = {
	redirect: string | null;
	ready: boolean;
	showMargin: boolean;
};

export default class InvLayout extends Component<Props, State> {
	currentUser = null;
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			showMargin: this.props.showMargin,
		};
	}

	componentDidMount() {
		this.currentUser = AuthService.getCurrentUser();
		if (!this.currentUser) this.setState({ redirect: "/login" });
		this.setState({ ready: true });
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		return (
			<>
				{this.state.ready && (
					<>
						<InvHeader currentUser={this.currentUser}></InvHeader>
						<div className="overflow-auto container-fluid bg-light vh-100">
							{this.props.children}
							{this.state.showMargin && <div className="d-d-print-none mb-50"></div>}
						</div>
						<InvFooter />
					</>
				)}
			</>
		);
	}
}
