import api from "../../Middleware/ApiClient";
import { AppConstants } from "../../Utils/AppConstants";

export default class AuthService {
  static login(username: string, password: string) {
    return api
      .post("/users/login", {
        username,
        password,
      })
      .then((response) => {
        if (response.status === 200 && response.data.response.token) {
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  }

  static logout() {
    sessionStorage.clear();
  }

  static getCurrentUser() {
    const payload = sessionStorage.getItem(AppConstants.SESSION_KEY_PAYLOAD);
    if (payload !== "undefined" && payload !== null) {
      const pyLoad = JSON.parse(payload);
      return pyLoad;
    }

    return null;
  }

  static getLoggedUser() {
    return api
      .post("/users/getLoggedUser", null)
      .then((response) => {
        if (response.status === 200 && response.data.response.token) {
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  }

  static isAuthenticated() {
    const payload = sessionStorage.getItem(AppConstants.SESSION_KEY_PAYLOAD);
    if (payload !== "undefined" && payload !== null) {
      const pload = JSON.parse(payload);
      if (pload.token !== undefined) {
        return true;
      }
    }

    return false;
  }
}
