import ModalPopup from "./ModalPopup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";

type Props = {
	showCreateNew: any;
	showCreateExisting: any;
	selectedRecord: any;
	handleClose: () => void;
	handleSave: (data: any) => void;
	scanType: Array<any>;
	scanningCenters: Array<any>;
	patients: Array<any>;
};

type State = {
	studyTypes: any;
};

export default class GenericScansDialog extends React.Component<Props, State> {
	userSubmitButtonRef: React.Ref<HTMLButtonElement> = null;
	scanSubmitButtonRef: React.Ref<HTMLButtonElement> = null;
	scanExistingSubmitButtonRef: React.Ref<HTMLButtonElement> = null;
	initialValues = {
		patientId: "",
		patientFirstName: "",
		patientLastName: "",
		email: "",
		mobile: "",
		address1: "",
		age: "",
		city: "",
		state: "",
		country: "",
		scanType: "",
		studyName: "",
		scaningCenter: "",
		gender: "",
		imageInput: "",
		clinicalHistory: "",
		isEmergencyScan: false,
		existingPatientId: "",
	};
	constructor(props: Props) {
		super(props);

		this.state = {
			studyTypes: [],
		};
		this.readyToSave = this.readyToSave.bind(this);
		this.scanSubmitButtonRef = React.createRef<HTMLButtonElement>();
	}

	gender = [
		{ value: 0, label: "Male" },
		{ value: 1, label: "Female" },
		{ value: 2, label: "Other" },
	];

	validationSchema1 = Yup.object().shape({
		patientFirstName: Yup.string().required("First Name is required"),
		patientLastName: Yup.string().required("Last Name is required"),
		email: Yup.string().required("Email is required").email("Invalid email address"),
		mobile: Yup.string()
			.required("Mobile is required")
			.matches(/^\d{10}$/, "Invalid mobile number"),
		country: Yup.string().required("Country is required"),
		state: Yup.string().required("State is required"),
		scanType: Yup.object().required("Scan Type is required"),
		city: Yup.string().required("City is required"),
		age: Yup.string().required("Age is required"),
		scaningCenter: Yup.string().required("Scaning Center is required"),
		patientId: Yup.string().required("Patient Id is required"),
		gender: Yup.string().required("Gender is required"),
		address1: Yup.string().required("Address is required"),
		imageInput: Yup.mixed()
			.test("is-zip", "Only ZIP files are allowed!", (value: any) => {
				console.log(value);
				if (value) {
					const { type } = value;
					if (type !== "application/zip") {
						return false;
					}

					return true;
				}

				return true;
			})
			.required("Scan image is required"),
		studyName: Yup.mixed().test("is-valid-study", "Invalid Study Name", (value: any) => {
			const ids = Array.isArray(value) ? value : [value];
			const isValid = ids.every((id) => typeof id === "string");
			return isValid;
		}),
	});

	validationSchema2 = Yup.object().shape({
		scanType: Yup.object().required("Scan Type is required"),
		scaningCenter: Yup.string().required("Scaning Center is required"),
		imageInput: Yup.mixed()
			.test("is-zip", "Only ZIP files are allowed!", (value: any) => {
				console.log(value);
				if (value) {
					const { type } = value;
					if (type !== "application/zip") {
						return false;
					}

					return true;
				}

				return true;
			})
			.required("Scan image is required"),
		existingPatientId: Yup.string().required("Patient Id is required"),
		studyName: Yup.mixed().test("is-valid-study", "Invalid Study Name", (value: any) => {
			const ids = Array.isArray(value) ? value : [value];
			const isValid = ids.every((id) => typeof id === "string");
			return isValid;
		}),
	});

	readyToSave(data: any) {
		this.props.handleSave(data);
		console.log(data);
	}

	handleSave = () => {
		if (this.scanSubmitButtonRef !== null) {
			(this.scanSubmitButtonRef as any).current.click();
		}
	};

	render(): React.ReactNode {
		return (
			<>
				<ModalPopup
					show={true}
					handleSave={this.handleSave}
					handleClose={this.props.handleClose}
					title={"Add new Scan"}
					content={
						<Formik enableReinitialize initialValues={this.initialValues} validationSchema={this.props.showCreateNew ? this.validationSchema1 : this.props.showCreateExisting ? this.validationSchema2 : null} onSubmit={this.readyToSave}>
							{({ setFieldValue }) => {
								return (
									<Form className="p-10">
										<Row>
											<Col>
												<div className="form-group">
													<label htmlFor="scaningCenter">Select Scaning Center</label>
													<Select
														name="scaningCenter"
														id="scaningCenter"
														isMulti={false}
														options={this.props.scanningCenters}
														onChange={(e) => {
															setFieldValue("scaningCenter", e.value);
														}}
														value={undefined}
													></Select>
													<ErrorMessage name="scaningCenter" component="div" className="text-danger" />
												</div>
											</Col>
										</Row>
										<Row>
											{this.props.showCreateExisting && (
												<Col>
													<div className="form-group">
														<label htmlFor="existingPatientId">Select Patient Id</label>
														<Select
															name="existingPatientId"
															id="existingPatientId"
															isMulti={false}
															options={this.props.patients}
															onChange={(e) => {
																setFieldValue("existingPatientId", e.value);
															}}
															value={undefined}
														></Select>
														<ErrorMessage name="existingPatientId" component="div" className="text-danger" />
													</div>
												</Col>
											)}
											{this.props.showCreateNew && (
												<Col>
													<div className="form-group">
														<label htmlFor="patientId">Patient Id</label>
														<Field type="number" id="patientId" name="patientId" className="form-control" />
														<ErrorMessage name="patientId" component="div" className="text-danger" />
													</div>
												</Col>
											)}
											{this.props.showCreateNew && (
												<Col>
													<div className="form-group">
														<label htmlFor="patientFirstName">Patient First Name</label>
														<Field type="text" id="patientFirstName" name="patientFirstName" className="form-control" />
														<ErrorMessage name="patientFirstName" component="div" className="text-danger" />
													</div>
												</Col>
											)}
											{this.props.showCreateNew && (
												<Col>
													<div className="form-group">
														<label htmlFor="patientLastName">Patient Last Name</label>
														<Field type="text" id="patientLastName" name="patientLastName" className="form-control" />
														<ErrorMessage name="patientLastName" component="div" className="text-danger" />
													</div>
												</Col>
											)}
										</Row>
										{this.props.showCreateNew && (
											<Row>
												<Col>
													<div className="form-group">
														<label htmlFor="mobile">Phone</label>
														<Field type="text" id="mobile" name="mobile" className="form-control" />
														<ErrorMessage name="mobile" component="div" className="text-danger" />
													</div>
												</Col>

												<Col>
													<div className="form-group">
														<label htmlFor="age">Age</label>
														<Field type="number" id="age" name="age" className="form-control" />
														<ErrorMessage name="age" component="div" className="text-danger" />
													</div>
												</Col>
												<Col>
													<div className="form-group">
														<label htmlFor="email">Email</label>
														<Field type="text" id="email" name="email" className="form-control" />
														<ErrorMessage name="email" component="div" className="text-danger" />
													</div>
												</Col>
											</Row>
										)}
										{this.props.showCreateNew && (
											<Row>
												<div className="form-group">
													<label htmlFor="address1">Address 1</label>
													<Field type="text" id="address1" name="address1" className="form-control" />
													<ErrorMessage name="address1" component="div" className="text-danger" />
												</div>
											</Row>
										)}
										{this.props.showCreateNew && (
											<Row>
												<Col>
													<div className="form-group">
														<label htmlFor="city">City</label>
														<Field type="text" id="city" name="city" className="form-control" />
														<ErrorMessage name="city" component="div" className="text-danger" />
													</div>
												</Col>
												<Col>
													<div className="form-group">
														<label htmlFor="state">State</label>
														<Field type="text" id="state" name="state" className="form-control" />
														<ErrorMessage name="state" component="div" className="text-danger" />
													</div>
												</Col>
												<Col>
													<div className="form-group">
														<label htmlFor="country">Country</label>
														<Field type="text" id="country" name="country" className="form-control" />
														<ErrorMessage name="country" component="div" className="text-danger" />
													</div>
												</Col>
											</Row>
										)}
										<Row>
											<Col>
												<div className="form-group">
													<label htmlFor="scanType">Select Scan Type</label>
													<Select
														name="scanType"
														id="scanType"
														isMulti={false}
														options={this.props.scanType}
														onChange={(e) => {
															console.log(e);
															this.setState({
																studyTypes: e.studyNames,
															});
															setFieldValue("scanType", e);
														}}
														value={undefined}
													></Select>
													<ErrorMessage name="scanType" component="div" className="text-danger" />
												</div>
											</Col>
											<Col>
												<div className="form-group">
													<label htmlFor="studyName">Select Study Name</label>
													<Select
														name="studyName"
														id="studyName"
														isMulti={false}
														options={this.state.studyTypes}
														onChange={(e: any) => {
															console.log(e);

															setFieldValue("studyName", e.value);
														}}
														value={undefined}
													></Select>
													<ErrorMessage name="studyName" component="div" className="text-danger" />
												</div>
											</Col>
										</Row>
										<Row>
											<Col>
												<div className="form-group">
													<label htmlFor="clinicalHistory">Technician Notes</label>
													<textarea
														id="clinicalHistory"
														name="clinicalHistory"
														className="form-control"
														onChange={(e: any) => {
															setFieldValue("clinicalHistory", e.target.value);
														}}
													/>
													<ErrorMessage name="clinicalHistory" component="div" className="text-danger" />
												</div>
											</Col>
											{this.props.showCreateNew && (
												<Col>
													<div className="form-group">
														<label htmlFor="gender">Gender</label>
														<Select
															name="gender"
															id="gender"
															isMulti={false}
															options={this.gender}
															onChange={(e) => {
																setFieldValue("gender", e?.label);
															}}
															value={undefined}
														></Select>
														<ErrorMessage name="gender" component="div" className="text-danger" />
													</div>
												</Col>
											)}
										</Row>
										<Row>
											<Col></Col>
										</Row>
										<Row>
											<Col>
												<div className="form-group">
													<label htmlFor="imageInput"> Add Scan Images</label>
													<input
														type="file"
														id="imageInput"
														name="imageInput"
														onChange={(e: any) => {
															const selectedFile = e.target.files[0];
															if (selectedFile) {
																const reader = new FileReader();
																reader.readAsArrayBuffer(selectedFile);
																reader.onloadend = () => {
																	const fileContentAsBlob = new Blob([reader.result as ArrayBuffer], { type: selectedFile.type });
																	setFieldValue("imageInput", fileContentAsBlob);
																};
															}
														}}
														className="form-control"
													/>
													<ErrorMessage name="imageInput" component="div" className="text-danger" />
												</div>
											</Col>
										</Row>

										<Row>
											<Col>
												<div className="form-check form-switch mt-5 ">
													<label className="form-check-label fw-bold " htmlFor="isEmergencyScan">
														Emergency Scan
													</label>
													<input
														className="form-check-input"
														type="checkbox"
														role="switch"
														onChange={(e: any) => {
															setFieldValue("isEmergencyScan", e.target.checked);
														}}
														id="isEmergencyScan"
													/>
												</div>
											</Col>
										</Row>
										<button type="submit" ref={this.scanSubmitButtonRef} className="d-none"></button>
									</Form>
								);
							}}
						</Formik>
					}
				/>
			</>
		);
	}
}
