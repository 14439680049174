import api from "../Middleware/ApiClient";

export default class MasterDataService {
	static getByType(type: string) {
		return api.post("/masterdata/getByType", { type });
	}
	static createOrUpdateValue(data: any) {
		return api.post("/masterdata/createOrUpdateValue", data);
	}
	static getStudyNames() {
		return api.post("/masterdata/getStudyNames");
	}
	static createOrUpdateStudyName(data: any) {
		return api.post("/masterdata/createOrUpdateStudyName", data);
	}
}
