import { Component } from "react";
import { Badge, Col, Row, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import AppLayout from "../../Layouts/App/AppLayout";
import AuthService from "../../Services/Auth/Auth.service";
import PlatformService from "../../Services/Auth/Platform.service";
import { FaEdit } from "react-icons/fa";
import { RiAdminLine } from "react-icons/ri";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaPlus } from "react-icons/fa6";
import Select from "react-select";
import { AppConstants } from "../../Utils/AppConstants";
import UserDialog from "./UserDialog.component";
import { toast, ToastContainer } from "react-toastify";

type Props = {};
type State = {
	redirect: string | null;
	ready: boolean;
	currentUser: any | null;
	clients: Array<any>;
	selectedClientId: any | undefined;
	users: Array<any>;
	isShowDialog: boolean;
	selectedRecord: any | null;
	roles: Array<any>;
};

type userType = {
	Id: string;
	UserName: string;
	DisplayName: string;
	Email: string;
	Mobile: string;
	IsActive: boolean;
	Roles: Array<string>;
	Action: any;
};

export default class Users extends Component<Props, State> {
	userTableHeaders: TableColumnType<userType>[] = [
		{
			prop: "UserName",
			title: "User Name",
			isSortable: true,
		},
		{
			prop: "DisplayName",
			title: "Display Name",
			isSortable: true,
		},
		{
			prop: "Email",
			title: "Email",
			isSortable: true,
		},
		{
			prop: "Mobile",
			title: "Mobile",
			isSortable: true,
		},
		{
			prop: "IsActive",
			title: "Active",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsActive && <Badge bg="success">Yes</Badge>}
					{!row.IsActive && <Badge bg="danger">No</Badge>}
				</div>
			),
		},
		{
			prop: "Roles",
			title: "Roles",
			isSortable: false,
			cell: (row) => (
				<div>
					{row.Roles.map((role: any) => (
						<div key={Math.random()}>
							<Badge bg="success">{role.label}</Badge>
						</div>
					))}
				</div>
			),
		},
		{
			prop: "Action",
			title: "Action",
			isFilterable: false,
			cell: (row) => (
				<div className="d-flex">
					<button
						className="btn btn-primary d-flex"
						onClick={() =>
							this.setState({
								selectedRecord: row,
								isShowDialog: true,
							})
						}
					>
						<span className="text-white">
							<FaEdit />
						</span>
						<span className="ms-3 mt-1">Edit</span>
					</button>
					<button className="btn btn-warning d-flex ms-5" onClick={() => this.impersonateUser(row)}>
						<span className="text-white">
							<RiAdminLine className="fs-10" />
						</span>
						<span className="ms-3 mt-1">Impersonate</span>
					</button>
				</div>
			),
		},
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			ready: false,
			currentUser: null,
			clients: [],
			selectedClientId: undefined,
			users: [],
			isShowDialog: false,
			selectedRecord: null,
			roles: [],
		};

		this.onClientSelected = this.onClientSelected.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	async componentDidMount() {
		const currentUser = AuthService.getCurrentUser();
		const res = await PlatformService.getClients();
		const roles = await PlatformService.getRoles();
		const clients: any = [];
		res.data.clients.map((c: any) =>
			clients.push({
				label: c.Name,
				value: c.Id,
			})
		);
		this.setState({
			currentUser,
			ready: true,
			clients: clients,
			roles: roles.data,
		});
	}

	async onClientSelected(e: any) {
		this.setState(
			{
				selectedClientId: e,
			},
			() => this.loadUsers()
		);
	}

	async loadUsers() {
		const users = await PlatformService.getClientUsers({ clientId: this.state.selectedClientId.value });
		this.setState({
			users: users.data,
		});
	}

	async impersonateUser(row: any) {
		const res = await PlatformService.impersonateUser({ userId: row.Id });
		if (res.status === 200) {
			console.log(res.data);
			res.data.isImpersonate = true;
			const currentContext = sessionStorage.getItem(AppConstants.SESSION_KEY_PAYLOAD);
			if (currentContext !== null) {
				sessionStorage.setItem(AppConstants.SESSION_KEY_IMPERSONATION, currentContext);
				sessionStorage.setItem(AppConstants.SESSION_KEY_PAYLOAD, JSON.stringify(res.data));
				window.location.href = "/home";
			}
		}
	}

	async handleSave(data: any) {
		const roles: any = [];
		data.selectedRoles.map((r: any) => roles.push(r.value));
		const req = {
			UserName: data.userName,
			ClientId: this.state.selectedClientId?.value,
			DisplayName: data.displayName,
			Password: data.password,
			Email: data.email,
			Mobile: data.mobile,
			Roles: roles,
			IsActive: data.isActive,
		};
		if (this.state.selectedRecord === null) {
			var res = await PlatformService.createUser(req);
			if (res.status === 200) {
				if (res.data.success) {
					toast.success("User created successfully");
					this.setState(
						{
							isShowDialog: false,
						},
						async () => await this.loadUsers()
					);
				} else toast.error(res.data.errorMessage[0]);
			}
		} else {
			(req as any).Id = this.state.selectedRecord.Id;
			var re = await PlatformService.updateUser(req);
			if (re.status === 200) {
				if (re.data.success) {
					toast.success("User updated successfully");
					this.setState(
						{
							isShowDialog: false,
						},
						async () => await this.loadUsers()
					);
				} else toast.error(re.data.errorMessage[0]);
			}
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<AppLayout>
				<>
					{this.state.ready && this.state.currentUser !== null && (
						<div className="mt-60">
							<div className="d-sm-flex align-items-center justify-content-between mb-12">
								<h1 className="h3 mb-0 text-gray-800">Users</h1>
							</div>
							<div className="d-flex">
								<label className="col col-lg-1 my-auto">Select Client</label>
								<div className="col col-lg-2">
									<Select name="client" value={this.state.selectedClientId} onChange={this.onClientSelected} options={this.state.clients}></Select>
								</div>
							</div>
							<DatatableWrapper
								body={this.state.users}
								headers={this.userTableHeaders}
								paginationOptionsProps={{
									initialState: {
										rowsPerPage: 10,
										options: [5, 10, 15, 20],
									},
								}}
							>
								<Row className="mb-10">
									<Col className="col-3 d-flex flex-col justify-content-end align-items-end">
										<Filter />
									</Col>
									<Col className="d-flex justify-content-end align-items-end">
										<PaginationOptions />
										{this.state.selectedClientId && (
											<button
												className="btn btn-primary d-flex ms-10"
												onClick={() =>
													this.setState({
														selectedRecord: null,
														isShowDialog: true,
													})
												}
											>
												<span className="text-white">
													<FaPlus />
												</span>
												<span className="ms-3 mt-1">Create New</span>
											</button>
										)}
									</Col>
								</Row>
								<Table>
									<TableHeader />
									<TableBody />
								</Table>
								<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
									<Pagination />
								</Col>
							</DatatableWrapper>
						</div>
					)}
					<UserDialog
						show={this.state.isShowDialog}
						selectedRecord={this.state.selectedRecord}
						handleSave={this.handleSave}
						handleClose={() => {
							this.setState({ isShowDialog: false });
						}}
						roles={this.state.roles}
						key={Math.random()}
					></UserDialog>
					<ToastContainer />
				</>
			</AppLayout>
		);
	}
}
