/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
import React from "react";
import { DatatableWrapper, Filter, PaginationOptions, TableBody, TableColumnType, TableHeader, Pagination } from "react-bs-datatable";
import { FaArrowDown, FaCommentMedical, FaEdit, FaEye, FaFilePdf, FaFilter, FaHistory, FaPlus, FaWindowClose } from "react-icons/fa";
import TRadService from "../../Services/TRad/TRad.service";
import { Navigate } from "react-router-dom";
import { Badge, Col, Row, Table } from "react-bootstrap";
import { AppConstants } from "../../Utils/AppConstants";
import ModalPopup from "./ModalPopup";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { Field, Form, Formik } from "formik";
import JSZip from "jszip";
import AuthService from "../../Services/Auth/Auth.service";
import GenericScansHistory from "./GenericScansHistory.component";
import moment from "moment";
import GenericScansDialog from "./GenericScansDialog.components";
import GenericUserService from "../../Services/GenericUser.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faEdit, faFloppyDisk, faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import TRadPatientEditDialog from "../TRad/TRadPatientEditDialog.component";
import ExportToExcel from "../Shared/ExportToExcel";

type Props = {
	statusKey: string;
};

type State = {
	redirect: string | null;
	tradData: any;
	formattedTradData: any;
	tradDataCopy: any;
	ready: boolean;
	isShowDialog: boolean;
	radiologists: any;
	assign: any;
	showFilters: boolean;
	filterDropDrowns: any;
	addNotes: boolean;
	notes: string;
	EditscanId: any;
	userRole: string;
	showHistoryDialog: boolean;
	addDocument: boolean;
	selectedFiles: any;
	selectedTab: any;
	showOptionAddScan: boolean;
	showCreateNew: boolean;
	scanTypes: any;
	showCreateExisting: boolean;
	selectedRecord: any | null;
	scanType: Array<any>;
	studyNames: Array<any>;
	values: Array<any>;
	scanningCenters: Array<any>;
	patients: Array<any>;
	isPatientEdit: boolean;
	selectedPatientRecord: any | null;
	historyData: Array<any>;
	key: number;
};

type scanType = {
	PatientFirstname: string;
	scanRequestedBy?: string;
	reportEnteredDate: any;
	teleRadiologyCentre: string;
	ScannedDate: any;
	scanType: any;
	PatientNumber: any;
	patientGender: any;
	"age/sex": any;
	studyName: any;
	sex: any;
	Action?: any;
	defaultRadiologist: any;
};

export default class GenericScans extends React.Component<Props, State> {
	private formik: any;
	tabArray = [
		{ name: "Today", filter: "today" },
		{ name: "This Week", filter: "thisWeek" },
		{ name: "This Month", filter: "thisMonth" },
	];

	scanTableHeaders: TableColumnType<scanType>[] = [
		{
			prop: "PatientNumber",
			title: "Patient Id",
			isSortable: true,
			cell: (row: any) => {
				return (
					<>
						<div className="d-flex ">
							{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && (
								<div>
									<FontAwesomeIcon
										icon={faEdit}
										title="Edit Patient"
										className="cursor-pointer P-3 fs-6"
										onClick={async () => {
											await this.loadPatient(row.PatientId);
										}}
									/>
								</div>
							)}
							<div className="ms-4 mt-1">{row.PatientNumber}</div>
						</div>
					</>
				);
			},
		},
		{
			prop: "PatientFirstname",
			title: "Patient Name",
			isSortable: true,
		},
		{
			prop: "age/sex",
			title: "Age/Gender",
			isSortable: false,
		},

		{
			prop: "scanType",
			title: "Scan Type",
			isSortable: true,
			cell: (row: any) => {
				const { tradData } = this.state;
				return (
					<>
						<div className="d-flex gap-4">
							{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && this.props.statusKey === AppConstants.STATUS_NEW && (
								<div className="d-flex gap-3 ms-4">
									<div>
										{!row.scanTypeEdit ? (
											<FontAwesomeIcon
												icon={faEdit}
												title="Edit scanType"
												className="cursor-pointer P-3 fs-6"
												onClick={() => {
													row["scanTypeEdit"] = true;
													this.setState({ tradData });
												}}
											/>
										) : (
											<FontAwesomeIcon
												icon={faSquareXmark}
												title="Discard"
												className="cursor-pointer fs-6 "
												onClick={() => {
													const { tradDataCopy } = this.state;
													const index = tradDataCopy.findIndex((x: any) => {
														return x.scanId === row.scanId;
													});
													if (index !== -1) {
														row.scanTypeId = tradDataCopy[index].scanTypeId;
														row.scanType = tradDataCopy[index].scanType;
														row.studyName = tradDataCopy[index].studyName;
														row.studyNameId = tradDataCopy[index].studyNameId;
													}
													row["scanTypeEdit"] = false;
													this.setState({ tradData });
												}}
											/>
										)}
									</div>
									{row.scanTypeEdit && (
										<div>
											<FontAwesomeIcon
												title="Save"
												className="cursor-pointer fs-6"
												onClick={async () => {
													await TRadService.updateScanFields({ scanId: row.scanId, value: row.scanTypeId, field: "ScanTypeId" });
													await TRadService.updateScanFields({ scanId: row.scanId, value: null, field: "StudyType" });
													row["scanTypeEdit"] = false;
													this.setState({ tradData });
												}}
												icon={faFloppyDisk}
											/>
										</div>
									)}
								</div>
							)}
							{row.scanTypeEdit ? (
								<div className="ms-4 w-100">
									<Select
										name="scanType"
										id="scanType"
										isMulti={false}
										options={this.state.scanTypes}
										onChange={(e: any) => {
											row.scanTypeId = e.value;
											row.scanType = e.label;
											row.studyName = null;
											row.studyNameId = null;
											this.setState({
												studyNames: e.studyNames,
											});
										}}
										value={row.scanTypeId ? { value: row.scanTypeId, label: row.scanType } : null}
									></Select>
								</div>
							) : (
								<div>{row.scanType}</div>
							)}
						</div>
					</>
				);
			},
		},
		{
			prop: "studyName",
			title: "Study",
			isSortable: true,
			cell: (row: any) => {
				const { tradData } = this.state;
				return (
					<>
						<div className="d-flex gap-4">
							{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && this.props.statusKey === AppConstants.STATUS_NEW && (
								<div className="d-flex gap-3 ms-4">
									<div>
										{!row.studyNameEdit ? (
											<FontAwesomeIcon
												icon={faEdit}
												title="Edit studyName"
												className="cursor-pointer P-3 fs-6"
												onClick={() => {
													let { studyNames } = this.state;
													studyNames =
														this.state.scanTypes.filter((x: any) => {
															return x.label === row.scanType;
														})[0]?.studyNames || [];
													row["studyNameEdit"] = true;
													this.setState({ tradData, studyNames });
												}}
											/>
										) : (
											<FontAwesomeIcon
												icon={faSquareXmark}
												title="Discard"
												className="cursor-pointer fs-6 "
												onClick={() => {
													const { tradDataCopy } = this.state;
													const index = tradDataCopy.findIndex((x: any) => {
														return x.scanId === row.scanId;
													});
													if (index !== -1) {
														if (row.scanTypeId === tradDataCopy[index].scanTypeId) {
															row.studyNameId = tradDataCopy[index].studyNameId;
															row.studyName = tradDataCopy[index].studyName;
														} else {
															row.studyNameId = null;
															row.studyName = null;
														}
													}
													row["studyNameEdit"] = false;
													this.setState({ tradData });
												}}
											/>
										)}
									</div>
									{row.studyNameEdit && (
										<div>
											<FontAwesomeIcon
												title="Save"
												className="cursor-pointer fs-6"
												onClick={async () => {
													await TRadService.updateScanFields({ scanId: row.scanId, value: row.studyNameId, field: "StudyType" });
													row["studyNameEdit"] = false;
													this.setState({ tradData });
												}}
												icon={faFloppyDisk}
											/>
										</div>
									)}
								</div>
							)}
							{row.studyNameEdit ? (
								<div className="ms-4 w-100">
									<Select
										name="scanType"
										id="scanType"
										isMulti={false}
										options={this.state.studyNames}
										onChange={(e) => {
											row.studyNameId = e?.value;
											row.studyName = e?.label;
											this.setState({ tradData });
										}}
										value={row.studyNameId ? { value: row.studyNameId, label: row.studyName } : null}
									></Select>
								</div>
							) : (
								<div>{row.studyName}</div>
							)}
						</div>
					</>
				);
			},
		},
		{
			prop: "ScannedDate",
			title: "Scan Date",
			isSortable: true,
		},
		{
			prop: "defaultRadiologist",
			title: "Radiologist",
			isSortable: true,
			cell: (row: any) => {
				return (
					<>
						{row.scanReport && <div></div>}
						{row.assignedRadiologist ? (
							<div>{row.assignedRadiologist}</div>
						) : (
							row?.defaultRadiologist.map((x: any) => {
								return (
									<div>
										{row.assignedRadiologistUserName === x.assignedRadiologistUserName && row.scanReport && this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && <FontAwesomeIcon icon={faCircle} spin style={{ color: "#48de12" }} />} {x.defaultRadiologist}
									</div>
								);
							})
						)}
					</>
				);
			},
		},
		{
			prop: "teleRadiologyCentre",
			title: "Center Name",
			isSortable: true,
		},
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			tradData: [],
			formattedTradData: [],
			tradDataCopy: [],
			ready: false,
			isShowDialog: false,
			radiologists: [],
			assign: {},
			showFilters: false,
			filterDropDrowns: [],
			addNotes: false,
			notes: "",
			EditscanId: null,
			userRole: "",
			showHistoryDialog: false,
			selectedFiles: [],
			addDocument: false,
			selectedTab: [],
			showOptionAddScan: false,
			showCreateNew: false,
			scanTypes: [],
			showCreateExisting: false,
			selectedRecord: null,
			scanType: [],
			studyNames: [],
			values: [],
			scanningCenters: [],
			patients: [],
			isPatientEdit: false,
			selectedPatientRecord: null,
			historyData: [],
			key: 0,
		};

		console.log(this.props.statusKey);

		if (this.props.statusKey === AppConstants.STATUS_NEW) {
			this.scanTableHeaders.push({
				prop: "Action",
				title: "",
				isFilterable: false,
				cell: (row: any) => (
					<div className="d-flex">
						{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && (
							<button
								title="Assign Radiologist"
								className="btn btn-primary"
								onClick={async () => {
									const { assign } = this.state;
									assign.scanId = row.scanId;
									await this.getAllRadiologist();
									this.setState({ isShowDialog: true, assign: assign });
								}}
							>
								<span className="text-white">
									<FaEdit />
								</span>
							</button>
						)}
						<div style={{ width: "3px" }}></div>
						{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && (
							<button
								title="Technician Notes"
								className="btn btn-secondary"
								onClick={async () => {
									this.setState({ addNotes: true, EditscanId: row.scanId, notes: row.technicianNotes || "" });
								}}
							>
								<span className="text-white">
									<FaCommentMedical />
								</span>
							</button>
						)}

						<div style={{ width: "3px" }}></div>
						<button
							title="Scan History"
							className="btn btn-warning"
							onClick={async () => {
								this.openScanHistory(row.scanId);
							}}
						>
							<span className="text-white">
								<FaHistory />
							</span>
						</button>

						<div style={{ width: "3px" }}></div>
						<button
							title="Download Images"
							className="btn btn-success"
							onClick={async () => {
								this.downloadScan(row.scanId);
							}}
						>
							<span className="text-white">
								<FaArrowDown />
							</span>
						</button>
						<div style={{ width: "3px" }}></div>
						<button title="View Images" className="btn btn-danger" onClick={() => this.getFiles(row)}>
							<span className="text-white">
								<FaEye />
							</span>
						</button>
					</div>
				),
			});
		}

		if (this.props.statusKey === AppConstants.STATUS_REVIEW) {
			this.scanTableHeaders.push({
				prop: "Action",
				title: "",
				isFilterable: false,
				cell: (row: any) => (
					<div className="d-flex">
						{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && (
							<button
								title="Assign Radiologist"
								className="btn btn-primary"
								onClick={async () => {
									const { assign } = this.state;
									assign.scanId = row.scanId;
									await this.getAllRadiologist();
									this.setState({ isShowDialog: true, assign: assign });
								}}
							>
								<span className="text-white">
									<FaEdit />
								</span>
							</button>
						)}
						<div style={{ width: "3px" }}></div>
						{(this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) || this.state.userRole.includes(AppConstants.ROLE_RADIOLOGIST)) && (
							<button
								title="Technician Notes"
								className="btn btn-secondary"
								onClick={async () => {
									this.setState({ addNotes: true, EditscanId: row.scanId, notes: row.technicianNotes || "" });
								}}
							>
								<span className="text-white">
									<FaCommentMedical />
								</span>
							</button>
						)}

						<div style={{ width: "3px" }}></div>
						<button
							title="Scan History"
							className="btn btn-warning"
							onClick={async () => {
								this.openScanHistory(row.scanId);
							}}
						>
							<span className="text-white">
								<FaHistory />
							</span>
						</button>

						<div style={{ width: "3px" }}></div>
						<button
							title="Download Images"
							className="btn btn-success"
							onClick={async () => {
								this.downloadScan(row.scanId);
							}}
						>
							<span className="text-white">
								<FaArrowDown />
							</span>
						</button>
						<div style={{ width: "3px" }}></div>
						<button title="View Images" className="btn btn-danger" onClick={() => this.getFiles(row)}>
							<span className="text-white">
								<FaEye />
							</span>
						</button>
					</div>
				),
			});
		}

		if (this.props.statusKey === AppConstants.STATUS_COMPLETED) {
			this.scanTableHeaders.push({
				prop: "Action",
				title: "",
				isFilterable: false,
				cell: (row: any) => (
					<div className="d-flex">
						{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && (
							<button
								title="Assign Radiologist"
								className="btn btn-primary"
								onClick={async () => {
									const { assign } = this.state;
									assign.scanId = row.scanId;
									await this.getAllRadiologist();
									this.setState({ isShowDialog: true, assign: assign });
								}}
							>
								<span className="text-white">
									<FaEdit />
								</span>
							</button>
						)}
						<div style={{ width: "3px" }}></div>
						{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && (
							<button
								title="Technician Notes"
								className="btn btn-secondary"
								onClick={async () => {
									this.setState({ addNotes: true, EditscanId: row.scanId, notes: row.technicianNotes || "" });
								}}
							>
								<span className="text-white">
									<FaCommentMedical />
								</span>
							</button>
						)}

						<div style={{ width: "3px" }}></div>

						<button
							title="Scan History"
							className="btn btn-warning"
							onClick={async () => {
								this.openScanHistory(row.scanId);
							}}
						>
							<span className="text-white">
								<FaHistory />
							</span>
						</button>

						<div style={{ width: "3px" }}></div>
						<button
							title="Download Images"
							className="btn btn-success"
							onClick={async () => {
								this.downloadScan(row.scanId);
							}}
						>
							<span className="text-white">
								<FaArrowDown />
							</span>
						</button>

						<div style={{ width: "3px" }}></div>
						<button title="View Images" className="btn btn-danger" onClick={() => this.getFiles(row)}>
							<span className="text-white">
								<FaEye />
							</span>
						</button>

						<div style={{ width: "3px" }}></div>
						<button title="Download Scan Report" className="btn btn-danger" onClick={() => this.downloadReport(row.scanId)}>
							<span className="text-white">
								<FaFilePdf />
							</span>
						</button>
					</div>
				),
			});
		}

		if (this.props.statusKey.includes(AppConstants.STATUS_LOADING)) {
			this.scanTableHeaders.push({
				prop: "Action",
				title: "",
				isFilterable: false,
				cell: (row: any) => (
					<div className="d-flex">
						{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && (
							<button
								title="Assign Radiologist"
								className="btn btn-primary"
								onClick={async () => {
									const { assign } = this.state;
									assign.scanId = row.scanId;
									await this.getAllRadiologist();
									this.setState({ isShowDialog: true, assign: assign });
								}}
							>
								<span className="text-white">
									<FaEdit />
								</span>
							</button>
						)}
						<div style={{ width: "3px" }}></div>
						{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && (
							<button
								title="Technician Notes"
								className="btn btn-secondary"
								onClick={async () => {
									this.setState({ addNotes: true, EditscanId: row.scanId, notes: row.technicianNotes || "" });
								}}
							>
								<span className="text-white">
									<FaCommentMedical />
								</span>
							</button>
						)}

						<div style={{ width: "3px" }}></div>
						{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) && (
							<button title="Add Attachments" className="btn btn-warning" onClick={async () => {}}>
								<span className="text-white">
									<FaHistory />
								</span>
							</button>
						)}

						<div style={{ width: "3px" }}></div>
						<button
							title="Download Images"
							className="btn btn-success"
							onClick={async () => {
								this.downloadScan(row.scanId);
							}}
						>
							<span className="text-white">
								<FaArrowDown />
							</span>
						</button>
						<div style={{ width: "3px" }}></div>
						<button title="View Images" className="btn btn-danger" onClick={() => this.getFiles(row)}>
							<span className="text-white">
								<FaEye />
							</span>
						</button>
					</div>
				),
			});
		}
	}

	async loadPatient(patientId: any) {
		let { selectedPatientRecord } = this.state;
		const res = await TRadService.getPatientsByID(patientId);
		if (res) {
			const patientData = res.data.response.data[0];

			const initialValues = {
				patientid: patientData.id,
				patientNumber: patientData.patientNo || "",
				patientFirstName: patientData.firstName || "",
				patientLastName: patientData.lastName || "",
				mobile: patientData.phone || "",
				age: patientData.age || "",
				email: patientData.email || "",
				address1: patientData.address || "",
				city: patientData.city || "",
				state: patientData.state || "",
				country: patientData.country || "",
				gender: patientData.gender || "",
			};

			selectedPatientRecord = { ...initialValues };

			this.setState({
				selectedPatientRecord,
				isPatientEdit: true,
			});
		}
	}
	openScanHistory = async (scanId: any) => {
		const res = await TRadService.getScanHistory({ scanId });
		this.setState({
			showHistoryDialog: true,
			EditscanId: scanId,
		});
		if (res.response.success) {
			this.setState({
				historyData: res.response.data,
			});
		}
	};

	downloadReport = async (scanId: any) => {
		const res = await TRadService.getScanDocument({ scanId });
		if (res.response.success) {
			const uint8Array = new Uint8Array(res.response.data.document.data);
			const blob = new Blob([uint8Array], { type: "application/pdf" });
			const downloadLink = document.createElement("a");
			downloadLink.href = URL.createObjectURL(blob);
			downloadLink.download = res.response.data.documentName;
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
		}
	};

	componentDidMount(): void {
		this.loadValues();
		this.loadData(null);
		this.loadFilterDropdowns();
		const currentUser = AuthService.getCurrentUser();
		const userRole = currentUser.user.Roles.split(",");
		this.setState({ userRole: userRole });
	}

	downloadScan = async (scanId: any) => {
		const res = await TRadService.downloadFileById(scanId);
		if (res != null) {
			let fileName = "";
			const uint8Array = new Uint8Array(res.data.data.image.data);
			const blob = new Blob([new Blob([uint8Array])], {
				type: "application/zip",
			});
			const zip = new JSZip();
			zip.loadAsync(blob)
				.then(async (zip) => {
					const imagePromises: any = [];
					zip.forEach((relativePath, file) => {
						if (file.dir) return;
						fileName = file.name;
						imagePromises.push(file.async("blob").then((blob) => ({ name: relativePath, blob })));
					});

					const imagesData = await Promise.all(imagePromises);
					const blobURL = URL.createObjectURL(new Blob([imagesData[0].blob], { type: "application/zip" }));

					const a = document.createElement("a");
					a.href = blobURL;
					a.download = fileName;

					a.style.display = "none";
					document.body.appendChild(a);

					a.click();

					document.body.removeChild(a);
					URL.revokeObjectURL(blobURL);
				})
				.catch((error) => {
					console.error("Error extracting ZIP:", error);
				});
		}
	};

	loadFilterDropdowns = async () => {
		const { filterDropDrowns } = this.state;
		const res = await TRadService.getDropdownsByField({
			statusKey: this.props.statusKey,
			filterFields: ["center", "patientNo", "refBy", "patient"],
		});

		if (res?.response?.data) {
			filterDropDrowns.centers = res?.response?.data[0];
			filterDropDrowns.patientNo = res?.response?.data[1];
			filterDropDrowns.refBy = res?.response?.data[2];
			filterDropDrowns.patients = res?.response?.data[3];
		}

		this.setState({ filterDropDrowns });
	};

	getFiltersByField = async (field: any) => {
		const res = await TRadService.getDropdownsByField({
			field,
			statusKey: this.props.statusKey,
		});
		if (res?.response?.data?.length) {
			return res.response.data;
		} else {
			return null;
		}
	};

	loadData = async (filters: any) => {
		const res = await TRadService.getScanByStatus({
			statusKey: this.props.statusKey,
			filters,
		});
		console.log(res.response.data);
		if (res.response.success) {
			this.setState({
				tradData: res.response.data.map((item: any) => ({ ...item })),
				tradDataCopy: res.response.data.map((item: any) => ({ ...item })),
				formattedTradData: res.response.data.map((item: any) => {
					const { PatientNumber, PatientFirstname, "age/sex": AgeSex, scanType, studyName, ScannedDate, defaultRadiologist, teleRadiologyCentre, assignedRadiologist } = item;

					const extractedRadiologists = assignedRadiologist ? assignedRadiologist : defaultRadiologist.map((x: any) => x.defaultRadiologist).join(", ");

					console.log(extractedRadiologists);

					return {
						PatientNumber,
						PatientFirstname,
						AgeSex,
						scanType,
						studyName,
						ScannedDate,
						defaultRadiologist: extractedRadiologists,
						teleRadiologyCentre,
					};
				}),

				ready: true,
			});
		} else {
			this.setState({
				tradData: [],
			});
		}
	};

	getFiles = (row: any) => {
		this.setState({
			redirect: `/tradunderreview/scan?id=${row.scanId}`,
		});
	};

	getAllRadiologist = async () => {
		const res = await TRadService.getAllRadiologist();
		if (res.response.success) {
			this.setState({
				radiologists: res.response.data.map((x: any) => ({
					value: x.Id,
					label: x.UserName,
				})),
			});
		}
	};

	handleTabChange = async (selectedTab: any) => {
		let startDate, endDate;
		switch (selectedTab.filter) {
			case "today":
				startDate = endDate = new Date();
				break;
			case "thisWeek":
				const weekDateRange = this.getCurrentWeekDateRange();
				startDate = new Date(weekDateRange.startDate);
				endDate = new Date(weekDateRange.endDate);
				break;
			case "thisMonth":
				startDate = new Date();
				startDate.setDate(1);
				endDate = new Date();
				endDate.setMonth(endDate.getMonth() + 1, 0);
				break;
			default:
				break;
		}
		const formattedStartDate = this.formatDateForFilter(startDate);
		const formattedEndDate = this.formatDateForFilter(endDate);

		// const filteredTradData = this.state.tradData.filter((item: any) => {
		// 	const scannedDate = moment(item.ScannedDate).format("YYYY-MM-DD");
		// 	return scannedDate >= formattedStartDate && scannedDate <= formattedEndDate;
		// });

		const updatedFilters = {
			from: formattedStartDate,
			to: formattedEndDate,
		};

		await this.loadData(updatedFilters);

		this.setState({ selectedTab });
	};

	formatDateForFilter(date: any) {
		return moment(date).format("YYYY-MM-DD");
	}
	getCurrentWeekDateRange() {
		var currentDate = new Date();
		var currentDayOfWeek = currentDate.getDay();
		var startDate = new Date(currentDate);
		startDate.setDate(currentDate.getDate() - currentDayOfWeek);
		var endDate = new Date(currentDate);
		endDate.setDate(currentDate.getDate() + (6 - currentDayOfWeek));
		var startDateFormatted = this.formatDateForFilter(startDate);
		var endDateFormatted = this.formatDateForFilter(endDate);
		return { startDate: startDateFormatted, endDate: endDateFormatted };
	}

	assignRadiologist = async () => {
		const { assign } = this.state;
		if (!assign.radiologistId) {
			toast.error("Please select radiologist");
			return;
		}
		const res = await TRadService.assignRadiologist(assign);
		this.setState({
			isShowDialog: false,
		});
		if (res.response.success) {
			this.loadData(null);
			toast.success("Radiologist Assigned");
		} else toast.error(res.data.errorMessage[0]);
	};

	filterData = async (filter: any) => {
		await this.loadData(filter);
	};

	removeSelectedFile = (index: number) => {
		const updatedFiles = [...this.state.selectedFiles];
		updatedFiles.splice(index, 1);
		this.setState({ selectedFiles: updatedFiles });
	};

	handleFileChange = (e: any) => {
		const { selectedFiles } = this.state;

		Array.from(e.target.files).map((x: any) => {
			const reader = new FileReader();
			reader.readAsArrayBuffer(x);

			reader.onload = () => {
				const blob = new Blob([reader.result as ArrayBuffer], { type: x.type });
				selectedFiles.push({ blob, fileName: x.name });
				this.setState({ selectedFiles });
			};

			reader.onerror = (error) => {
				console.error(error);
			};
		});
	};

	addDocument = async (data: any) => {
		const res = await TRadService.addDocument(data);

		if (res.success === true) {
			this.setState({
				addNotes: false,
			});
		}
	};

	addNotes = async (data: string) => {
		const { tradData } = this.state;
		const scanId = this.state.EditscanId;
		const scan = tradData.find((x: any) => x.scanId === scanId);
		const object = {
			scanId: scanId,
			field: "History",
			value: data,
		};
		const res = await TRadService.updateScanFields(object);
		scan.technicianNotes = data;
		if (res.response.success) {
			this.setState({
				addNotes: false,
				tradData,
			});
		}
	};

	handleSave = async (data: any) => {
		var res = await TRadService.addNewScan(data);
		if (res.response.success === true) {
			toast.success("Added New Scan successfully");
			this.setState({ showCreateExisting: false, showCreateNew: false });
		} else {
			toast.error(res.response.message);
		}
	};

	handleEditPatient = async (data: any) => {
		const req = {
			patientId: data.patientid,
			FirstName: data.patientFirstName,
			LastName: data.patientLastName,
			Phone1: data.mobile,
			Age: data.age,
			City: data.city,
			Coutry: data.country,
			Gender: data.gender,
			State: data.state,
		};

		const res = await TRadService.editPatient(req);
		if (res.response.success) {
			toast.success("Patient Updated");
			this.loadData(null);
		}
	};

	async loadValues() {
		const scanType = await TRadService.getScanTypes();
		if (scanType.status === 200 && scanType.data.response.success) {
			this.setState({
				scanTypes: scanType.data.response.data,
			});
		}
	}

	async loadScaningCenter() {
		const res = await GenericUserService.getUsersByType(AppConstants.ROLE_SCANNINGCENTRE);
		if (res.status === 200 && res.data.success) {
			const dropDown: any = [];
			res.data.results.map((item: any) => {
				dropDown.push({
					label: item.DisplayName,
					value: item.Id,
				});
			});
			this.setState({
				scanningCenters: dropDown,
				ready: true,
			});
		}
	}

	async getPatients() {
		const value = await TRadService.getPatients(AppConstants.ROLE_CLIENTADMIN);
		if (value.status === 200) {
			this.setState({
				patients: value.data.response.data,
				ready: true,
			});
		}
	}

	render(): React.ReactNode {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}
		const { filterDropDrowns } = this.state;

		return (
			<>
				<div>
					<DatatableWrapper
						body={this.state.tradData}
						headers={this.scanTableHeaders}
						paginationOptionsProps={{
							initialState: {
								rowsPerPage: 10,
								options: [5, 10, 15, 20],
							},
						}}
					>
						<Row className="mb-10">
							<Col className="col-3 d-flex flex-col justify-content-end align-items-end">
								<Filter />
							</Col>

							<Col className="d-flex gap-3 justify-content-end align-items-end gap-5">
								{this.props.statusKey !== AppConstants.STATUS_LOADING && (
									<div className=" gap-2 border rounded">
										<div
											title="Filter"
											onClick={() =>
												this.setState({
													showFilters: !this.state.showFilters,
												})
											}
											className="text-white btn btn-primary"
										>
											<FaFilter />
										</div>
									</div>
								)}
								{this.props.statusKey === AppConstants.STATUS_LOADING && (
									<button
										className="btn btn-primary d-flex ms-10"
										onClick={() => {
											this.loadValues();
											this.loadScaningCenter();
											this.setState({ showOptionAddScan: true });
											this.getPatients();
										}}
									>
										<span className="text-white">
											<FaPlus />
										</span>
										<span className="ms-3 mt-1">Add New Scan</span>
									</button>
								)}
								<ExportToExcel fileName={`Scan ${this.props.statusKey}_${moment().format("DD-MM-YYYY-HH-mm")}`} data={this.state.formattedTradData} sheetName={`Scans ${this.props.statusKey}`} fields={["PatientNumber", "PatientFirstname", "AgeSex", "scanType", "studyName", "ScannedDate", "defaultRadiologist", "teleRadiologyCentre"]} />
							</Col>
						</Row>

						{this.state.showFilters && (
							<Row className="mb-10">
								<Col>
									<div className="border p-3 w-100 d-flex">
										<Formik
											initialValues={{
												TeleRadiologyPatientId: null,
												TeleRadiologyCentreId: null,
												from: null,
												to: null,
												ScanRequestedBy: null,
												keywords: null,
											}}
											onSubmit={(values) => {
												this.filterData(values);
											}}
											innerRef={(formik) => (this.formik = formik)}
										>
											{(formikProps) => (
												<Form className="w-100">
													<div className="row g-4 w-100">
														<div className="col-1">
															<label className="font-weight-bold">Patient Name</label>
															<Select
																name="TeleRadiologyPatientId"
																id="TeleRadiologyPatientId"
																isMulti={false}
																options={filterDropDrowns.patients}
																onChange={(selectedOption: any) => {
																	formikProps.setFieldValue("TeleRadiologyPatientId", selectedOption.value);
																}}
																className="form-control p-0 border-0"
																key={this.state.key}
															/>
														</div>
														<div className="col-1">
															<label>Patient ID</label>
															<Select
																name="TeleRadiologyPatientId"
																id="TeleRadiologyPatientId"
																isMulti={false}
																options={filterDropDrowns.patientNo}
																onChange={(selectedOption: any) => {
																	formikProps.setFieldValue("TeleRadiologyPatientId", selectedOption.value);
																}}
																className="form-control p-0 border-0"
																key={this.state.key}
															/>
														</div>
														<div className="col-1">
															<label className="font-weight-bold">Center Name</label>
															<Select
																name="TeleRadiologyCentreId"
																id="TeleRadiologyCentreId"
																isMulti={false}
																options={filterDropDrowns.centers}
																onChange={(selectedOption: any) => {
																	formikProps.setFieldValue("TeleRadiologyCentreId", selectedOption.value);
																}}
																className="form-control p-0 border-0 "
																key={this.state.key}
															/>
														</div>
														<div className="col-1">
															<label>Ref. Physician</label>
															<Select
																name="ScanRequestedBy"
																id="ScanRequestedBy"
																isMulti={false}
																options={filterDropDrowns.refBy}
																onChange={(selectedOption: any) => {
																	formikProps.setFieldValue("ScanRequestedBy", selectedOption.value);
																}}
																className="form-control p-0 border-0"
																key={this.state.key}
															/>
														</div>
														<div className="col-1">
															<label>From</label>
															<Field type="date" name="from" className="form-control" key={this.state.key} />
														</div>
														<div className="col-1">
															<label>To</label>
															<Field type="date" name="to" className="form-control" key={this.state.key} />
														</div>
														<div className="col-2">
															<label>Keywords</label>
															<Field type="text" name="keywords" className="form-control" placeholder="Enter keywords" onChange={formikProps.handleChange} key={this.state.key} />
														</div>
														<div className="col-2 d-flex  justify-content-end ms-auto">
															<div>
																<button type="submit" className="btn btn-primary mt-14">
																	Search
																</button>
															</div>
															<div>
																<button
																	type="submit"
																	className="btn btn-secondary mt-14 ms-4"
																	onClick={() => {
																		formikProps.setValues({
																			TeleRadiologyPatientId: null,
																			TeleRadiologyCentreId: null,
																			from: null,
																			to: null,
																			ScanRequestedBy: null,
																			keywords: null,
																		});

																		this.setState((prevState) => ({
																			selectedTab: null,
																			key: prevState.key + 1,
																		}));
																	}}
																>
																	Reset
																</button>
															</div>
															<div>
																<button
																	title="Filter"
																	onClick={() =>
																		this.setState({
																			showFilters: !this.state.showFilters,
																		})
																	}
																	type="submit"
																	className="btn text-white btn-warning mt-14 ms-4"
																>
																	<FaWindowClose />
																</button>
															</div>
														</div>
													</div>
													<div className="row g-4 w-100 mt-2">
														<div className="d-flex">
															{/* <Col>
																<div className="mb-3">
																	{this.tabArray.map((tab: any, index: number) => (
																		<span key={index} className={`mb-3 btn btn-primary me-2 ${this.state.selectedTab === tab.filter ? "active" : ""}`} onClick={() => this.handleTabChange(tab)}>
																			{tab.name}
																		</span>
																	))}
																</div>
															</Col> */}
															<Col className="d-flex  gap-5">
																{this.tabArray.map((tab, index) => (
																	<Badge key={index} pill className={tab === this.state.selectedTab ? "bg-blue-400 cursor-pointer" : "bg-blue-700 cursor-pointer"} onClick={() => this.handleTabChange(tab)}>
																		{tab.name}
																	</Badge>
																))}
															</Col>
														</div>
													</div>
												</Form>
											)}
										</Formik>
									</div>
								</Col>
							</Row>
						)}
						<Table>
							<TableHeader />
							<TableBody
								classes={{ td: "bg-transparent" }}
								rowProps={(row: any) => {
									return {
										style: {
											backgroundColor: `${row.isEmergency && "#f9dcc4"}`,
										},
									};
								}}
							/>
						</Table>
						<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
							<PaginationOptions />
							<div className="ms-5" />
							<Pagination />
						</Col>
					</DatatableWrapper>
				</div>

				<ModalPopup
					show={this.state.isShowDialog}
					handleClose={() => {
						const { assign } = this.state;
						assign.radiologistId = null;
						this.setState({
							isShowDialog: false,
							assign,
						});
					}}
					handleSave={() => this.assignRadiologist()}
					title={"Assign Radiologist"}
					content={
						<>
							<div className="p-3">
								<div>Select Radiologist</div>
								<div>
									<Select
										name="UserName"
										id="Id"
										isMulti={false}
										options={this.state.radiologists}
										onChange={(selectedOption: any) => {
											const { assign } = this.state;
											assign.radiologistId = selectedOption.value;
											this.setState({
												assign,
											});
										}}
									/>
								</div>
							</div>
							<ToastContainer />
						</>
					}
				/>

				<ModalPopup
					show={this.state.addNotes}
					title={"Technician Notes"}
					content={
						<div className="p-4 form-floating">
							{this.state.userRole.includes(AppConstants.ROLE_CLIENTADMIN) || this.state.userRole.includes(AppConstants.ROLE_RADIOLOGIST) ? (
								<>
									<textarea className={"form-control border border-1 form-control-lg"} id="floatingTextarea2" style={{ height: "200px" }} value={this.state.notes} onChange={(e) => this.setState({ notes: e.target.value })}></textarea>
									<label htmlFor="floatingTextarea2">Technician Notes</label>
								</>
							) : (
								<div>{this.state.notes}</div>
							)}
						</div>
					}
					handleClose={() => {
						this.setState({
							addNotes: false,
						});
					}}
					handleSave={() => {
						this.addNotes(this.state.notes);
						this.setState({
							addNotes: false,
						});
					}}
				/>

				<GenericScansHistory
					showDialog={this.state.showHistoryDialog}
					statusKey={this.props.statusKey}
					handleClose={() =>
						this.setState({
							showHistoryDialog: false,
							historyData: [],
						})
					}
					role={this.state.userRole}
					historyData={this.state.historyData}
					scanId={this.state.EditscanId}
				/>
				<ModalPopup
					show={this.state.showOptionAddScan}
					showOptionAddScan={this.state.showOptionAddScan}
					title={"Select"}
					content={
						<div className="p-4 form-floating d-flex justify-content-between">
							<button
								className="btn btn-primary"
								onClick={() => {
									this.setState({
										showCreateNew: true,
										showOptionAddScan: false,
										showCreateExisting: false,
									});
								}}
							>
								Add Scan with New Patient
							</button>

							<button
								className="btn btn-primary"
								onClick={() => {
									this.setState({
										showCreateExisting: true,
										showOptionAddScan: false,
										showCreateNew: false,
									});
								}}
							>
								Add Scan with Existing Patient
							</button>
						</div>
					}
					handleClose={() => {
						this.setState({
							showOptionAddScan: false,
						});
					}}
					handleSave={() => {}}
				/>
				{this.state.showCreateNew || this.state.showCreateExisting ? (
					<GenericScansDialog
						showCreateNew={this.state.showCreateNew}
						showCreateExisting={this.state.showCreateExisting}
						selectedRecord={undefined}
						handleSave={this.handleSave}
						patients={this.state.patients}
						handleClose={() => {
							this.setState({
								showCreateNew: false,
								showCreateExisting: false,
							});
						}}
						scanType={this.state.scanTypes}
						scanningCenters={this.state.scanningCenters}
					/>
				) : null}
				{this.state.isPatientEdit && (
					<TRadPatientEditDialog
						show={this.state.isPatientEdit}
						handleSave={this.handleEditPatient}
						selectedPatientRecord={this.state.selectedPatientRecord}
						handleClose={() => {
							this.setState({
								isPatientEdit: false,
							});
						}}
					/>
				)}
				<ToastContainer />
			</>
		);
	}
}
