import { Component } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { FaPlus, FaRegFloppyDisk, FaXmark } from "react-icons/fa6";
import { Navigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import TRadLayout from "../../Layouts/TRad/TRadLayout";
import AuthService from "../../Services/Auth/Auth.service";
import MasterDataService from "../../Services/MasterData.service";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { AppConstants } from "../../Utils/AppConstants";
import Select from "react-select";

type Props = {};
type State = {
	redirect: string | null;
	scanTypes: any;
	studyNames: Array<any>;
	ready: boolean;
	currentUser: any | null;
	tableKey: number;
};

type valueType = {
	ScanType: any;
	Name: string;
	Code: string;
	RadiologistCharges: number;
	HospitalCharges: number;
	Action: any;
	IsEdit: boolean;
	isNew?: Boolean;
	Id?: any;
};

export default class TRadManageStudyNames extends Component<Props, State> {
	valueTableHeaders: TableColumnType<valueType>[] = [
		{
			prop: "ScanType",
			title: "Scan Type",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<div>
							<Select
								name="scantype"
								id={"scantype_" + Math.random()}
								isMulti={false}
								onChange={(e: any) => {
									row.ScanType = e;
									this.setState({
										studyNames: this.state.studyNames,
									});
								}}
								value={row.ScanType}
								options={this.state.scanTypes}
							></Select>
						</div>
					)}
					{!row.IsEdit && <span>{row.ScanType.label}</span>}
				</div>
			),
		},
		{
			prop: "Code",
			title: "Code",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<input
							value={row.Code}
							className="form-control"
							onChange={(e) => {
								row.Code = e.target.value;
								this.setState({
									studyNames: this.state.studyNames,
								});
							}}
						/>
					)}
					{!row.IsEdit && <span>{row.Code}</span>}
				</div>
			),
		},
		{
			prop: "Name",
			title: "Name",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<input
							value={row.Name}
							className="form-control"
							onChange={(e) => {
								row.Name = e.target.value;
								this.setState({
									studyNames: this.state.studyNames,
								});
							}}
						/>
					)}
					{!row.IsEdit && <span>{row.Name}</span>}
				</div>
			),
		},
		{
			prop: "RadiologistCharges",
			title: "Radiologist Charges",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<input
							type="number"
							value={row.RadiologistCharges}
							className="form-control"
							onChange={(e) => {
								row.RadiologistCharges = Number(e.target.value);
								this.setState({
									studyNames: this.state.studyNames,
								});
							}}
						/>
					)}
					{!row.IsEdit && <span>{row.RadiologistCharges}</span>}
				</div>
			),
		},
		{
			prop: "HospitalCharges",
			title: "Hospital Charges",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<input
							type="number"
							value={row.HospitalCharges}
							className="form-control"
							onChange={(e) => {
								row.HospitalCharges = Number(e.target.value);
								this.setState({
									studyNames: this.state.studyNames,
								});
							}}
						/>
					)}
					{!row.IsEdit && <span>{row.HospitalCharges}</span>}
				</div>
			),
		},
		{
			prop: "Action",
			title: "Action",
			isFilterable: false,
			cell: (row) => (
				<div className="d-flex">
					{!row.IsEdit && (
						<button
							className="btn btn-primary d-flex"
							onClick={() => {
								row.IsEdit = !row.IsEdit;
								this.setState({
									studyNames: this.state.studyNames,
								});
							}}
						>
							<span className="text-white">
								<FaEdit />
							</span>
							<span className="ms-3 mt-1">Edit</span>
						</button>
					)}
					{row.IsEdit && (
						<>
							<button
								className="btn btn-success d-flex"
								onClick={() => {
									this.saveValue(row);
								}}
							>
								<span className="text-white">
									<FaRegFloppyDisk />
								</span>
								<span className="ms-3 mt-1">Save</span>
							</button>
							<button
								className="btn btn-warning d-flex ms-4 "
								onClick={() => {
									let { studyNames } = this.state;
									if (row.isNew) {
										studyNames = studyNames.filter((x: any) => {
											return x.Id !== row.Id;
										});
									}
									row.IsEdit = !row.IsEdit;
									this.setState({
										studyNames,
									});
								}}
							>
								<span className="text-white">
									<FaXmark />
								</span>
								<span className="ms-3 mt-1 text-white">Cancel</span>
							</button>
						</>
					)}
				</div>
			),
		},
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			scanTypes: [],
			studyNames: [],
			ready: false,
			currentUser: null,
			tableKey: Math.random(),
		};
	}

	async componentDidMount() {
		const currentUser = AuthService.getCurrentUser();
		const scanTypes = await MasterDataService.getByType(AppConstants.MASTER_TYPE_SCAN);
		const scanTypeOptions: any = [];
		scanTypes.data.results.map((r: any) =>
			scanTypeOptions.push({
				label: r.Value,
				value: r.Id,
			})
		);
		this.setState(
			{
				currentUser,
				scanTypes: scanTypeOptions,
			},
			async () => await this.loadValues()
		);
	}

	async loadValues() {
		const value = await MasterDataService.getStudyNames();
		if (value.status === 200 && value.data.success) {
			this.setState({
				studyNames: value.data.results,
				ready: true,
			});
		}
	}

	async saveValue(data: any) {
		console.log(data);
		if (data.ScanType === undefined) {
			toast.error("Please select scan type");
			return;
		}
		if (data.Code === "") {
			toast.error("Please enter code");
			return;
		}
		if (data.Name === "") {
			toast.error("Please enter name");
			return;
		}
		const res = await MasterDataService.createOrUpdateStudyName({
			Id: data.isNew ? undefined : data.Id,
			ScanTypeId: data.ScanType.value,
			Code: data.Code,
			Name: data.Name,
			RadiologistCharges: data.RadiologistCharges,
			HospitalCharges: data.HospitalCharges,
		});
		if (res.status === 200 && res.data.success) {
			toast.success(res.data.message);
			this.loadValues();
		} else {
			toast.error(res.data.message);
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<TRadLayout showMargin={true}>
				<>
					<div className="mt-50 px-10">
						<div className="row">
							<div className="col-12">
								<h4 className="h3 mb-0 text-gray-800">Manage Study Names</h4>
								<p>A place to manage all study names</p>
							</div>
						</div>
						{this.state.ready && this.state.currentUser !== null && (
							<DatatableWrapper
								body={this.state.studyNames}
								headers={this.valueTableHeaders}
								paginationOptionsProps={{
									initialState: {
										rowsPerPage: 10,
										options: [5, 10, 15, 20],
									},
								}}
								key={this.state.tableKey}
							>
								<Row className="mb-10">
									<Col className="col-3 d-flex flex-col justify-content-end align-items-end">
										<Filter />
									</Col>
									<Col className="d-flex justify-content-end align-items-end">
										<PaginationOptions />
										<button
											className="btn btn-primary d-flex ms-10"
											onClick={() => {
												const studyNames = this.state.studyNames;
												studyNames.push({
													Id: Math.random(),
													Code: "",
													Name: "",
													RadiologistCharges: 0,
													HospitalCharges: 0,
													IsEdit: true,
													isNew: true,
												});
												this.setState({
													studyNames,
													tableKey: Math.random(),
												});
												console.log(this.state.studyNames);
											}}
										>
											<span className="text-white">
												<FaPlus />
											</span>
											<span className="ms-3 mt-1">Create New</span>
										</button>
									</Col>
								</Row>
								<Table>
									<TableHeader />
									<TableBody />
								</Table>
								<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
									<Pagination />
								</Col>
							</DatatableWrapper>
						)}
						<ToastContainer />
					</div>
				</>
			</TRadLayout>
		);
	}
}
