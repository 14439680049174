import { Component } from "react";
import ModalPopup from "../Shared/ModalPopup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React from "react";
import Select from "react-select";

type Props = {
	show: boolean;
	handleClose: () => void;
	handleSave: (data: any) => void;
	selectedRecord: any | null;
};

export default class ClientDialog extends Component<Props> {
	PreferredLanguages = [
		{ value: 0, label: "English", prefix: "EN" },
		{ value: 1, label: "Arabic", prefix: "AR" },
	];
	clientSubmitButtonRef: React.Ref<HTMLButtonElement> = null;
	logoFileRef: React.Ref<HTMLInputElement> = null;
	initialValues = {
		id: "",
		name: "",
		primarycolor: "",
		secondarycolor: "",
		logo: "",
		preferredLanguages: [this.PreferredLanguages[0].prefix],
	};

	constructor(props: Props) {
		super(props);

		this.readyToSave = this.readyToSave.bind(this);
		this.clientSubmitButtonRef = React.createRef<HTMLButtonElement>();
		this.logoFileRef = React.createRef<HTMLInputElement>();
		console.log(this.props);
		this.initialValues = {
			id: this.props.selectedRecord?.Id,
			logo: this.props.selectedRecord?.Logo,
			name: this.props.selectedRecord?.Name,
			primarycolor: this.props.selectedRecord?.PrimaryColor,
			secondarycolor: this.props.selectedRecord?.SecondaryColor,
			preferredLanguages: this.props.selectedRecord?.PreferredLanguage,
		};
	}

	validationSchema = Yup.object().shape({
		name: Yup.string().required("Name is required"),
		primarycolor: Yup.string().required("Primary color is required"),
		secondarycolor: Yup.string().required("Secondary is required"),
		logo: Yup.mixed()
			.nullable()
			.test("fileType", "Unsupported File Format", (value: any) => {
				if (value === null) {
					return false; // No file selected, so it's unsupported
				} else if (value !== null && value.toString().indexOf("data:") > -1) {
					return true; // Editing a record, and the value is not null
				} else if (value && value.length > 0) {
					const supportedTypes = ["image/jpeg", "image/bmp", "image/png"];
					if (supportedTypes.includes(value[0].type)) {
						return true;
					}
					return false; // Unsupported file format
				} else {
					return false; // Unsupported file format
				}
			})
			.required("Logo is required"),
	});

	handleSave = () => {
		if (this.clientSubmitButtonRef !== null) {
			(this.clientSubmitButtonRef as any).current.click();
		}
	};

	readyToSave(data: any) {
		console.log("DATACLIENT", data);
		if (data.preferredLanguages === undefined || data.preferredLanguages.length === 0) {
			data.preferredLanguages = [this.PreferredLanguages[0].prefix];
		}
		if (data.logo !== null && data.logo.toString().indexOf("data:") > -1) {
			this.props.handleSave(data);
		} else {
			const fileReader = new FileReader();
			fileReader.onload = () => {
				if (fileReader.readyState === 2) {
					data.logo = fileReader.result;
					this.props.handleSave(data);
				}
			};
			fileReader.readAsDataURL(data.logo[0]);
		}
	}

	render() {
		const selectedPreferredLanguages = this.props.selectedRecord?.PreferredLanguage || []; // Assuming this holds the selected languages from the selectedRecord
		console.log(selectedPreferredLanguages);
		const defaultLanguages =
			selectedPreferredLanguages.length > 0
				? selectedPreferredLanguages
						.map((lang: string) => {
							if (lang.includes(",")) {
								const langArr = lang.split(",");
								return langArr.map((prefix: string) => this.PreferredLanguages.find((item) => item.prefix === prefix));
							} else {
								return [this.PreferredLanguages.find((item) => item.prefix === lang)];
							}
						})
						.flat()
				: [this.PreferredLanguages[0]];

		return (
			<ModalPopup
				show={this.props.show}
				handleClose={this.props.handleClose}
				handleSave={this.handleSave}
				title={this.props.selectedRecord !== null ? "Edit Client" : "Create Client"}
				content={
					<Formik enableReinitialize initialValues={this.initialValues} validationSchema={this.validationSchema} onSubmit={this.readyToSave}>
						{({ setFieldValue }) => {
							return (
								<Form className="p-10">
									<div className="form-group">
										<label htmlFor="name">Name</label>
										<Field type="text" id="name" name="name" className="form-control" />
										<ErrorMessage name="name" component="div" className="text-danger" />
									</div>

									<div className="form-group pt-5">
										<label htmlFor="primarycolor">Primary Color</label>
										<Field type="color" id="primarycolor" name="primarycolor" className="form-control" />
										<ErrorMessage name="primarycolor" component="div" className="text-danger" />
									</div>

									<div className="form-group pt-5">
										<label htmlFor="secondarycolor">Secondary Color</label>
										<Field type="color" id="secondarycolor" name="secondarycolor" className="form-control" />
										<ErrorMessage name="secondarycolor" component="div" className="text-danger" />
									</div>

									<div className="form-group pt-5">
										<label htmlFor="logo">Logo</label>
										<input
											type="file"
											id="logo"
											name="logo"
											className="form-control"
											onChange={(e) => {
												setFieldValue("logo", e.target.files);
											}}
										/>
										<ErrorMessage name="logo" component="div" className="text-danger" />
									</div>

									<div className="form-group pt-5">
										<label htmlFor="Preferred Languages">Preferred Languages</label>
										<Select
											name="preferredLanguages"
											id="Preferred Languages"
											options={this.PreferredLanguages}
											defaultValue={defaultLanguages}
											isMulti={true}
											onChange={(selectedOptions: any) => {
												console.log(selectedOptions);
												const array: any = [];
												selectedOptions.map((option: any) => array.push(option.prefix));
												setFieldValue("preferredLanguages", array);
											}}
											//  value={}
										/>
									</div>

									<button type="submit" ref={this.clientSubmitButtonRef} className="d-none"></button>
								</Form>
							);
						}}
					</Formik>
				}
			/>
		);
	}
}
