/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-useless-constructor */
import React from "react";
import ModalPopup from "./ModalPopup";
import { DatatableWrapper, Filter, PaginationOptions, Pagination, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import Select from "react-select";
import { Col, Row, Table } from "react-bootstrap";
import { FaEdit, FaPlus } from "react-icons/fa";
import { FaRegFloppyDisk, FaXmark } from "react-icons/fa6";
import TRadService from "../../Services/TRad/TRad.service";
import { AppConstants } from "../../Utils/AppConstants";
import { toast } from "react-toastify";

type Props = {
	showDialog: boolean;
	scanId: any;
	statusKey: any;
	handleClose(data: any): void;
	historyData: any;
	role: any;
};

type State = {
	scanTypes: any;
	studyNames: any;
	historyDataCopy: any;
	historyData: any;
	tableKey: any;
};

export default class GenericScansHistory extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			scanTypes: [],
			studyNames: [],
			historyDataCopy: [...this.props.historyData],
			historyData: [...this.props.historyData],
			tableKey: Math.random(),
		};
	}

	historyTableHeaders: TableColumnType<any>[] = [
		{
			prop: "scanType",
			title: "Scan Type",
			isSortable: true,
			cell: (row) => (
				<div>
					{row.IsEdit && (
						<Select
							name="scanType"
							id="scanType"
							isMulti={false}
							placeholder="Select"
							options={this.state.scanTypes}
							onChange={(selectedOption: any) => {
								row.scanType = selectedOption.label;
								row.scanTypeId = selectedOption.value;
								this.setState({
									historyData: this.state.historyData,
									studyNames: selectedOption.studyNames,
								});
							}}
							value={
								row.scanTypeId
									? {
											label: row.scanType,
											value: row.scanTypeId,
									  }
									: null
							}
							className="form-control p-0 border-0"
						/>
					)}
					{!row.IsEdit && <span>{row.scanType}</span>}
				</div>
			),
		},
		{
			prop: "studyType",
			title: "Study Type",
			isSortable: true,
			cell: (row: any) => {
				return (
					<>
						<div>
							{row.IsEdit && (
								<Select
									name="studyType"
									id="studyType"
									placeholder="Select"
									isMulti={false}
									options={this.state.studyNames}
									onChange={(selectedOption: any) => {
										row.studyTypeId = selectedOption.value;
										row.studyType = selectedOption.label;
										this.setState({
											historyData: this.state.historyData,
										});
									}}
									value={
										row.studyTypeId
											? {
													label: row.studyType,
													value: row.studyId,
											  }
											: null
									}
									className="form-control p-0 border-0"
								/>
							)}
							{!row.IsEdit && <span>{row.studyType}</span>}
						</div>
					</>
				);
			},
		},
		{
			prop: "attachments",
			title: "Attachments",
			isSortable: true,
			cell: (row: any) => {
				const { historyData } = this.state;
				return (
					<>
						<div>
							{row.IsEdit && (
								<>
									<div className="w-100">
										<input
											multiple={true}
											id={`attachments+${row.id}`}
											type="file"
											name="attachments"
											className="d-none"
											onChange={(e: any) => {
												const files = Array.from(e.target.files);
												files.map((file: any) => {
													row.attachments.push({
														fileName: file.name,
														file: file,
													});
													this.setState({
														historyData,
													});
												});
											}}
										/>
										<label htmlFor={`attachments+${row.id}`} className="btn btn-primary">
											Choose File
										</label>
										<div className="cursor-pointer w-full mt-2 d-flex flex-column gap-2">
											{row.attachments?.map((x: any, id: any) => {
												return (
													<div className="d-flex">
														<li key={id}>{x.fileName}</li>

														<div>
															<FaXmark
																className="text-danger small my-auto ms-2"
																onClick={() => {
																	row.attachments = row.attachments.filter((obj: any) => obj !== x);
																	this.setState({
																		historyData,
																	});
																}}
															/>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</>
							)}
							{!row.IsEdit && (
								<div className="cursor-pointer w-full mt-2 d-flex flex-column gap-2">
									{row.attachments?.map((x: any, id: any) => {
										return (
											<div className="d-flex">
												<li
													onClick={async () => {
														const res = await TRadService.downloadFile({ fileId: x.fileId });
														if (res.response.success) {
															const uint8Array = new Uint8Array(res.response.data.file.data);
															const blob = new Blob([uint8Array], { type: "application/pdf" });
															const downloadLink = document.createElement("a");
															downloadLink.href = URL.createObjectURL(blob);
															downloadLink.download = res.response.data.fileName;
															document.body.appendChild(downloadLink);
															downloadLink.click();
															document.body.removeChild(downloadLink);
														}
													}}
													key={id}
												>
													{x.fileName}
												</li>
											</div>
										);
									})}
								</div>
							)}
						</div>
					</>
				);
			},
		},
		{
			prop: "description",
			title: "Description",
			isSortable: true,
			cell: (row: any) => {
				return (
					<>
						<div>
							{row.IsEdit && (
								<textarea
									onChange={(e: any) => {
										row.description = e.target.value;
										this.setState({
											historyData: this.state.historyData,
										});
									}}
									className="w-100"
									id="description"
									name="description"
									value={row.description}
								/>
							)}

							{!row.IsEdit && <span>{row.description}</span>}
						</div>
					</>
				);
			},
		},
	];

	async componentDidUpdate(prevProps: Props): Promise<void> {
		if (prevProps.showDialog !== this.props.showDialog && this.props.showDialog) {
			const index = this.historyTableHeaders.findIndex((x: any) => {
				return x.title === "Action";
			});
			if (this.props.role.includes(AppConstants.ROLE_CLIENTADMIN) && index === -1) {
				this.historyTableHeaders.push({
					prop: "Action",
					title: "Action",
					isFilterable: false,
					cell: (row) => (
						<div className="d-flex">
							{!row.IsEdit && (
								<button
									title="Edit"
									className="btn btn-primary d-flex"
									onClick={() => {
										row.IsEdit = true;
										this.setState({
											historyData: [...this.state.historyData],
											historyDataCopy: [...this.state.historyData],
										});
									}}
								>
									<span className="text-white">
										<FaEdit />
									</span>
								</button>
							)}
							{row.IsEdit && (
								<>
									<button
										title="Save"
										className="btn btn-success d-flex"
										onClick={() => {
											if (!row.isNew) {
												this.editHistory(row);
											} else {
												this.saveHistory(row);
											}
										}}
									>
										<span className="text-white">
											<FaRegFloppyDisk />
										</span>
									</button>
									<button
										title="Close"
										className="btn btn-warning d-flex ms-4 "
										onClick={() => {
											row.IsEdit = !row.IsEdit;
											this.setState({
												historyData: this.state.historyDataCopy,
											});
										}}
									>
										<span className="text-white">
											<FaXmark />
										</span>
									</button>
								</>
							)}
						</div>
					),
				});
			}
			this.setState({
				historyData: this.state.historyDataCopy,
			});

			await this.getScanTypes();
		}
	}

	getScanHistory = async () => {
		const res = await TRadService.getScanHistory({ scanId: this.props.scanId });
		if (res.response.success) {
			this.setState({
				historyData: res.response.data,
			});
		}
	};

	async saveHistory(data: any) {
		if (!data.attachments.length && !data.description) {
			toast.error("Please select Attachmeent or Description");
			return;
		}
		const res = await TRadService.addHistoryforScan(data);
		if (res.response.success) {
			toast.success("History Added");
			this.getScanHistory();
		} else {
			toast.error(res.response.message);
		}
	}

	async editHistory(data: any) {
		if (!data.attachments.length && !data.description) {
			toast.error("Please select Attachmeent or Description");
			return;
		}
		const res = await TRadService.editHistoryforScan(data);
		if (res.response.success) {
			toast.success(res.response.message);
			this.getScanHistory();
		} else {
			toast.error(res.response.message);
		}
	}

	getScanTypes = async () => {
		const res = await TRadService.getScanTypes();
		if (res.data.response.success) {
			this.setState({
				scanTypes: res.data.response.data,
			});
		}
	};

	render(): React.ReactNode {
		return (
			<>
				<ModalPopup
					hideSave={true}
					size="xl"
					show={this.props.showDialog}
					title={`Scan History`}
					content={
						<div className="p-4">
							<DatatableWrapper
								body={this.state.historyData}
								headers={this.historyTableHeaders}
								paginationOptionsProps={{
									initialState: {
										rowsPerPage: 10,
										options: [5, 10, 15, 20],
									},
								}}
								key={this.state.tableKey}
							>
								<Row className="mb-10">
									<Col className="col-3 d-flex flex-col justify-content-end align-items-end">
										<Filter />
									</Col>
									<Col className="d-flex justify-content-end align-items-end">
										<PaginationOptions />

										{this.props.role.includes(AppConstants.ROLE_CLIENTADMIN) && (
											<button
												className="btn btn-primary d-flex ms-10"
												onClick={() => {
													const { historyData } = this.state;
													historyData.push({
														scanId: this.props.scanId,
														attachments: [],
														IsEdit: true,
														isNew: true,
														id: Math.random(),
													});
													this.setState({
														tableKey: Math.random(),
													});
												}}
											>
												<span className="text-white">
													<FaPlus />
												</span>
												<span className="ms-3 mt-1">Create New</span>
											</button>
										)}
									</Col>
								</Row>
								<Table>
									<TableHeader />
									<TableBody />
								</Table>
								<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
									<Pagination />
								</Col>
							</DatatableWrapper>
						</div>
					}
					handleClose={() => {
						this.props.handleClose({});
					}}
					handleSave={() => {
						// this.state.selectedFiles.map(async (x: any) => {
						//   await TRadService.addDocument({ scanId: this.state.EditscanId, file: x.blob, fileName: x.fileName });
						// });
					}}
				/>
			</>
		);
	}
}
