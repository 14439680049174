import { Component } from "react";
import ModalPopup from "./ModalPopup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { AppConstants } from "../../Utils/AppConstants";
import { FaXmark } from "react-icons/fa6";

type Props = {
	show: boolean;
	handleClose: () => void;
	handleSave: (data: any) => void;
	selectedRecord: any | null;
	type: any;
};

type State = {
	isSignatureDeleted: boolean;
};

export default class GenericUserDialog extends Component<Props, State> {
	userSubmitButtonRef: React.Ref<HTMLButtonElement> = null;

	initialValues = {
		id: "",
		userName: "",
		password: "",
		displayName: "",
		email: "",
		mobile: "",
		address1: "",
		address2: "",
		city: "",
		state: "",
		country: "",
		zipCode: "",
		latitude: "",
		longitude: "",
		registrationNumber: "",
		educationQualification: "",
		isActive: true,
		signature: "",
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			isSignatureDeleted: false,
		};
		this.readyToSave = this.readyToSave.bind(this);
		this.userSubmitButtonRef = React.createRef<HTMLButtonElement>();

		this.initialValues = {
			id: this.props.selectedRecord?.Id,
			userName: this.props.selectedRecord?.UserName,
			password: this.props.selectedRecord?.Password,
			displayName: this.props.selectedRecord?.DisplayName,
			email: this.props.selectedRecord?.Email,
			mobile: this.props.selectedRecord?.Mobile,
			address1: this.props.selectedRecord?.Address1,
			address2: this.props.selectedRecord?.Address2,
			city: this.props.selectedRecord?.City,
			state: this.props.selectedRecord?.State,
			country: this.props.selectedRecord?.Country,
			zipCode: this.props.selectedRecord?.ZipCode,
			latitude: this.props.selectedRecord?.Latitude,
			longitude: this.props.selectedRecord?.Longitude,
			educationQualification: this.props.selectedRecord?.EducationQualification,
			registrationNumber: this.props.selectedRecord?.RegistrationNumber,
			isActive: this.props.selectedRecord === null ? true : !!this.props.selectedRecord?.IsActive,
			signature: this.props.selectedRecord?.Signature,
		};
	}

	validationSchema1 = Yup.object().shape({
		userName: Yup.string().required("UserName is required"),
		password: this.props.selectedRecord != null ? Yup.string() : Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
		displayName: Yup.string().required("DisplayName is required"),
		email: Yup.string().required("Email is required").email("Invalid email address"),
		mobile: Yup.string()
			.required("Mobile is required")
			.matches(/^\d{10}$/, "Invalid mobile number"),
		country: Yup.string().required("Country is required"),
		isActive: Yup.boolean().required("Is Active is required"),
		state: Yup.string().required("State is required"),
		city: Yup.string().required("City is required"),
		educationQualification: Yup.string().required("Qualification is required"),
		signature: Yup.mixed().test("fileType", "Unsupported file type", (value: any) => {
			if (value instanceof File) {
				const supportedTypesRegex = /^image\/(jpeg|png|gif|bmp)$/i;
				return supportedTypesRegex.test(value.type);
			} else if (typeof value === "string") {
				const supportedBase64TypesRegex = /^data:image\/(jpeg|png|gif|bmp);base64,/i;
				return supportedBase64TypesRegex.test(value);
			}
			return true;
		}),
		address1: Yup.string().required("Address File is required"),
	});

	validationSchema2 = Yup.object().shape({
		userName: Yup.string().required("UserName is required"),
		password: this.props.selectedRecord != null ? Yup.string() : Yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
		displayName: Yup.string().required("DisplayName is required"),
		email: Yup.string().required("Email is required").email("Invalid email address"),
		mobile: Yup.string()
			.required("Mobile is required")
			.matches(/^\d{10}$/, "Invalid mobile number"),
		country: Yup.string().required("Country is required"),
		isActive: Yup.boolean().required("Is Active is required"),
		state: Yup.string().required("State is required"),
		city: Yup.string().required("City is required"),
		address1: Yup.mixed().required("Address File is required"),
		longitude: Yup.string().required("Longitude is required"),
		latitude: Yup.string().required("Latitude is required"),
		signature: Yup.mixed().test("fileType", "Unsupported file type", (value: any) => {
			if (value instanceof File) {
				const supportedTypesRegex = /^image\/(jpeg|png|gif|bmp)$/i;
				return supportedTypesRegex.test(value.type);
			} else if (typeof value === "string") {
				const supportedBase64TypesRegex = /^data:image\/(jpeg|png|gif|bmp);base64,/i;
				return supportedBase64TypesRegex.test(value);
			}
			return true;
		}),
	});

	handleSave = () => {
		if (this.userSubmitButtonRef !== null) {
			(this.userSubmitButtonRef as any).current.click();
		}
	};

	// async readyToSave(data: any) {
	// 	console.log(data);
	// 	if (data.signature != null) {
	// 		console.log(typeof data.signature); // Check the type
	// 		console.log(data.signature instanceof Blob);

	// 		if (typeof data.signature === "string") {
	// 			// Convert the string to a Blob
	// 			const base64String = data.signature;
	// 			const byteCharacters = atob(base64String.split(",")[1]);

	// 			const byteNumbers = new Array(byteCharacters.length);
	// 			for (let i = 0; i < byteCharacters.length; i++) {
	// 				byteNumbers[i] = byteCharacters.charCodeAt(i);
	// 			}

	// 			const byteArray = new Uint8Array(byteNumbers);
	// 			data.signature = new Blob([byteArray], { type: "image/jpeg" });
	// 		}

	// 		const fileReader = new FileReader();
	// 		fileReader.onload = () => {
	// 			if (fileReader.readyState === 2) {
	// 				data.signature = fileReader.result;
	// 				this.props.handleSave(data);
	// 			}
	// 		};
	// 		fileReader.readAsDataURL(data.signature[0]);
	// 		console.log("data", data.signature[0]);
	// 	} else {
	// 		this.props.handleSave(data);
	// 	}
	// }

	async readyToSave(data: any) {
		if (data.signature != null) {
			if (typeof data.signature === "string") {
				// Convert the string to a Blob
				const base64String = data.signature;
				const byteCharacters = atob(base64String.split(",")[1]);
				const byteNumbers = new Array(byteCharacters.length);

				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);
				data.signature = new Blob([byteArray], { type: "image/jpeg" });
			}

			const fileReader = new FileReader();

			fileReader.onload = () => {
				if (fileReader.readyState === 2) {
					data.signature = fileReader.result;
					this.props.handleSave(data);
				}
			};

			fileReader.readAsDataURL(data.signature instanceof Blob ? data.signature : data.signature[0]);
		} else {
			this.props.handleSave(data);
		}
	}

	extractFileName = (dataURL: any) => {
		if (dataURL) {
			const blob = this.base64ToBlob(dataURL);
			if (blob && blob.type.startsWith("image/")) {
				return blob.type;
			}
		}
		return "";
	};

	base64ToBlob = (dataURL: any) => {
		const base64String = dataURL.split(",")[1];
		const mimeType = dataURL.split(",")[0].match(/:(.*?);/);

		if (mimeType && mimeType[1] && /^image\//.test(mimeType[1])) {
			const byteCharacters = atob(base64String);
			const byteNumbers = new Array(byteCharacters.length);

			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			const blob = new Blob([byteArray], { type: mimeType[1] });

			return blob;
		} else {
			console.error("Invalid or unsupported image type");
			return null;
		}
	};

	render() {
		const signatureFileName = this.extractFileName(this.initialValues.signature);

		return (
			<ModalPopup
				show={this.props.show}
				handleClose={this.props.handleClose}
				handleSave={this.handleSave}
				title={this.props.selectedRecord !== null ? "Edit User" : "Create User"}
				content={
					<Formik enableReinitialize initialValues={this.initialValues} validationSchema={this.props.type === AppConstants.ROLE_RADIOLOGIST ? this.validationSchema1 : this.props.type === AppConstants.ROLE_SCANNINGCENTRE ? this.validationSchema2 : null} onSubmit={this.readyToSave}>
						{({ setFieldValue }) => {
							return (
								<Form className="p-10">
									<Row>
										<Col>
											<div className="form-group">
												<label htmlFor="userName">User Name</label>
												<Field type="text" id="userName" name="userName" className="form-control" />
												<ErrorMessage name="userName" component="div" className="text-danger" />
											</div>
										</Col>
										<Col>
											<div className="form-group">
												<label htmlFor="password">Password</label>
												<Field type="password" id="password" name="password" className="form-control" />
												<ErrorMessage name="password" component="div" className="text-danger" />
											</div>
										</Col>
										<Col>
											<div className="form-group">
												<label htmlFor="mobile">Mobile</label>
												<Field type="text" id="mobile" name="mobile" className="form-control" />
												<ErrorMessage name="mobile" component="div" className="text-danger" />
											</div>
										</Col>
									</Row>
									<Row>
										<Col>
											<div className="form-group">
												<label htmlFor="displayName">Display Name</label>
												<Field type="text" id="displayName" name="displayName" className="form-control" />
												<ErrorMessage name="displayName" component="div" className="text-danger" />
											</div>
										</Col>
										<Col>
											<div className="form-group">
												<label htmlFor="email">Email</label>
												<Field type="text" id="email" name="email" className="form-control" />
												<ErrorMessage name="email" component="div" className="text-danger" />
											</div>
										</Col>
										<Col>
											<div className="form-group mt-20">
												<Field type="checkbox" id="isActive" name="isActive" className="form-check-input" />
												<label htmlFor="isActive" className="form-check-label ps-5 ">
													Is Active
												</label>
												<ErrorMessage name="isActive" component="div" className="text-danger" />
											</div>
										</Col>
									</Row>
									{this.props.type === AppConstants.ROLE_RADIOLOGIST && (
										<Row>
											<Col>
												<div className="form-group">
													<label htmlFor="registrationNumber">Reg No</label>
													<Field type="text" id="registrationNumber" name="registrationNumber" className="form-control" />
												</div>
											</Col>
											<Col>
												<div className="form-group">
													<label htmlFor="educationQualification">Qualification</label>
													<Field type="text" id="educationQualification" name="educationQualification" className="form-control" />
													<ErrorMessage name="educationQualification" component="div" className="text-danger" />
												</div>
											</Col>
											<Col></Col>
										</Row>
									)}
									<Row>
										<div className="form-group">
											<label htmlFor="address1">Address 1</label>
											<Field type="text" id="address1" name="address1" className="form-control" />
											<ErrorMessage name="address1" component="div" className="text-danger" />
										</div>
									</Row>
									<Row>
										<div className="form-group">
											<label htmlFor="address2">Address 2</label>
											<Field type="text" id="address2" name="address2" className="form-control" />
										</div>
									</Row>
									<Row>
										<Col>
											<div className="form-group">
												<label htmlFor="city">City</label>
												<Field type="text" id="city" name="city" className="form-control" />
												<ErrorMessage name="city" component="div" className="text-danger" />
											</div>
										</Col>
										<Col>
											<div className="form-group">
												<label htmlFor="state">State</label>
												<Field type="text" id="state" name="state" className="form-control" />
												<ErrorMessage name="state" component="div" className="text-danger" />
											</div>
										</Col>
										<Col>
											<div className="form-group">
												<label htmlFor="country">Country</label>
												<Field type="text" id="country" name="country" className="form-control" />
												<ErrorMessage name="country" component="div" className="text-danger" />
											</div>
										</Col>
									</Row>
									{this.props.type === AppConstants.ROLE_SCANNINGCENTRE && (
										<Row>
											<Col>
												<div className="form-group">
													<label htmlFor="latitude">Latitude</label>
													<Field type="text" id="latitude" name="latitude" className="form-control" />
													<ErrorMessage name="latitude" component="div" className="text-danger" />
												</div>
											</Col>
											<Col>
												<div className="form-group">
													<label htmlFor="longitude">Longitude</label>
													<Field type="text" id="longitude" name="longitude" className="form-control" />
													<ErrorMessage name="longitude" component="div" className="text-danger" />
												</div>
											</Col>
											<Col></Col>
										</Row>
									)}

									<Row>
										{/* <Col>
											<div className="form-group">
												<label htmlFor="signature">{this.props.type === AppConstants.ROLE_RADIOLOGIST ? "Signature" : "Photo"}</label>
												<input  type="file" id="signature" name="signature" onChange={(e: any) => setFieldValue("signature", e.target.files)} className="form-control" />

												<ErrorMessage name="signature" component="div" className="text-danger" />
											</div>
										</Col> */}
										{this.state.isSignatureDeleted || this.props.selectedRecord === null ? (
											<div>
												<label htmlFor="signature">{this.props.type === AppConstants.ROLE_RADIOLOGIST ? "Signature" : "Photo"}</label>
												<input type="file" id="signature" name="signature" onChange={(e: any) => setFieldValue("signature", e.target.files)} className="form-control" />
												<ErrorMessage name="signature" component="div" className="text-danger" />
											</div>
										) : (
											<div className="cursor-pointer w-full mt-2 d-flex flex-column gap-2">
												<div className="d-flex">
													<li>{signatureFileName}</li>
													<ErrorMessage name="signature" component="div" className="text-danger" />
													<div>
														<FaXmark
															className="text-danger small my-auto ms-2"
															onClick={() => {
																setFieldValue("signature", null);
																this.setState({ isSignatureDeleted: true });
															}}
														/>
													</div>
												</div>
											</div>
										)}
									</Row>

									<button type="submit" ref={this.userSubmitButtonRef} className="d-none"></button>
								</Form>
							);
						}}
					</Formik>
				}
			/>
		);
	}
}
