import { Component } from "react";
import { Modal } from "react-bootstrap";
import "../../Styles/tradlayout.custom.css";
import TRadService from "../../Services/TRad/TRad.service";
import { FaCircle } from "react-icons/fa6";
type Props = {
	show: boolean;
	handleClose: () => void;
};

type State = {
	show: boolean;
	tradData: any;
	userRoles: string | null;
	searchQuery: string;
	allRadiologists: any[];
};

export default class TRadRadiologistList extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			show: props.show,
			tradData: [],
			userRoles: null,
			searchQuery: "",
			allRadiologists: [],
		};
	}

	handleClose = () => {
		this.props.handleClose();
	};

	// generateRadiologistData(username: string, assignedScan: any, isOnline: boolean, lastSeen: any) {
	// 	return {
	// 		UserName: username,
	// 		assignedScan: assignedScan,
	// 		isOnline: isOnline,
	// 		lastSeen: lastSeen,
	// 	};
	// }

	async componentDidMount() {
		try {
			const res = await TRadService.getAllRadiologist();
			if (res.response.success) {
				this.setState({
					tradData: res.response.data,
					allRadiologists: res.response.data,
				});
			}
		} catch (error) {
			console.error("Error fetching radiologists:", error);
		}
	}

	calculateMinutesAgo(lastSeen: any) {
		const minutes = Math.floor(lastSeen / 60);
		if (minutes < 60) {
			return `${minutes} minutes`;
		}

		const hours = Math.floor(minutes / 60);

		return `${hours} hours`;
	}

	handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const searchQuery = event.target.value.toLowerCase();
		this.setState({ searchQuery }, () => {
			this.filterRadiologists(searchQuery); // Pass searchQuery directly
		});
	};

	filterRadiologists = (searchQuery: string) => {
		const { allRadiologists } = this.state;

		const filteredRadiologists = allRadiologists.filter((radiologist) => radiologist.UserName.toLowerCase().includes(searchQuery));

		this.setState({ tradData: filteredRadiologists });
	};

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevProps.show !== this.props.show) {
			this.setState({ show: this.props.show });
		}

		if (prevState.searchQuery !== this.state.searchQuery) {
			this.filterRadiologists(this.state.searchQuery);
		}
	}

	render() {
		const { tradData, searchQuery } = this.state;
		return (
			<div>
				<Modal show={this.state.show} onHide={this.handleClose} backdrop="static" className="modal-left">
					<Modal.Header className=" text-primary p-10 text-center" closeButton closeVariant="black">
						Available Radiologists
					</Modal.Header>
					<Modal.Body className="p-3 text-center">
						<div className="mb-3">
							<input id="searchText" type="text" className="form-control" name="searchText" value={searchQuery} placeholder="Search" onChange={this.handleSearch} />
						</div>
						<div className="border radiologist-list">
							<ul className="list-unstyled chat-list mt-2 mb-2">
								{tradData.map((radiologist: any) => (
									<li className="clearfix d-flex gap-3" key={radiologist.UserName}>
										<div className="d-flex align-items-center justify-content-center">
											<img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="avatar" />
										</div>
										<div className="about d-flex flex-column align-items-start w-100">
											<div className="name">{radiologist.DisplayName}</div>
											<div className="status">
												{radiologist.isOnline ? (
													<>
														<div className="d-flex gap-3 align-items-center justify-content-center">
															<FaCircle className="online " /> Active
														</div>
													</>
												) : (
													radiologist.lastSeen && (
														<>
															<div className="d-flex gap-3 align-items-center justify-content-center">
																<FaCircle className="offline" /> {`Last seen ${this.calculateMinutesAgo(radiologist.lastSeen)} ago`}
															</div>
														</>
													)
												)}
											</div>
											<div>Task Assigned ({radiologist.assignedScan})</div>
											<div>Observation ({radiologist.observation})</div>
										</div>
									</li>
								))}
							</ul>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}
