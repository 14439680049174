/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import "../../Styles/tradviewscanzoom.custom.css";

type State = {
	selectedFile: any | null;
	imageFiles: Blob[];
};

type Props = {
	selectedImage: any;
};

export default class TRadViewScanZoom extends React.Component<Props, State> {
	zoomRef: any;
	constructor(props: any) {
		super(props);

		this.state = {
			selectedFile: null,
			imageFiles: [],
		};

		this.zoomRef = React.createRef();
	}

	componentDidMount(): void {
		console.log(this.props.selectedImage);
	}

	zoomOut() {
		this.zoomRef.current.zoomOut();
	}

	zoomIn() {
		this.zoomRef.current.zoomIn();
	}

	reset() {
		this.zoomRef.current.resetTransform();
	}

	render(): React.ReactNode {
		return (
			<>
				<div className="bg-secondary wrapper">
					<TransformWrapper ref={this.zoomRef} initialScale={0.3} minScale={0.1} centerOnInit={true} disabled={false} zoomAnimation={{ disabled: true }}>
						<TransformComponent>{this.props.selectedImage && <img className="" alt="image" src={URL.createObjectURL(this.props.selectedImage)} />}</TransformComponent>
					</TransformWrapper>
				</div>
			</>
		);
	}
}
