import { Component } from "react";
import { Navigate } from "react-router-dom";
import InvLayout from "../../Layouts/Inv/InvLayout";
import InvService from "../../Services/Inv/Inv.service";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableColumnType, TableHeader } from "react-bs-datatable";
import { FaEdit } from "react-icons/fa";
import { FaRegFloppyDisk, FaXmark, FaPlus, FaEye } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import { Col, Row, Table } from "react-bootstrap";
import moment from "moment";
import Select from "react-select";
import deepCopy from "../../Utils/DeepCopy";
import "../../Styles/invTable.custom.css";

type Props = {
	isShop: boolean;
};

type State = {
	redirect: string | null;
	purchases: Array<any>;
	purchasesCopy: Array<any>;
	ready: boolean;
	tableKey: number;
	shops: Array<any>;
	selectedShop: any;
};

type purchaseItem = {
	Id: string;
	InvoiceNumber: string;
	SupplierName: string;
	SupplierVATIN: string;
	InvoiceDate: any;
	TotalItems: number;
	TotalAmount: number;
	Action: any;
	IsEdit: boolean;
	NewRandomId: any;
	ShopId: any;
};

export default class InvPurchases extends Component<Props, State> {
	purchaseTableHeaders: TableColumnType<purchaseItem>[] = [
		{
			prop: "InvoiceNumber",
			title: "Invoice #",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Invoice #</div>}
					<div>
						{row.IsEdit && (
							<div>
								<input
									value={row.InvoiceNumber}
									className="form-control"
									onChange={(e) => {
										row.InvoiceNumber = e.target.value;
										this.setState({
											purchases: this.state.purchases,
										});
									}}
								/>
							</div>
						)}
						{!row.IsEdit && <span>{row.InvoiceNumber}</span>}
					</div>
				</>
			),
		},
		{
			prop: "SupplierName",
			title: "Supplier",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Supplier</div>}
					<div>
						{row.IsEdit && (
							<div>
								<input
									value={row.SupplierName}
									className="form-control"
									onChange={(e) => {
										row.SupplierName = e.target.value;
										this.setState({
											purchases: this.state.purchases,
										});
									}}
								/>
							</div>
						)}
						{!row.IsEdit && <span>{row.SupplierName}</span>}
					</div>
				</>
			),
		},
		{
			prop: "SupplierVATIN",
			title: "Supplier VATIN",
			isSortable: true,
			isFilterable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Supplier VATIN</div>}
					<div>
						{row.IsEdit && (
							<div>
								<input
									value={row.SupplierVATIN}
									className="form-control"
									onChange={(e) => {
										row.SupplierVATIN = e.target.value;
										this.setState({
											purchases: this.state.purchases,
										});
									}}
								/>
							</div>
						)}
						{!row.IsEdit && <span>{row.SupplierVATIN}</span>}
					</div>
				</>
			),
		},
		{
			prop: "InvoiceDate",
			title: "Date",
			isSortable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Date</div>}
					<div>
						{row.IsEdit && (
							<div>
								<input
									type="date"
									value={moment(row.InvoiceDate).format("YYYY-MM-DD")}
									className="form-control"
									onChange={(e) => {
										row.InvoiceDate = new Date(e.target.value);
										this.setState({
											purchases: this.state.purchases,
										});
									}}
								/>
							</div>
						)}
						{!row.IsEdit && <span>{moment(row.InvoiceDate).format("DD-MM-YYYY")}</span>}
					</div>
				</>
			),
		},
		{
			prop: "TotalItems",
			title: "Total Items",
			isSortable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Total Items</div>}
					<div>{row.TotalItems.toLocaleString()}</div>
				</>
			),
		},
		{
			prop: "TotalAmount",
			title: "Total Amount",
			isSortable: true,
			cell: (row) => (
				<>
					{row.IsEdit && <div>Total Amount</div>}
					<div>{row.TotalAmount.toLocaleString()}</div>
				</>
			),
		},
		{
			prop: "Action",
			title: "Action",
			cell: (row) => (
				<>
					{row.IsEdit && <div>Action</div>}
					<div className="d-flex">
						{!row.IsEdit && (
							<>
								<button
									title="Edit"
									className="btn btn-primary d-flex"
									onClick={() => {
										row.IsEdit = !row.IsEdit;
										this.setState({
											purchases: this.state.purchases,
										});
									}}
								>
									<span className="text-white">
										<FaEdit />
									</span>
									<span className="ms-3 mt-1">Edit</span>
								</button>
								<button
									title="View"
									className="btn btn-warning d-flex ms-5 "
									onClick={() => {
										var redirect = `/manage/invstorepurchaseitem/${row.Id}`;
										if (this.props.isShop) {
											redirect = `/manage/invshoppurchaseitem/${row.Id}`;
										}
										this.setState({
											redirect,
										});
									}}
								>
									<span className="text-white">
										<FaEye />
									</span>
									<span className="ms-3 mt-1 text-white">View</span>
								</button>
							</>
						)}
						{row.IsEdit && (
							<>
								<button
									title="Save"
									className="btn btn-success d-flex"
									onClick={() => {
										this.saveValue(row);
									}}
								>
									<span className="text-white">
										<FaRegFloppyDisk />
									</span>
									<span className="ms-3 mt-1">Save</span>
								</button>
								<button
									title="Discard"
									className="btn btn-warning d-flex ms-4 "
									onClick={() => {
										row.IsEdit = !row.IsEdit;
										this.setState({
											purchases: this.state.purchases.filter((x) => x.NewRandomId === undefined || x.NewRandomId !== row.NewRandomId),
										});
									}}
								>
									<span className="text-white">
										<FaXmark />
									</span>
									<span className="ms-3 mt-1 text-white">Cancel</span>
								</button>
							</>
						)}
					</div>
				</>
			),
		},
	];
	constructor(props: Props) {
		super(props);

		this.state = {
			redirect: null,
			purchases: [],
			purchasesCopy: [],
			ready: false,
			tableKey: Math.random(),
			shops: [],
			selectedShop: null,
		};
		this.loadValues = this.loadValues.bind(this);
		this.onShopChange = this.onShopChange.bind(this);
	}

	async componentDidMount() {
		if (this.props.isShop) {
			const response = await InvService.getShops();
			const shops = new Array<any>();
			response.data.map((s: any) =>
				shops.push({
					label: s.Name,
					value: s.Id,
				})
			);
			this.setState(
				{
					shops,
				},
				() => this.loadValues()
			);
		} else {
			this.loadValues();
		}
	}

	async loadValues() {
		console.log(this.state.selectedShop);
		const response = await InvService.getPurchases({ shopId: this.state.selectedShop?.value, isShop: this.props.isShop });
		this.setState({
			purchases: response.data,
			purchasesCopy: deepCopy(response.data),
			ready: true,
		});
	}

	async saveValue(data: any) {
		console.log(data);
		if (data.InvoiceNumber === "" || data.InvoiceNumber === "") {
			toast.error("Please enter invoice number");
		} else if (data.Supplier === "" || data.Supplier === "") {
			toast.error("Please enter Supplier");
		} else if (data.InvoiceDate === "" || data.InvoiceDate === null) {
			toast.error("Please enter Date");
		} else {
			const res = await InvService.createOrUpdatePurchase({
				Id: data.Id,
				InvoiceNumber: data.InvoiceNumber,
				SupplierName: data.SupplierName,
				SupplierVATIN: data.SupplierVATIN,
				InvoiceDate: data.InvoiceDate,
				ShopId: data.Id !== undefined ? data.ShopId : this.state.selectedShop?.value,
			});
			if (res.status === 200 && res.data.success) {
				toast.success(res.data.message);
				this.loadValues();
			}
		}
	}

	onShopChange(e: any) {
		this.setState(
			{
				selectedShop: e,
			},
			() => this.loadValues()
		);
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />;
		}

		return (
			<InvLayout showMargin={true}>
				<>
					<div className="mt-50 px-10">
						<div className="row">
							{this.props.isShop && (
								<div className="col-12">
									<h4 className="h3 mb-0 text-gray-800">Manage Shop Purchases</h4>
									<p>A place to manage shop purchases</p>
								</div>
							)}
							{!this.props.isShop && (
								<div className="col-12">
									<h4 className="h3 mb-0 text-gray-800">Manage Store Purchases</h4>
									<p>A place to manage store purchases</p>
								</div>
							)}
						</div>
					</div>
					{this.state.ready && (
						<DatatableWrapper
							body={this.state.purchases}
							headers={this.purchaseTableHeaders}
							paginationOptionsProps={{
								initialState: {
									rowsPerPage: 10,
									options: [5, 10, 15, 20],
								},
							}}
							key={this.state.tableKey}
							sortProps={{
								initialState: {
									order: "desc",
									prop: "InvoiceDate",
								},
							}}
						>
							<Row className="mb-10">
								{this.props.isShop === true && (
									<Col className="mb-3 mb-md-0">
										<Select name="shops" value={this.state.selectedShop} onChange={this.onShopChange} options={this.state.shops}></Select>
									</Col>
								)}
								<Col xs={12} md={4} className=" d-flex flex-col justify-content-end align-items-end gap-5">
									<Filter placeholder="Search..." />
								</Col>
								<Col className="col-12 mt-5 col-md mt-md-0 d-flex justify-content-end align-items-end">
									{((this.props.isShop === true && this.state.selectedShop !== null) || this.props.isShop === false) && (
										<button
											className="btn btn-primary d-flex ms-10"
											onClick={() => {
												const purchases = this.state.purchases;
												purchases.unshift({
													Id: undefined,
													InvoiceNumber: "",
													SupplierName: "",
													SupplierVATIN: "",
													InvoiceDate: null,
													TotalItems: 0,
													TotalAmount: 0,
													IsEdit: true,
													NewRandomId: Math.random(),
												});
												this.setState({
													purchases,
													tableKey: Math.random(),
												});
											}}
										>
											<span className="text-white">
												<FaPlus />
											</span>
											<span className="ms-3 mt-1">Create New</span>
										</button>
									)}
								</Col>
							</Row>
							<Table className="dataTable">
								<TableHeader />
								<TableBody />
							</Table>
							<Col className="mt-10 d-flex flex-col justify-content-end align-items-end">
								<PaginationOptions />
								<div className="ms-5" />
								<Pagination />
							</Col>
						</DatatableWrapper>
					)}
					<ToastContainer />
				</>
			</InvLayout>
		);
	}
}
